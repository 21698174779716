import React, { useEffect, useState } from 'react';
import { ConfiguredProduct, Design, isDirectionL, isDirectionR, L_R_LR } from '../domain';
import { isNil } from '@w11k/rx-ninja';
import DOMPurify from 'dompurify';
import { mirrorDecorX } from './dekor-svg-utils';

const domPurify = DOMPurify(window);

async function loadSvgFile(url: string): Promise<Document> {
    const parser = new DOMParser();
    const data = await fetch(url);
    const text = await data.text();
    return parser.parseFromString(domPurify.sanitize(text), 'image/svg+xml');
}

function cleanupSvg(svg: Document) {
    svg.querySelector('svg > style')?.remove();
    svg.querySelector('svg > g#Ebene_4')?.remove();
    svg.querySelector('svg')?.removeAttribute('style');
}

function extractDataFromSvg(svg: XMLDocument) {
    const root = svg.querySelector('svg');
    const viewBox = root?.getAttribute('viewBox');
    if (isNil(root) || isNil(viewBox)) {
        throw new Error('kein SVG root oder keine viewbox definiert');
    }

    const [top, left, width, height] = viewBox.split(' ').map(v => parseFloat(v));

    const dekorNode = svg.querySelector('svg > g[id^=Original]');
    if (isNil(dekorNode)) {
        throw new Error('kein Element g#Original in der SVG Datei gefunden');
    }
    const dekor = new XMLSerializer().serializeToString(dekorNode);

    return {
        xmlns: root.getAttribute('xmlns') ?? undefined,
        'xmlns:xlink': root.getAttribute('xmlns:xlink') ?? undefined,
        version: root.getAttribute('version') ?? undefined,
        x: root.getAttribute('x') ?? undefined,
        y: root.getAttribute('y') ?? undefined,
        'xml:space': root.getAttribute('xml:space') ?? undefined,
        top,
        left,
        width,
        height,
        dekor,
    };
}

function useSvgFile(sku: string, design: Design) {
    const [svgData, setSvgData] = useState<ReturnType<typeof extractDataFromSvg> & { mmToPxFactor: number }>();
    useEffect(() => {
        (async () => {
            try {
                const svg = await loadSvgFile(design.svgFile);
                cleanupSvg(svg);
                const data = extractDataFromSvg(svg);
                const aspectRatioFromViewBox = Math.round(data.width / data.height * 1000) / 1000;
                const aspectRatioFromDesign = Math.round(design.widthMaxDecor / design.heightMaxDecor * 1000) / 1000;
                if (aspectRatioFromViewBox !== aspectRatioFromDesign) {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error('Seitenverhältnisse SVG\'s viewBox und design-Tabelle sind unterschiedlich');
                }

                setSvgData({
                    ...data,
                    mmToPxFactor: data.height / design.heightMaxDecor,
                });
            } catch (e) {
                console.error(`Fehler beim Einlesen der SVG-Datei für SKU '${sku}' / Design '${design.code}': '${e}'`);
            }
        })();
    }, [sku, design]);
    return svgData;
}

export function Dekor(props: {
    configuredProduct: ConfiguredProduct,
    design: Design,
    initialDirection: L_R_LR | undefined,
}) {
    const product = props.configuredProduct.final;

    const svg = useSvgFile(product.sku, props.design);
    if (isNil(svg)) {
        return <></>;
    }

    const dekorWidthInMM = Math.round(svg.width / svg.mmToPxFactor);
    const dekorOverlayingWidth = dekorWidthInMM - product.width;
    const dekorHeightInMM = Math.round(svg.height / svg.mmToPxFactor);
    const dekorOverlayingHeight = dekorHeightInMM - product.height;

    let offsetX = 0;
    if (props.design.posX === 'center') {
        offsetX = dekorOverlayingWidth / 2;
    } else if (props.design.posX === 'hinge') {
        if (isDirectionL(product.direction)) {
            offsetX = 0;
        } else if (isDirectionR(product.direction)) {
            offsetX = -dekorOverlayingWidth;
        }
    } else if (props.design.posX === 'lock') {
        if (isDirectionL(product.direction)) {
            offsetX = -dekorOverlayingWidth;
        } else if (isDirectionR(product.direction)) {
            offsetX = 0;
        }
    }

    let offsetY = 0;
    if (props.design.posY === 'center') {
        offsetY = dekorOverlayingHeight / 2;
    } else if (props.design.posY === 'bottom') {
        offsetY = -dekorOverlayingHeight;
    }

    const vbLeft = svg.left;
    const vbTop = svg.top;
    const vbWidth = product.width * svg.mmToPxFactor;
    const vbHeight = product.height * svg.mmToPxFactor;

    const mirrorX = mirrorDecorX(
        props.initialDirection,
        props.design.posX,
        product.direction,
        props.design.mirrorHorizontal,
        vbWidth,
    );

    return (
        <svg xmlns={svg.xmlns}
             version={svg.version}
             x={0}
             y={0}
             width={product.width}
             height={product.height}
             preserveAspectRatio="none"
             viewBox={`${vbLeft} ${vbTop} ${vbWidth} ${vbHeight}`}
        >
            <g id="door-dimensions" fill="none" stroke="none">
                <rect x={vbLeft} y={vbTop} width={vbWidth} height={vbHeight}/>
            </g>

            <g
                id="dekor"
                transform={`${mirrorX()}translate(${-1 * offsetX * svg.mmToPxFactor} ${-1 * offsetY * svg.mmToPxFactor})`}
                dangerouslySetInnerHTML={{__html: svg.dekor}}/>

        </svg>
    );
}
