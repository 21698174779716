import { ShoppingCartSelection } from '../../../facades/ShoppingCartFacade';
import { UserTypes } from '../../../user/user-types';
import {
    convertToOrderPostToBackendV2,
    createOrderFromConfiguredProduct,
    createOrderFromConfiguredProducts,
} from '../../../facades/OrderFacade';
import { getErrorMessageFromException, getHeadersByEnv } from '../../../utils';
import { SubmitOrderBody } from '../../../endpoints';
import Swal from 'sweetalert2';
import { ConfiguredProduct, ItemFromBackend, OrderPostToBackendV2 } from '../../../domain';
import { initialFormValue } from './BasketOrderModal';
import { FormikValues } from 'formik';
import {
    sendBasketPurchaseSuccessAnalyticsEvent,
    sendCPPurchaseSuccessAnalyticsEvent,
    sendPurchaseErrorAnalyticsEvent,
} from '../../../analytics/analytics-service';

export async function submit(
    form: typeof initialFormValue,
    productSelection: ShoppingCartSelection[] | ConfiguredProduct,
    userType?: UserTypes,
    totalPrice?: string) {

    let body: OrderPostToBackendV2;

    if (Array.isArray(productSelection)) {
        body = createOrderFromConfiguredProducts(form.customerNumber, form.message, productSelection);
    } else {
        body = convertToOrderPostToBackendV2(
            createOrderFromConfiguredProduct(form.customerNumber, form.message, productSelection),
        );
    }
    console.log('Senden:', JSON.stringify(body, undefined, 4));

    let potentialErrorMessage = '';
    try {
        const headers = getHeadersByEnv();
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/configurator-api/v2/order/submit`,
            {
                method: 'POST',
                credentials: 'include',
                headers,
                body: JSON.stringify(body),
            });

        /* MOCK for testing: */
        // const response = {
        //     status: 200,
        //     json: (): Promise<SubmitOrderBody> =>
        //         new Promise(r => setTimeout(() => r({
        //             status: 'ok',
        //             configurationOrders: [{
        //                 configurationId: 'configurationId',
        //                 configurationUrl: 'configurationUrl',
        //             }],
        //         } as SubmitOrderBody), 1000)),
        // };

        if (response.status === 200) {
            const result = await response.json() as SubmitOrderBody;
            if (result.status === 'ok') {
                if (Array.isArray(productSelection) && totalPrice !== undefined) {
                    result?.configurationOrders.forEach(configurationItem => {
                            console.log('totalPrice', totalPrice);
                            sendBasketPurchaseSuccessAnalyticsEvent(totalPrice, productSelection, configurationItem.configurationId);
                        },
                    );
                } else if (!Array.isArray(productSelection)) {
                    result?.configurationOrders.forEach(configurationItem =>
                        sendCPPurchaseSuccessAnalyticsEvent(productSelection, configurationItem.configurationId),
                    );
                }
                // noinspection ES6MissingAwait
                Swal.fire({
                    title: 'Ihre Bestellung wurde erfolgreich abgesendet!',
                    icon: 'success',
                    confirmButtonText: 'Close',
                });
                return true;
            } else {
                potentialErrorMessage = result?.code ?? 'Unbekannter Fehler';
            }
        } else {
            potentialErrorMessage = 'Fehler bei der Übertragung: ' + response.status;
        }
    } catch (e) {
        potentialErrorMessage = getErrorMessageFromException(e);
    }

    (body.items as ItemFromBackend[]).forEach(item =>
        sendPurchaseErrorAnalyticsEvent(item.product.sku),
    );
    await Swal.fire({
        title: 'Das hat leider nicht geklappt...',
        text: potentialErrorMessage,
        icon: 'error',
        confirmButtonText: 'Close',
    });
    return false;
}

export const modalValidation = (values: FormikValues) => {
    const errors: Record<string, string> = {};
    if (!values.customerNumber || values.customerNumber.trim().length < 3) {
        errors.customerNumber = 'Bitte geben Sie Ihre Kundennummer an!';
    }
    return errors;
};
