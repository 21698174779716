import { takeUntil } from 'rxjs/operators';
import { Commands, Facade } from '@w11k/tydux';
import { combineLatestToMap, isNil } from '@w11k/rx-ninja';
import { userMessages } from '../utils';
import { extractDoorTypeMulti, isCustomizableProduct, Product } from '../domain';
import { DatabaseFacade, DatabaseState } from './DatabaseFacade';

export class ProductSelectionState {
    selectedSku?: string;
    productTemplate?: Product;
    matchingProducts: Product[] = [];
}

export class ProductSelectionCommands extends Commands<ProductSelectionState> {

    resetState() {
        this.state.selectedSku = undefined;
        this.state.matchingProducts = [];
        this.state.productTemplate = undefined;
    }

    setSelectedSku(sku: string) {
        this.state.selectedSku = sku;
    }

    updateMatchingProducts(
        databaseState: DatabaseState,
    ) {
        if (isNil(this.state.selectedSku)) {
            return;
        }

        this.state.matchingProducts = [];
        this.state.productTemplate = undefined;

        const selectedProduct = databaseState.availableProductsBySku[this.state.selectedSku];
        this.state.productTemplate = selectedProduct;
        if (isNil(selectedProduct)) {
            return;
        }

        this.state.matchingProducts = searchMatchingProducts(
            this.state.selectedSku, databaseState.availableProducts, databaseState.availableProductsBySku,
        );
    }

}


export class ProductSelectionFacade extends Facade<ProductSelectionCommands> {

    constructor(private readonly databaseFacade: DatabaseFacade) {
        super('productSelection', new ProductSelectionCommands(), new ProductSelectionState());

        combineLatestToMap({
            sku: this.select(s => s.selectedSku),
            databaseState: databaseFacade.select(),
        })
            .pipe(takeUntil(this.observeDestroyed()))
            .subscribe((v) => {
                if (isNil(v.sku)) {
                    return;
                }
                this.commands.updateMatchingProducts(v.databaseState);
                if (!isNil(this.state.productTemplate)) {
                    userMessages.log(`%cSEL Auswahl`, 'font-weight:bold;color:#ffffff;background: #48b9c7;', this.state.productTemplate);
                    userMessages.log(`%cSEL Matching`, 'font-weight:bold;color:#ffffff;background: #48b9c7;', this.state.matchingProducts);
                }
            });
    }

    setSelectedSku(sku: string) {
        this.commands.setSelectedSku(sku);
    }

    resetState() {
        this.commands.resetState();
    }

}

export function searchMatchingProducts(selectedSku: string,
                                       availableProducts: Product[],
                                       availableProductsBySku: { [sku: string]: Product }): Product[] {
    const selectedProduct = availableProductsBySku[selectedSku];

    if (selectedProduct) {
        const matchingProducts = availableProducts.filter(product => {
            const family =
                product.family === selectedProduct.family
                || product.family === 'NAV Glas Konfigurationsartikel';

            const valid_country_select =
                product.valid_country_select === selectedProduct.valid_country_select
                || product.valid_country_select === 'world';

            const brand =
                product.brand === selectedProduct.brand;

            const product_name =
                product.product_name === selectedProduct.product_name;

            return valid_country_select && brand && family && product_name;
        });

        const standardProducts = matchingProducts.filter(product => !isCustomizableProduct(product));
        const xProducts = matchingProducts.filter(product => isCustomizableProduct(product));

        const xProductsWithClones = xProducts.reduce((acc: Product[], product: Product) => {
            const door_type_multi = extractDoorTypeMulti(product);
            const products = door_type_multi.map(doorType => ({...product, door_type_multi: doorType}));
            return acc.concat(products);
        }, []);

        return [...standardProducts, ...xProductsWithClones];
    }

    return [];
}
