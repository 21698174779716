import React from 'react';
import { Divider } from '@material-ui/core';

export function Footer() {

  return (
    <div className="footer">
      <Divider/>
      <a href={process.env.REACT_APP_PRIVACY_URL} rel="noreferrer" target="_blank">DATENSCHUTZ</a>
      <a href={process.env.REACT_APP_IMPRINT_URL} rel="noreferrer" target="_blank">IMPRESSUM</a>
      <a href={process.env.REACT_APP_AGB_URL} rel="noreferrer" target="_blank">AGB</a>
    </div>);
}
