import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Box, Tab, Tabs } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons/faScrewdriverWrench';
import { useFacadeState } from '../../utils';
import { AppFacades } from '../../facades/app-facades';
import './navTabs.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
const LinkTab = (props: { label: string, to: string, icon: JSX.Element, disabled?: boolean }): JSX.Element => (
    <Tab className="link-tab" {...props} fullWidth={true} component={Link}/>
);

export default function NavTabs({facades}: { facades: AppFacades }): JSX.Element {
    const {path} = useRouteMatch();
    const activePosition = useFacadeState(facades.shoppingCartFacade, s => s.activePosition);
    const items = useFacadeState(facades.shoppingCartFacade, s => s.selection);

    const handleSelectedTabValue = (): number => path.includes('configure') ? 0 : 1;
    const isEmptyShoppingCart = (): boolean => items.length === 0;

    return (
        <Box className="w-100">
            <Tabs value={handleSelectedTabValue()} aria-label="nav tabs">
                <LinkTab
                    label="Konfigurator"
                    to={`/products/configure/${activePosition ?? 0}`}
                    icon={<FontAwesomeIcon icon={faScrewdriverWrench}/>}
                    disabled={isEmptyShoppingCart()}
                />
                <LinkTab
                    label="Warenkorb"
                    to="/products/cart"
                    icon={<FontAwesomeIcon icon={faShoppingCart}/>}
                />
            </Tabs>
        </Box>
    );
}
