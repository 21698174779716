import { ConfiguredProduct } from '../../../domain';
import React, { useRef, useState } from 'react';
import { wrapHTMLElementWithModalApi } from '../../../utils';
import { Form, Formik } from 'formik';
import { modalValidation, submit } from './order-service';
import { DataInput } from './BasketOrderModal';
import { AuthenticationState } from '../../../facades/AuthenticationFacade';
import { UserTypes } from '../../../user/user-types';

export function ConfiguredProductOrderModal(props: {
    configuredProduct: ConfiguredProduct,
    user: Readonly<AuthenticationState>
}) {
    const formRef = useRef<HTMLDivElement>(null);
    const product = props.configuredProduct.final;
    const [isValidCustomer, setIsValidCustomer] = useState(false);
    const initialFormValue = {
        customerNumber: props.user.customerNr || '',
        message: '',
    };
    const reRoutePartners = async () => {
        if (props.user.userType === UserTypes.PARTNER) {
            await submit(initialFormValue, props.configuredProduct, props.user.userType);
            window.location.href = process.env.REACT_APP_SHOPPING_CART_REDIRECT_URL ?? 'https://www.griffwert.eu/warenkorb/';
        }
    };

    return (
        <div>
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#bestellung"
                    onClick={reRoutePartners}>
                {props.user.userType === UserTypes.INTERNAL ? 'Anfrage abschicken' : 'In den Warenkorb'}
            </button>
            <Formik
                initialValues={initialFormValue}
                validate={modalValidation}
                onSubmit={async (values, {setSubmitting}) => {
                    const success = await submit(initialFormValue, props.configuredProduct, props.user.userType);
                    setSubmitting(false);
                    if (success) {
                        wrapHTMLElementWithModalApi(formRef.current)?.modal('hide');
                    }
                }}
            >
                {({isSubmitting, isValid, submitForm}) => {
                    if (props.user.userType === UserTypes.PARTNER) {
                        return;
                    }
                    return (
                        <Form>
                            <div ref={formRef} className="modal fade" id="bestellung" tabIndex={-1}
                                 aria-labelledby="exampleModalLabel"
                                 aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Anfrage senden</h5>
                                        </div>
                                        <div className="modal-body">
                                            <h6 className="mb-3">Zusammenfassung</h6>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div>{product.description_40_char_1}</div>
                                                    <div>{product.description_40_char_2}</div>
                                                    <div>{product.description_40_char_3}</div>
                                                </div>
                                                <div className="col">
                                                    <div>{product.sku}</div>
                                                    <div>{product.product_name}</div>
                                                    <div>Richtung: {product?.direction?.toUpperCase()}</div>
                                                </div>
                                            </div>


                                            <div className="mt-3 mb-3">
                                                <span className="">{product?.nav_recommended_retail_price_net} € </span>
                                                <span className="text-muted mb-3">
                                        UVP des Herstellers,
                                        alle Preise exkl. MwSt.
                                    </span>
                                            </div>
                                            <h6 className="mt-5">Ihre Daten</h6>
                                            <DataInput setIsValidCustomer={setIsValidCustomer}/>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-primary"
                                                    disabled={!isValidCustomer || isSubmitting || !isValid}>
                                                Absenden
                                            </button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                                Abbrechen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
