import { Commands, createAssignCommand, Facade } from '@w11k/tydux';
import { filter, take } from 'rxjs/operators';
import { getHeadersByEnv } from '../utils';
import { UserInformationFromBackend, UserTypes } from '../user/user-types';

export class AuthenticationState {
  userLoggedIn = false;
  userType?: UserTypes;
  customerNr?: string;
  customerName?: string;
}

class AuthenticationCommands extends Commands<AuthenticationState> {
  setUserLoggedIn = createAssignCommand(this, 'userLoggedIn');
  setUserType = createAssignCommand(this, 'userType');
  setCustomerNr = createAssignCommand(this, 'customerNr');
  setCustomerName = createAssignCommand(this, 'customerName');
}

export class AuthenticationFacade extends Facade<AuthenticationCommands> {

  constructor() {
    super('authentication', new AuthenticationCommands(), new AuthenticationState());
  }

  setUserInformation(userInfo: UserInformationFromBackend) {
    this.commands.setUserLoggedIn(true);
    this.commands.setUserType(userInfo.type);
    if (userInfo.customer) {
      this.commands.setCustomerNr(userInfo.customer.customerNo);
      this.commands.setCustomerName(userInfo.customer.name);
    }
  }

  async loadUserInformation(): Promise<boolean> {
    const headers = getHeadersByEnv();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/configurator-api/v2/user/info`,
      {
        method: 'GET',
        credentials: 'include',
        headers,
      });

    if (response.status === 200) {
      const userInformation = await response.json() as UserInformationFromBackend;
      this.setUserInformation(userInformation);
      return true;
    } else {
      this.setUserLoggedIn(false);
      return false;
    }
  }

  // need to wait for login
  async setUserLoggedIn(loggedIn: boolean) {
    this.commands.setUserLoggedIn(loggedIn);
    return this.select(state => state.userLoggedIn).pipe(filter(login => login), take(1)).toPromise();
  }
}
