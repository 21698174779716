import { filter, takeUntil } from 'rxjs/operators';
import { Commands, createAssignCommand, Facade } from '@w11k/tydux';
import { combineLatestToMap, isNil, isNotNil } from '@w11k/rx-ninja';
import { ConfiguredProduct, Norm, Product } from '../domain';
import { DatabaseFacade } from './DatabaseFacade';
import { ProductSelectionFacade } from './ProductSelectionFacade';
import { ProductCustomizeFacade } from './ProductCustomizeFacade';
import { userMessages } from '../utils';

export type ShoppingCartSelection = {
    selectedProduct?: Product;
    configuredProduct?: ConfiguredProduct;
}

export class ShoppingCartState {
    active = false;
    activePosition?: number;
    priceTotal = '0.00';
    selection: ShoppingCartSelection[] = [];
}

export class ShoppingCartCommands extends Commands<ShoppingCartState> {

    setActive = createAssignCommand(this, 'active');
    setActivePosition = createAssignCommand(this, 'activePosition');
    setPriceTotal = createAssignCommand(this, 'priceTotal');
    setSelection = createAssignCommand(this, 'selection');

    addSelection(selected: ShoppingCartSelection): void {
        this.state.selection = [...this.state.selection, selected];
    }

    removeSelection(selectedPosition: number): void {
        if (selectedPosition >= 0) {
            const selectionState = [...this.state.selection];
            selectionState.splice(selectedPosition, 1);
            this.setSelection(selectionState);
        }
    }

    switchSelection(selectedPosition: number, selected: ShoppingCartSelection): void {
        if (selectedPosition >= 0) {
            const selectionState = [...this.state.selection];
            selectionState.splice(selectedPosition, 1, selected);
            this.setSelection(selectionState);
            this.setActivePosition(selectedPosition);
        }
    }

    resetSelection(): void {
        this.setSelection([]);
        this.setActivePosition(undefined);
    }
}

export class ShoppingCartFacade extends Facade<ShoppingCartCommands> {

    constructor(private readonly databaseFacade: DatabaseFacade,
                private readonly productSelectionFacade: ProductSelectionFacade,
                private readonly productCustomizeFacade: ProductCustomizeFacade) {

        super('shoppingCart', new ShoppingCartCommands(), new ShoppingCartState());

        this.calculateTotalPriceOnShoppingCartChange();
        this.foo();

        // TODO: in eine private Methode mit passendem Namen auslagern
        combineLatestToMap({
            activePosition: this.selectNonNil(s => s.activePosition),
            selectedProduct: productCustomizeFacade.selectNonNil(s => s.selectedProduct),
            configuredProduct: productCustomizeFacade.selectNonNil(s => s.configuredProduct),
        })
            .pipe(takeUntil(this.observeDestroyed()))
            .subscribe(({activePosition, selectedProduct, configuredProduct}) => {
                this.updateSelection(activePosition, {selectedProduct, configuredProduct});
            });
    }

    public addProductToCartBySKU(sku: string): void {
        const product = this.databaseFacade.state.availableProductsBySku[sku];
        if (product) {
            this.commands.addSelection({
                selectedProduct: product,
            });
        }
    }

    public removeProductFromCartByIndex(selectedPosition: number): void {
        this.commands.removeSelection(selectedPosition);
    }

    public setShoppingCartViewToActive(isActive: boolean) {
        this.commands.setActive(isActive);
    }

    public setShoppingCartSelection(shoppingCartSelection: ShoppingCartSelection[]) {
        this.commands.setSelection(shoppingCartSelection);
    }

    public resetShoppingCart() {
        this.commands.resetSelection();
    }

    public setSkuAndUpdateMatchingProducts(sku: string, activePosition: number) {
        if (sku === this.productSelectionFacade.state.selectedSku && activePosition === this.state.activePosition) {
            userMessages.log(
                `%c====================== gleiche SKU gesetzt: ${sku} ======================`,
                'font-weight:bold;color:#ffffff;background: #48b9c7;',
            );
            return;
        }

        userMessages.log(
            `%c====================== neue SKU gesetzt: ${sku} ======================`,
            'font-weight:bold;color:#ffffff;background: #48b9c7;',
        );
        this.productSelectionFacade.setSelectedSku(sku);
        this.commands.setActivePosition(activePosition);
    }

    private calculateTotalPriceOnShoppingCartChange() {
        combineLatestToMap({
            active: this.select(s => s.active),
            selection: this.select(s => s.selection),
        })
            .pipe(takeUntil(this.observeDestroyed()))
            .subscribe(({active, selection}) => {
                this.logShoppingCart(selection);

                if (active) {
                    const itemsCount = selection.length;
                    if (itemsCount === 0) {
                        this.productSelectionFacade.resetState();
                        this.productCustomizeFacade.resetState();
                    }
                    if (itemsCount > 0) {
                        this.commands.setPriceTotal(this.calculatePriceTotal(selection));
                    }
                }
            });
    }

    private foo() {
        combineLatestToMap({
            activePosition: this.selectNonNil(s => s.activePosition),
            matchingProducts: this.productSelectionFacade.select(s => s.matchingProducts),
            shoppingCartIsActive: this.selectNonNil(s => s.active),
        })
            .pipe(
                filter(({shoppingCartIsActive}) => !shoppingCartIsActive),
                filter(({matchingProducts}) => matchingProducts.length > 0),
                takeUntil(this.observeDestroyed()),
            )
            .subscribe(({activePosition, matchingProducts}) => {
                this.productCustomizeFacade.createProductCustomizationOptions(matchingProducts);
                const sku = this.productSelectionFacade.state.selectedSku;

                if (!isNil(sku)) {
                    const product = this.databaseFacade.state.availableProductsBySku[sku];
                    const configuredProduct: ConfiguredProduct | undefined | null = this.getConfiguredProductFromShoppingCart(
                        sku, activePosition, this.state.selection,
                    );
                    const norm: Norm = configuredProduct ? configuredProduct.final.norm : product.norms[0];

                    this.productCustomizeFacade.setSelectedProduct(product, configuredProduct, norm);
                }
            });
    }

    private updateSelection(selectedPosition: number, selection: ShoppingCartSelection): void {
        this.commands.switchSelection(selectedPosition, selection);
    }

    private logShoppingCart(shoppingCartSelection: ShoppingCartSelection[]): void {
        userMessages.log(`%c___🛒___`, 'background: #ffffff;');
        userMessages.dir(shoppingCartSelection);
    }

    private getConfiguredProductFromShoppingCart(
        selectedSku: string, activePosition: number, shoppingCartSelection: ShoppingCartSelection[],
    ): ConfiguredProduct | undefined | null {
        if (isNil(shoppingCartSelection[activePosition])) {
            return null;
        }
        const {selectedProduct, configuredProduct} = shoppingCartSelection[activePosition];
        return selectedProduct && selectedProduct?.sku === selectedSku && isNotNil(configuredProduct)
            ? configuredProduct : null;
    }

    private calculatePriceTotal(shoppingCartSelection: ShoppingCartSelection[]): string {
        const parseProductPrice = (priceExtracted: string): number => {
            const priceUnparsed = (priceExtracted.indexOf(',') >= 0)
                ? priceExtracted.replace(',', '.') : priceExtracted;
            const priceParsed = parseFloat(priceUnparsed);
            return isNaN(priceParsed) ? 0.0 : priceParsed;
        };
        return shoppingCartSelection.reduce((total: number, cart: ShoppingCartSelection) =>
            total + parseProductPrice(
                cart?.configuredProduct
                    ? cart.configuredProduct.final.nav_recommended_retail_price_net
                    : cart?.selectedProduct
                        ? cart.selectedProduct.nav_recommended_retail_price_net
                        : '',
            ), 0.0).toFixed(2);
    }

}
