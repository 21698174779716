import React from 'react';
import { Route } from 'react-router-dom';
import { AuthenticationFacade } from './facades/AuthenticationFacade';
import { useFacadeState } from './utils';
import { isNil } from '@w11k/rx-ninja';
import { UserTypes } from './user/user-types';

export function PrivateRoute(props: {
    path: string;
    exact: boolean;
    isAuthenticated: boolean;
    children: JSX.Element;
}) {
    console.log(props.isAuthenticated)
    if (!props.isAuthenticated) {
        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL ?? 'https://www.griffwert.eu/login/';
        return null;
    }


    return (<Route exact={props.exact} path={props.path}>
        {props.children}
    </Route>);

}

// INFO: Denies access for `UserTypes.PARTNER`
export function InternalGuard({authFacade, children}: {
    authFacade: AuthenticationFacade;
    children: JSX.Element;
}): JSX.Element | null {
    const userType = useFacadeState(authFacade, s => s.userType);
    if (isNil(userType) || UserTypes.PARTNER === userType) {
        window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL ?? 'https://www.griffwert.eu/login/';
        return null;
    }
    return children;
}
