import { Product } from '../../../domain';
import React, { useState } from 'react';
import { getDescription40char2 } from '../../../facades/OrderFacade';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { SearchCustomer } from '../../../product/ProductSearchCustomer';
import { testIdMessage } from '../../../product/ProductInfoText';
import { ShoppingCartSelection } from '../../../facades/ShoppingCartFacade';
import { convertToGermanCurrency } from '../basketEdit/BasketEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import './orderModal.scss';
import { UserTypes } from '../../../user/user-types';
import { modalValidation, submit } from './order-service';

export const getReadableOrderContent = (selection: ShoppingCartSelection[]): string[][][] =>
    selection.filter(s => !!s?.selectedProduct).map((s, i) => {
        const {
            sku,
            product_name,
            description_40_char_1,
            description_40_char_2,
            description_40_char_3,
            direction,
            nav_recommended_retail_price_net,
        } = s?.configuredProduct?.final ?? s.selectedProduct as Product;
        const description_40_char_2_conf = s?.configuredProduct
            ? getDescription40char2(s?.configuredProduct)
            : undefined;
        return [
            [
                description_40_char_1,
                sku,
            ],
            [
                description_40_char_2_conf ?? description_40_char_2,
                product_name,
            ],
            [
                description_40_char_3,
                `Richtung: ${direction}`,
            ],
            [
                convertToGermanCurrency(nav_recommended_retail_price_net),
            ],
        ];
    });

export const initialFormValue = {
    customerNumber: '',
    message: '',
};

export function TriggerOrderModal({priceTotal, basketSelection, handleReset, userType}: {
    priceTotal: string;
    basketSelection: ShoppingCartSelection[];
    handleReset: () => void;
    userType?:  UserTypes | undefined;
}): JSX.Element {
    const [show, setShow] = useState(false);

    const orderContent = getReadableOrderContent(basketSelection);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (<>
        <TriggerOrderModalButton handleShow={handleShow}/>
        <Formik
            initialValues={initialFormValue}
            validate={modalValidation}
            onSubmit={async (values, {setSubmitting}) => {
                const success = await submit(values, basketSelection, userType, priceTotal);
                setSubmitting(false);
                if (success) {
                    setShow(false);
                    handleReset();
                }
            }}
        >
            {() => (
                <Form>
                    <BasketOrderModal
                        show={show}
                        handleClose={handleClose}
                        orderContent={orderContent}
                        priceTotal={priceTotal}
                    />
                </Form>
            )}
        </Formik>
    </>);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const TriggerOrderModalButton = ({handleShow}: { handleShow: () => void }): JSX.Element => (
    <button onClick={handleShow} type="button" className="btn btn-primary">
        <FontAwesomeIcon icon={faCheck}/>
        Anfrage abschicken
    </button>
);

function BasketOrderModal({show, handleClose, orderContent, priceTotal}: {
    show: boolean;
    handleClose: () => void;
    orderContent: string[][][];
    priceTotal: string;
}): JSX.Element {
    const {isSubmitting, isValid, submitForm} = useFormikContext();
    const [isValidCustomer, setIsValidCustomer] = useState(false);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="order-modal"
            scrollable={true}
        >
            <div className="modal-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h4 className="modal-title" id="exampleModalLabel">Anfrage senden</h4>
                        </div>
                    </div>
                </div>
            </div>

            {/* scrollable content */}
            <div className="modal-body">
                <OrderSummary orderContent={orderContent} priceTotal={priceTotal}/>
            </div>

            {/* divider */}
            <div className="modal-footer" style={{padding: '.25rem'}}/>

            {/* non scrollable content */}
            <div className="modal-body" style={{flex: '1 0 auto'}}>
                <DataInput setIsValidCustomer={setIsValidCustomer}/>
            </div>

            <div className="modal-footer">
                <button
                    type="submit" className="btn btn-primary"
                    onClick={submitForm}
                    disabled={!isValidCustomer || isSubmitting || !isValid}
                >
                    Absenden
                </button>
                <button onClick={handleClose} type="button" className="btn btn-secondary" data-dismiss="modal">
                    Abbrechen
                </button>
            </div>
        </Modal>
    );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const OrderSummary = ({orderContent, priceTotal}: { orderContent: string[][][]; priceTotal: string }): JSX.Element => (
    <div className="container-fluid">
        <h5 className="pb-3">Zusammenfassung</h5>

        {orderContent.map((orderItem, i) => {
            const currentSku = orderItem[0][1];
            return (
                <div key={`${currentSku}-${i}`} className="row pb-3">
                    <div className="col">
                        {orderItem.map((orderRow, j) => (
                            <div key={`${currentSku}-${i}-${j}`} className="row">
                                {orderRow.map((orderInfo, k) => (
                                    <div
                                        key={`${currentSku}-${i}-${j}-${k}`}
                                        className={`${k % 2 === 0 ? 'col-6' : 'col'} ${j === 3 && 'pt-2'}`}
                                    >
                                        {orderInfo}
                                    </div>
                                ))
                                }
                            </div>
                        ))
                        }
                    </div>
                </div>
            );
        })
        }

        <div className="row">
            <div className="col" style={{fontWeight: 600}}>
                Gesamtbetrag
            </div>
        </div>
        <div className="row">
            <div className="col">
                <span className="">{convertToGermanCurrency(priceTotal)}</span>
                <span
                    className="text-muted pb-3">&emsp;UVP des Herstellers, alle Preise exkl. MwSt.</span>
            </div>
        </div>
    </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DataInput = ({setIsValidCustomer}: { setIsValidCustomer: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element => (
    <div className="container-fluid">
        <h5 className="pb-3">Ihre Daten</h5>
        <SearchCustomer setIsValidCustomer={setIsValidCustomer}/>
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <label>Bemerkungen</label>
                    <Field type="text" name="message" className="form-control"
                           as="textarea"
                           rows="2"
                           maxLength="250" {...testIdMessage.attr}/>
                </div>
            </div>
        </div>
    </div>
);
