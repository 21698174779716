import { Commands, Facade } from '@w11k/tydux';
import { CommandsState } from '@w11k/tydux/lib/commands';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isNil } from '@w11k/rx-ninja';

export type MemberTypeConverter<T, X> = {
    [P in keyof T]: X;
};

function runIfNotJest(fn: () => void) {
    try {
        if (process.env.JEST_WORKER_ID === undefined) {
            fn();
        }
    } catch (e) {
        // Ignore
    }
}

export const userMessages = {
    debug: (...args: unknown[]) => runIfNotJest(() => console.debug(...args)),
    log: (...args: unknown[]) => runIfNotJest(() => console.log(...args)),
    warn: (...args: unknown[]) => runIfNotJest(() => console.warn(...args)),
    error: (...args: unknown[]) => runIfNotJest(() => console.error(...args)),
    dir: (...args: unknown[]) => runIfNotJest(() => console.dir(...args)),
    groupCollapsed: (...args: unknown[]) => runIfNotJest(() => console.groupCollapsed(...args)),
};

export function getErrorMessageFromException(e: unknown): string {
    if (typeof e === 'string') {
        return e;
    } else if (e instanceof Error) {
        return e.message;
    } else {
        return 'unknown error';
    }
}

export function wrapHTMLElementWithModalApi(elem: HTMLElement | null) {
    if (elem === null) {
        return null;
    }

    return elem as unknown as { modal: (p: unknown) => void };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFacadeState<R, C extends Commands<any>>(
    facade: Facade<C>,
    selector?: (state: Readonly<CommandsState<C>>) => R): R;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFacadeState<R, C extends Commands<any>>(
    facade: Facade<C>,
    selector: (state: Readonly<CommandsState<C>>) => R,
    nilReplacement: NonNullable<R>): NonNullable<R>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFacadeState<R, C extends Commands<any>>(
    facade: Facade<C>,
    selector?: (state: Readonly<CommandsState<C>>) => R,
    nilReplacement?: NonNullable<R>): R {

    const [state, setState]: [R, Dispatch<SetStateAction<R>>] =
        useState(selector !== undefined
            ? selector(facade.state)
            : facade.state);

    if (isNil(state) && !isNil(nilReplacement)) {
        setState(nilReplacement);
    }

    useEffect(() => {
        const sub = facade.select(selector).subscribe(val => {
            if (isNil(nilReplacement) || !isNil(val)) {
                setState(val);
            } else {
                setState(nilReplacement);
            }
        });
        return () => sub.unsubscribe();
    }, [facade, selector, nilReplacement]);

    return state;
}

export function getHeadersByEnv(): { 'Content-Type': string, 'Authorization': string } {
    return process.env.REACT_APP_BASIC_AUTH_TOKEN ? {
        'Content-Type': 'application/json',
        Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_TOKEN}`,
    } : {
        'Content-Type': 'application/json',
    } as { 'Content-Type': string, 'Authorization': string };
}
