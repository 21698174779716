import { Commands, createAssignCommand, Facade } from '@w11k/tydux';
import {
    checkIsSlidingDoor,
    ConfiguredProduct,
    Contact,
    Design,
    isCustomizableProduct,
    isDirectionLR,
    isOfTypeOrderGetFromBackend,
    ItemFromBackend,
    L_R,
    ModificationsFromBackend,
    Norm,
    Order,
    OrderGetFromBackend,
    OrderGetFromBackendV2,
    OrderItemV2,
    OrderPostToBackend,
    OrderPostToBackendV2,
    OrderV2,
    Product,
} from '../domain';
import { sendOrderViewErrorAnalyticsEvent, sendOrderViewSuccessAnalyticsEvent } from '../analytics/analytics-service';
import { userMessages } from '../utils';
import { ProductSelectionFacade, searchMatchingProducts } from './ProductSelectionFacade';
import { DatabaseFacade } from './DatabaseFacade';
import { combineLatestToMap, isNil, isNotNil } from '@w11k/rx-ninja';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ShoppingCartFacade, ShoppingCartSelection } from './ShoppingCartFacade';

type ProductsInformation = ((OrderItemV2 | Product | Norm | Design | undefined)[])[];

export class OrderState {
    order?: Partial<OrderV2>;
    statusIndicator?: 'loading' | 'error' | 'notFound' | 'success';
}

export class OrderCommands extends Commands<OrderState> {
    setOrder = createAssignCommand(this, 'order');
    setStatusIndicator = createAssignCommand(this, 'statusIndicator');
}

export class OrderFacade extends Facade<OrderCommands> {

    constructor(
        private readonly databaseFacade: DatabaseFacade,
        private readonly productSelectionFacade: ProductSelectionFacade,
        private readonly shoppingCartFacade: ShoppingCartFacade,
    ) {
        super('order', new OrderCommands(), new OrderState());

        combineLatestToMap({
            order: this.selectNonNil(s => s.order),
            databaseStatePartial: databaseFacade.selectNonNil(s => ({
                normsById: s.normById,
                availableProducts: s.availableProducts,
                availableProductsBySku: s.availableProductsBySku,
            })),
        }).pipe(
            map(({order, databaseStatePartial}): ProductsInformation | [] | undefined => {
                const {normsById, availableProducts, availableProductsBySku} = databaseStatePartial;

                return order?.items?.reduce(
                    (acc: ((OrderItemV2 | Product | Norm | Design | undefined)[])[], item: OrderItemV2) => {
                        const sku = item.product.sku;
                        const matchingProducts = searchMatchingProducts(sku, availableProducts, availableProductsBySku);
                        const product = this.getMatchingProduct(matchingProducts, item);
                        const norm = this.getMatchingNorm(normsById, item, product);
                        const design = this.getMatchingDesign(item, product);

                        return [
                            ...acc,
                            [
                                item,
                                product,
                                norm,
                                design,
                            ],
                        ];

                    }, []) as ProductsInformation;
            }),
            filter(productsInformation => !!productsInformation),
            map(productsInformation =>
                (productsInformation as ProductsInformation)
                    .filter(([order, product, norm]) => !!(order && product && norm))
                    .map(([order, product, norm, design]) => ({
                            selectedProduct: product as Product,
                            configuredProduct: createConfiguredProductFromOrderAndProduct(
                                order as OrderItemV2,
                                product as Product,
                                norm as Norm,
                                design as Design | undefined,
                            ) as ConfiguredProduct,
                        }),
                    )),
            filter(shoppingCart => shoppingCart.length > 0),
            takeUntil(this.observeDestroyed()),
        ).subscribe((shoppingCart: ShoppingCartSelection[]) => {
            this.shoppingCartFacade.setShoppingCartSelection(shoppingCart);
            this.commands.setStatusIndicator('success');
        });
    }

    clear() {
        this.commands.setOrder(undefined);
    }

    async loadOrderById(orderId: string): Promise<void> {
        this.commands.setOrder(undefined);
        this.commands.setStatusIndicator('loading');

        const headers = process.env.REACT_APP_BASIC_AUTH_TOKEN ? {
            'Content-Type': 'application/json',
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH_TOKEN}`,
        } : {
            'Content-Type': 'application/json',
        } as { 'Content-Type': string, 'Authorization': string };

        /**
         *  NOTE: Use below code if backend was updated.
         *      -> At this time BE responds with old order format,
         *         therefore a mocked response is used.
         */
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/configurator-api/v2/order/load/${orderId}`,
            {
                method: 'GET',
                credentials: 'include',
                headers,
            });

        /* MOCK */
        // const response = {
        //     status: 200,
        //     // status: 404,
        //     // status: 500,
        //     json: (): Promise<OrderGetFromBackendV2> =>
        //         new Promise(r => setTimeout(() => r(orderMOCK), 3500)),
        // };

        if (response.status === 200) {

            const orderWithGermanAttributes = await response.json() as OrderGetFromBackendV2;

            sendOrderViewSuccessAnalyticsEvent(orderId, orderWithGermanAttributes.items);

            if (isNil(orderWithGermanAttributes) || Object.keys(orderWithGermanAttributes).length === 0) {
                this.commands.setStatusIndicator('error');
                return;
            }

            const order = convertToOrderV2(orderWithGermanAttributes);

            if (isNil(order)) {
                this.commands.setStatusIndicator('error');
                return;
            }

            // Für den Fall, dass im Order keine SKU enthalten ist.
            // Dürfte eigentlich nicht passieren, aber so ist es robuster.
            if (isNil(order.items[0].product.sku)) {
                this.commands.setStatusIndicator('error');
                return;
            }

            this.commands.setOrder(order);
            this.shoppingCartFacade.setSkuAndUpdateMatchingProducts(order.items[0].product.sku, 0);

        } else if (response.status === 404) {
            sendOrderViewErrorAnalyticsEvent(orderId);
            this.commands.setStatusIndicator('notFound');
        } else {
            userMessages.error('Error fetching order', response.status);
            sendOrderViewErrorAnalyticsEvent(orderId);
            this.commands.setStatusIndicator('error');
        }

        return;
    }

    private getMatchingProduct(mProducts: Product[], orderItem: OrderItemV2): Product | undefined {
        return mProducts.find(matching =>
            matching.sku === orderItem.product.sku
            &&
            matching.door_type_multi === orderItem.modifications.door_type_multi,
        );
    }

    private getMatchingNorm(norms: { [p: string]: Norm },
                            orderItem: OrderItemV2,
                            mProduct: Product | undefined): Norm | undefined {
        let matchingNorm = norms[orderItem.modifications.norm_code];
        // If the order does not include a norm_code, take the norm_code from the available products
        if (!matchingNorm && mProduct) {
            matchingNorm = norms[mProduct.norms[0].code];
        }
        return matchingNorm;
    }

    private getMatchingDesign(orderItem: OrderItemV2, mProduct: Product | undefined): Design | undefined {
        const designCode = orderItem?.modifications?.design_code;
        const designs = mProduct?.designs;
        return designs?.find(d => d.code === designCode);
    }

}

export function createOrderFromConfiguredProduct(customerNumber: string,
                                                 message: string,
                                                 configuredProduct: ConfiguredProduct): OrderPostToBackend {

    const description_40_char_2 = getDescription40char2(configuredProduct);
    const modifications = getModificationsBasedOnDoorType(configuredProduct);

    return {
        customerNumber,
        message,
        product: {
            sku: configuredProduct.final.sku,
            description_40_char_1: configuredProduct.final.description_40_char_1,
            description_40_char_2,
            description_40_char_3: configuredProduct.final.description_40_char_3,
        },
        modifications,
    };
}

export function convertToOrderPostToBackendV2(order: OrderPostToBackend): OrderPostToBackendV2 {
    const {
        customerNumber,
        message,
        product,
        modifications,
    } = order;

    return {
        type: '',
        customerNumber,
        ext_voucher: '',
        message,
        contact: {} as Contact,
        items: [
            {
                product: {
                    ...product,
                    nav_no: modifications.Artikelnummer,
                    quantity: 1,
                    configuration_url: '',
                },
                modifications,
            },
        ],
    };
}

export function createOrderFromConfiguredProducts(customerNumber: string,
                                                  message: string,
                                                  basketSelection: ShoppingCartSelection[]): OrderPostToBackendV2 {
    const items = basketSelection
        .map(basketItem => {
            const {selectedProduct, configuredProduct} = basketItem;
            if (!configuredProduct && selectedProduct) {

                const norm = selectedProduct.norms[0];
                const direct = (selectedProduct.direction as string === '' || isDirectionLR(selectedProduct.direction))
                    ? 'L' : selectedProduct.direction;
                const mitte_falle = 1068;
                const mitte_falle_top = selectedProduct.height - mitte_falle;

                return convertToConfiguredProduct(
                    {
                        product: selectedProduct,
                        norm: selectedProduct.norms[0],
                        design: selectedProduct.designs[0],
                    }, {
                        richtung: direct,
                        lock_drilling: norm?.norm_lock_drilling,
                        hinge_drilling: norm?.norm_hinge_drilling,
                        width: selectedProduct.width,
                        height: selectedProduct.height,
                        mitte_falle,
                        mitte_falle_top,
                    },
                );
            }

            if (configuredProduct) {
                return configuredProduct;
            }
            return undefined;
        })
        .map(configured => {
            if (isNotNil(configured)) {
                const description_40_char_2 = getDescription40char2(configured);
                const modifications = getModificationsBasedOnDoorType(configured);

                return {
                    product: {
                        sku: configured.final.sku,
                        description_40_char_1: configured.final.description_40_char_1,
                        description_40_char_2,
                        description_40_char_3: configured.final.description_40_char_3,
                    },
                    modifications,
                };
            }
            return undefined;
        })
        .filter(configured => !!configured) as ItemFromBackend[];

    return {
        type: '',
        customerNumber,
        ext_voucher: '',
        message,
        contact: {} as Contact,
        items: (items ?? []) as ItemFromBackend[],
    } as OrderPostToBackendV2;
}

function convertToConfiguredProduct(
    matchingProductData: {
        product: Product,
        norm: Norm,
        design?: Design,
    },
    options: {
        richtung: L_R,
        lock_drilling: string,
        hinge_drilling: string;
        width: number,
        height: number,
        mitte_falle: number,
        mitte_falle_top: number,
    }) {

    const {product, norm, design} = matchingProductData;
    const final: ConfiguredProduct['final'] = {
        sku: product.sku,
        norm,
        width: options.width,
        height: options.height,
        length: product.length,
        color: product.color,
        direction: options.richtung,
        glass_opacity: product.glass_opacity,
        glass_safety: product.glass_safety,
        valid_country_select: product.valid_country_select,
        product_name: product.product_name,
        nav_no: product.nav_no,
        preview_living_situation: product.preview_living_situation,
        nav_recommended_retail_price_net: product.nav_recommended_retail_price_net,
        description_40_char_1: product.description_40_char_1,
        description_40_char_2: product.description_40_char_2,
        description_40_char_3: product.description_40_char_3,
        door_type_multi: product.door_type_multi,
        glass_processing_front: product.glass_processing_front,
        glass_processing_back: product.glass_processing_back,
        design,
    };
    const modifications: ConfiguredProduct['modifications'] = {
        width: product.width !== options.width ? options.width : undefined,
        height: product.height !== options.height ? options.height : undefined,
        direction: product.direction !== options.richtung ? options.richtung : undefined,

        // mitte_falle: norm.lower_glass_corner_mid_latch_min !== options.mitte_falle ? options.mitte_falle : undefined,
        lower_glass_corner_mid_latch: options.mitte_falle,
        upper_glass_corner_mid_latch: options.height - options.mitte_falle,

        lock_drilling: options.lock_drilling,
        hinge_drilling: options.hinge_drilling,

        bbl1: norm?.bbl1_min,
        bbl2: norm?.bbl2_min,
        bbl3: norm?.bbl3_min,
    };

    return {
        final,
        modifications,
    };
}

export function convertV2ToOrderGetFromBackend(order: OrderGetFromBackendV2): OrderGetFromBackend {
    const {
        customerNumber,
        message,
        items,
    } = order;
    const {
        nav_no,
        quantity,
        configuration_url,
        ...product
    } = items[0].product;

    return {
        customerNumber,
        message,
        product,
        modifications: items[0].modifications as ModificationsFromBackend,
    };
}

export function getDescription40char2(configuredProduct: ConfiguredProduct): string {
    return isCustomizableProduct(configuredProduct.final.sku)
        ? `${configuredProduct.final.width}x${configuredProduct.final.height}x${configuredProduct.final.length}mm`
        : configuredProduct.final.description_40_char_2;
}

function getModificationsBasedOnDoorType(configuredProduct: ConfiguredProduct): OrderPostToBackend['modifications'] {
    const product = configuredProduct.final;
    const norm = configuredProduct.final.norm;
    const modifications = configuredProduct.modifications;

    const modificationsRotatingDoor = {
        Norm: norm.norm,
        Farbe: product.color,
        Richtung: modifications.direction ?? product.direction as L_R,
        Schlossbohrung: modifications.lock_drilling ?? norm.norm_lock_drilling,
        Bandbohrung: modifications.hinge_drilling ?? norm.norm_hinge_drilling,
        Glasbearbeitung_Vorderseite: product.glass_processing_front,
        Glasbearbeitung_Rückseite: product.glass_processing_back,
        Glastransparenz: product.glass_opacity,
        Glassicherheit: product.glass_safety,
        Land: product.valid_country_select,
        Bandversatz: norm.hinge_offset,
        Bandbezugslinie_1: norm.bbl1_min,
        Bandbezugslinie_2: norm.bbl2_min,
        Bandbezugslinie_3: norm.bbl3_min,
        Produktname: product.product_name,
        Artikelnummer: product.nav_no,
        Empfohlener_Verkaufspreis: product.nav_recommended_retail_price_net,

        Breite: modifications.width ?? product.width,
        Höhe: modifications.height ?? product.height,
        Länge: product.length,

        Untere_Glaskante_Mitte_Falle: modifications.lower_glass_corner_mid_latch,
        Obere_Glaskante_Mitte_Falle: modifications.upper_glass_corner_mid_latch,

        Designcode: product.design?.code,
        Türart: product.door_type_multi,
    };

    if (checkIsSlidingDoor(product.door_type_multi)) {
        const {
            Norm,
            Richtung,
            Schlossbohrung,
            Bandbohrung,
            Bandversatz,
            Bandbezugslinie_1,
            Bandbezugslinie_2,
            Bandbezugslinie_3,
            Untere_Glaskante_Mitte_Falle,
            Obere_Glaskante_Mitte_Falle,
            ...modificationsSlidingDoor
        } = modificationsRotatingDoor;

        return modificationsSlidingDoor;
    }

    return modificationsRotatingDoor;
}

function createConfiguredProductFromOrderAndProduct(order: OrderItemV2,
                                                    product: Product,
                                                    norm: Norm,
                                                    design: Design | undefined): ConfiguredProduct {
    const cp: ConfiguredProduct = {
        final: {
            sku: order.product.sku,
            norm,
            width: order.modifications.width ?? product.width,
            height: order.modifications.height ?? product.height,
            length: order.modifications.length ?? product.width,
            color: order.modifications.color
                ?? product.color,
            direction: order.modifications.direction ?? product.direction,
            glass_opacity: product.glass_opacity,
            glass_safety: product.glass_safety,
            valid_country_select: product.valid_country_select,
            product_name: product.product_name,
            preview_living_situation: product.preview_living_situation,
            nav_recommended_retail_price_net: product.nav_recommended_retail_price_net,
            description_40_char_1: product.description_40_char_1,
            description_40_char_2: product.description_40_char_2,
            description_40_char_3: product.description_40_char_3,
            nav_no: product.nav_no,
            door_type_multi: product.door_type_multi ?? product.door_type_multi,
            glass_processing_front: product.glass_processing_front,
            glass_processing_back: product.glass_processing_back,
            design,
        },
        modifications: {
            width: order.modifications.width ?? product.width,
            height: order.modifications.height ?? product.height,
            lower_glass_corner_mid_latch: order.modifications.lower_glass_corner_mid_latch,
            upper_glass_corner_mid_latch: order.modifications.upper_glass_corner_mid_latch,
            direction: order.modifications.direction,
            lock_drilling: order.modifications.lock_drilling,
            hinge_drilling: order.modifications.hinge_drilling,
            bbl1: order.modifications.bbl1,
            bbl2: order.modifications.bbl2,
            bbl3: order.modifications.bbl3,
        },
    };

    // 30.07.2021
    // Chris und ich (RR) haben die Struktur der Order wesentlich verändert.
    // Dadurch passen die derzeitigen Testdaten auf dem Server von Griffwerk nicht mehr zum Code.
    // Diverse Felder heißen nun anders oder sind neu. Um trotzdem Testen zu können und um
    // möglichst robust ggü. zukünftigen Änderungen zu sein, werden die die derzeit bekannten Felder hier
    // angepasst/korrigiert.
    replaceUndefinedAndLogWarning(cp.modifications, 'direction', 'L',
        'Feld "direction" wurde nicht im Order gefunden. "L" gesetzt.');

    replaceUndefinedAndLogWarning(cp.modifications, 'hinge_drilling', 'studio',
        'Feld "hinge_drilling" wurde nicht im Order gefunden. "studio" gesetzt.');

    replaceUndefinedAndLogWarning(cp.modifications, 'lock_drilling', 'studio',
        'Feld "lock_drilling" wurde nicht im Order gefunden. "studio" gesetzt.');

    replaceUndefinedAndLogWarning(cp.modifications, 'lower_glass_corner_mid_latch', 1068,
        'Feld "lower_glass_corner_mid_latch" wurde nicht im Order gefunden. "1068" gesetzt.');

    replaceUndefinedAndLogWarning(cp.modifications, 'upper_glass_corner_mid_latch', cp.final.height - 1068,
        'Feld "upper_glass_corner_mid_latch" wurde nicht im Order gefunden. "' + (cp.final.height - 1068) + '" gesetzt.');

    return cp;
}

function replaceUndefinedAndLogWarning<O, F extends keyof O>(obj: O, field: F, replaceWith: O[F], warning: string) {
    if (obj[field] === undefined) {
        obj[field] = replaceWith;
        userMessages.warn(warning);
    }
}

export function convertToOrderV2(orderGetFromBackendV2: OrderGetFromBackendV2): OrderV2 | null {
    const {items, ...rest} = orderGetFromBackendV2;
    if (!(items?.length > 0)) {
        return null;
    }
    return {
        ...rest,
        items: items.map(({product, modifications}: ItemFromBackend) => ({
            product,
            modifications: {
                norm_code: modifications.norm_code,
                norm: modifications.Norm,
                color: modifications.Farbe,
                direction: modifications.Richtung,
                lock_drilling: modifications.Schlossbohrung,
                hinge_drilling: modifications.Bandbohrung,
                glass_processing_front: modifications.Glasbearbeitung_Vorderseite,
                glass_processing_back: modifications.Glasbearbeitung_Rückseite,
                glass_opacity: modifications.Glassicherheit,
                glass_safety: modifications.Glassicherheit,
                valid_country_select: modifications.Land,
                hinge_offset: modifications.Bandversatz,
                bbl1: modifications.Bandbezugslinie_1,
                bbl2: modifications.Bandbezugslinie_2,
                bbl3: modifications.Bandbezugslinie_3,
                product_name: modifications.Produktname,
                nav_no: modifications.Artikelnummer,
                description_40_char_1: product.description_40_char_1,
                description_40_char_2: product.description_40_char_2,
                description_40_char_3: product.description_40_char_3,
                nav_recommended_retail_price_net: modifications.Empfohlener_Verkaufspreis,
                width: modifications.Breite,
                height: modifications.Höhe,
                length: modifications.Länge,
                lower_glass_corner_mid_latch: modifications.Untere_Glaskante_Mitte_Falle,
                upper_glass_corner_mid_latch: modifications.Obere_Glaskante_Mitte_Falle,
                design_code: modifications.Designcode,
                door_type_multi: modifications.Türart,
            },
        })),
    };
}

export function convertOrderGetFromBackendToOrder(order: OrderGetFromBackend | Order): Order {
    return {
        customerNumber: order.customerNumber,
        message: order.message,
        product: {
            sku: order.product.sku,
        },
        modifications: {
            norm_code: order.modifications.norm_code,
            norm: isOfTypeOrderGetFromBackend(order) ? order.modifications.Norm : order.modifications.norm,
            color: isOfTypeOrderGetFromBackend(order) ? order.modifications.Farbe : order.modifications.color,
            direction: isOfTypeOrderGetFromBackend(order) ? order.modifications.Richtung
                : order.modifications.direction,
            lock_drilling: isOfTypeOrderGetFromBackend(order) ? order.modifications.Schlossbohrung :
                order.modifications.lock_drilling,
            hinge_drilling: isOfTypeOrderGetFromBackend(order) ? order.modifications.Bandbohrung :
                order.modifications.hinge_drilling,
            glass_processing_front: isOfTypeOrderGetFromBackend(order)
                ? order.modifications.Glasbearbeitung_Vorderseite : order.modifications.glass_processing_front,
            glass_processing_back: isOfTypeOrderGetFromBackend(order)
                ? order.modifications.Glasbearbeitung_Rückseite : order.modifications.glass_processing_back,
            glass_opacity: isOfTypeOrderGetFromBackend(order) ? order.modifications.Glassicherheit :
                order.modifications.glass_opacity,
            glass_safety: isOfTypeOrderGetFromBackend(order) ? order.modifications.Glassicherheit :
                order.modifications.glass_safety,
            valid_country_select: isOfTypeOrderGetFromBackend(order) ? order.modifications.Land :
                order.modifications.valid_country_select,
            hinge_offset: isOfTypeOrderGetFromBackend(order) ? order.modifications.Bandversatz :
                order.modifications.hinge_offset,
            bbl1: isOfTypeOrderGetFromBackend(order) ? order.modifications.Bandbezugslinie_1 :
                order.modifications.bbl1,
            bbl2: isOfTypeOrderGetFromBackend(order) ? order.modifications.Bandbezugslinie_2 :
                order.modifications.bbl2,
            bbl3: isOfTypeOrderGetFromBackend(order) ? order.modifications.Bandbezugslinie_3 :
                order.modifications.bbl3,
            product_name: isOfTypeOrderGetFromBackend(order) ? order.modifications.Produktname :
                order.modifications.product_name,
            nav_no: isOfTypeOrderGetFromBackend(order) ? order.modifications.Artikelnummer :
                order.modifications.nav_no,
            description_40_char_1: isOfTypeOrderGetFromBackend(order) ? order.product.description_40_char_1 :
                order.modifications.description_40_char_1,
            description_40_char_2: isOfTypeOrderGetFromBackend(order) ? order.product.description_40_char_2 :
                order.modifications.description_40_char_2,
            description_40_char_3: isOfTypeOrderGetFromBackend(order) ? order.product.description_40_char_3 :
                order.modifications.description_40_char_3,
            nav_recommended_retail_price_net: isOfTypeOrderGetFromBackend(order)
                ? order.modifications.Empfohlener_Verkaufspreis
                : order.modifications.nav_recommended_retail_price_net,

            width: isOfTypeOrderGetFromBackend(order) ? order.modifications.Breite : order.modifications.width,
            height: isOfTypeOrderGetFromBackend(order) ? order.modifications.Höhe : order.modifications.height,
            length: isOfTypeOrderGetFromBackend(order) ? order.modifications.Länge : order.modifications.length,

            lower_glass_corner_mid_latch: isOfTypeOrderGetFromBackend(order)
                ? order.modifications.Untere_Glaskante_Mitte_Falle : order.modifications.lower_glass_corner_mid_latch,
            upper_glass_corner_mid_latch: isOfTypeOrderGetFromBackend(order)
                ? order.modifications.Obere_Glaskante_Mitte_Falle : order.modifications.upper_glass_corner_mid_latch,

            design_code: isOfTypeOrderGetFromBackend(order) ? order.modifications.Designcode :
                order.modifications.design_code,
            door_type_multi: isOfTypeOrderGetFromBackend(order) ? order.modifications.Türart :
                order.modifications.door_type_multi,
        },
    };
}

/* MOCK for testing: */
const orderMOCK: OrderGetFromBackendV2 = {
    type: 'type',
    customerNumber: 'customer',
    ext_voucher: 'ext',
    message: 'message',
    contact: {
        name: 'name',
        delivery_address_1: 'address',
        delivery_address_2: 'address',
        delivery_postcode: 'postcode',
        delivery_city: 'city',
        delivery_country: 'country',
        e_mail: 'mail',
    },
    items: [
        {
            'product': {
                'sku': '4250214382454',
                'description_40_char_1': 'Glasdrehtür NOVA 501',
                'description_40_char_2': 'ESG BASIC GREEN matt',
                'description_40_char_3': '709x1972x8mm Studio/Office DIN L',
                'nav_no': 'H501011012',
                'quantity': 1,
                'configuration_url': '',
            },
            'modifications': {
                'Norm': 'din_norm',
                'Farbe': 'BASIC GREEN',
                'Richtung': 'L',
                'Schlossbohrung': 'studio',
                'Bandbohrung': 'office',
                'Glasbearbeitung_Vorderseite': 'unbearbeitet',
                'Glasbearbeitung_Rückseite': 'siebbedruckt',
                'Glastransparenz': 'matt',
                'Glassicherheit': 'ESG',
                'Land': 'Deutschland',
                'Bandversatz': 10,
                'Bandbezugslinie_1': 241,
                'Bandbezugslinie_2': 1435,
                'Produktname': 'NOVA 501',
                'Artikelnummer': 'H501011012',
                'Empfohlener_Verkaufspreis': '126,04',
                'Breite': 709,
                'Höhe': 1972,
                'Länge': 8,
                'Untere_Glaskante_Mitte_Falle': 1068,
                'Obere_Glaskante_Mitte_Falle': 904,
                'Türart': 'Drehtür',
            } as ModificationsFromBackend,
        },
        {
            'product': {
                'sku': '4250214382454',
                'description_40_char_1': 'Glasdrehtür NOVA 501',
                'description_40_char_2': 'ESG BASIC GREEN matt',
                'description_40_char_3': '709x1972x8mm Studio/Office DIN L',
                'nav_no': 'H501011012',
                'quantity': 1,
                'configuration_url': '',
            },
            'modifications': {
                'Norm': 'din_norm',
                'Farbe': 'BASIC GREEN',
                'Richtung': 'L',
                'Schlossbohrung': 'studio',
                'Bandbohrung': 'office',
                'Glasbearbeitung_Vorderseite': 'unbearbeitet',
                'Glasbearbeitung_Rückseite': 'siebbedruckt',
                'Glastransparenz': 'matt',
                'Glassicherheit': 'ESG',
                'Land': 'Deutschland',
                'Bandversatz': 10,
                'Bandbezugslinie_1': 241,
                'Bandbezugslinie_2': 1435,
                'Produktname': 'NOVA 501',
                'Artikelnummer': 'H501011012',
                'Empfohlener_Verkaufspreis': '126,04',
                'Breite': 709,
                'Höhe': 1972,
                'Länge': 8,
                'Untere_Glaskante_Mitte_Falle': 1068,
                'Obere_Glaskante_Mitte_Falle': 904,
                'Türart': 'Drehtür',
            } as ModificationsFromBackend,
        },
        {
            'product': {
                'sku': 'X68Z986',
                'description_40_char_1': 'Sondermaß DECO 587',
                'description_40_char_2': '1000x2100x8mm',
                'description_40_char_3': 'ESG PURE WHITE LASD',
                'nav_no': '587033628',
                'quantity': 1,
                'configuration_url': '',
            },
            'modifications': {
                'Norm': 'din_norm',
                'Farbe': 'PURE WHITE',
                'Richtung': 'R',
                'Schlossbohrung': 'studio',
                'Bandbohrung': 'office',
                'Glasbearbeitung_Vorderseite': 'belasert',
                'Glasbearbeitung_Rückseite': 'siebbedruckt',
                'Glastransparenz': 'matt',
                'Glassicherheit': 'ESG',
                'Land': 'world',
                'Bandversatz': 10,
                'Bandbezugslinie_1': 241,
                'Bandbezugslinie_2': 1435,
                'Produktname': 'DECO 587',
                'Artikelnummer': '587033628',
                'Empfohlener_Verkaufspreis': '637,27',
                'Breite': 1000,
                'Höhe': 2100,
                'Länge': 8,
                'Untere_Glaskante_Mitte_Falle': 1060,
                'Obere_Glaskante_Mitte_Falle': 1040,
                'Designcode': '587_7',
                'Türart': 'Drehtür',
            } as ModificationsFromBackend,
        },
        {
            'product': {
                'sku': '4250214596370',
                'description_40_char_1': 'Glasdrehtür NOVA 623',
                'description_40_char_2': 'ESG BASIC GREEN klar',
                'description_40_char_3': '821x2013x8mm Studio/Office Ö-Norm L',
                'nav_no': 'H623014915',
                'quantity': 1,
                'configuration_url': '',
            },
            'modifications': {
                'Norm': 'oe_norm',
                'Farbe': 'BASIC GREEN',
                'Richtung': 'L',
                'Schlossbohrung': 'studio',
                'Bandbohrung': 'office',
                'Glasbearbeitung_Vorderseite': 'belasert',
                'Glasbearbeitung_Rückseite': 'unbearbeitet',
                'Glastransparenz': 'klar',
                'Glassicherheit': 'ESG',
                'Land': 'Österreich',
                'Bandversatz': 20,
                'Bandbezugslinie_1': 279,
                'Bandbezugslinie_2': 1440,
                'Produktname': 'NOVA 623',
                'Artikelnummer': 'H623014915',
                'Empfohlener_Verkaufspreis': '432,76',
                'Breite': 821,
                'Höhe': 2013,
                'Länge': 8,
                'Untere_Glaskante_Mitte_Falle': 1068,
                'Obere_Glaskante_Mitte_Falle': 945,
                'Designcode': '623_3',
                'Türart': 'Drehtür',
            } as ModificationsFromBackend,
        },
        {
            'product': {
                'sku': 'X9068TX',
                'description_40_char_1': 'Sondermaß NOVA 623',
                'description_40_char_2': '821x2013x8mm',
                'description_40_char_3': 'ESG BASIC GREEN LA',
                'nav_no': 'H623033715',
                'quantity': 1,
                'configuration_url': '',
            },
            'modifications': {
                'Norm': 'din_norm',
                'Farbe': 'BASIC GREEN',
                'Richtung': 'L',
                'Schlossbohrung': 'studio',
                'Bandbohrung': 'office',
                'Glasbearbeitung_Vorderseite': 'belasert',
                'Glasbearbeitung_Rückseite': 'unbearbeitet',
                'Glastransparenz': 'klar',
                'Glassicherheit': 'ESG',
                'Land': 'world',
                'Bandversatz': 10,
                'Bandbezugslinie_1': 241,
                'Bandbezugslinie_2': 1435,
                'Produktname': 'NOVA 623',
                'Artikelnummer': 'H623033715',
                'Empfohlener_Verkaufspreis': '588,23',
                'Breite': 821,
                'Höhe': 2013,
                'Länge': 8,
                'Untere_Glaskante_Mitte_Falle': 1070,
                'Obere_Glaskante_Mitte_Falle': 943,
                'Designcode': '623_1',
                'Türart': 'Drehtür',
            } as ModificationsFromBackend,
        },
    ],
};
