import { Commands, Facade, groupByUniqueId } from '@w11k/tydux';
import * as Papa from 'papaparse';
import { isNil } from '@w11k/rx-ninja';
import { Design, DesignPosX, DesignPosY, extractDoorTypeMulti, L_R_LR, Norm, Product } from '../domain';
import { userMessages } from '../utils';
import { DoorType } from './ProductCustomizeFacade';

export function convertCsvToObjectArray(csv: string): Record<string, string>[] {
    return Papa.parse(csv, {header: true}).data as Record<string, string>[];
}

function toNumberOr<O>(val: string | undefined, or: O): number | O {
    if (isNil(val)) {
        return or;
    } else if (val.trim().length === 0) {
        return or;
    }
    const parsed = parseInt(val, 10);

    if (Number.isNaN(parsed)) {
        return or;
    }

    return parsed;
}

export class DatabaseState {

    normList: Norm[] = [];
    normById: { [p: string]: Norm } = {};

    designsById: { [code: string]: Design; } = {};

    availableProducts: Product[] = [];
    availableProductsBySku: { [sku: string]: Product } = {};

}

export class DatabaseCommands extends Commands<DatabaseState> {

    setDesignsFromCsv(csv: Record<string, string>[]) {
        this.state.designsById = {};
        csv
            .filter(row => row.code.trim() !== '')
            .forEach(row => {
                this.state.designsById[row.code] = {
                    code: row.code,
                    processing: row.processing,
                    mirrorHorizontal: row.mirror_horizontal,
                    svgFile: row.svg_file,
                    heightMinDecor: parseInt(row.height_min_decor, 10),
                    heightMaxDecor: parseInt(row.height_max_decor, 10),
                    widthMinDecor: parseInt(row.width_min_decor, 10),
                    widthMaxDecor: parseInt(row.width_max_decor, 10),
                    posX: row['pos-x'].toLowerCase() as DesignPosX,
                    posY: row['pos-y'].toLowerCase() as DesignPosY,
                    scale: row.scale.toLowerCase() === 'true' || row.scale.toLowerCase() === 'ja',
                };
            });
    }

    setNormsFromCsv(csv: Record<string, string>[]) {
        const norms: Norm[] = csv
            .filter(row => row.code.trim() !== '')
            .map(row => ({
                code: row.code,
                norm: row.norm,
                hinge_offset: toNumberOr(row.hinge_offset, 10),
                norm_height_min: parseInt(row.norm_height_min, 10),
                norm_height_max: parseInt(row.norm_height_max, 10),
                norm_hinge_drilling: row.norm_hinge_drilling.toLowerCase(),
                norm_lock_drilling: row.norm_lock_drilling.toLowerCase(),
                bbl1_min: parseInt(row.bbl1_min, 10),
                bbl1_max: parseInt(row.bbl1_max, 10),
                bbl2_min: parseInt(row.bbl2_min, 10),
                bbl2_max: parseInt(row.bbl2_max, 10),
                bbl3_min: toNumberOr(row.bbl3_min, undefined),
                bbl3_max: toNumberOr(row.bbl3_max, undefined),
                lower_glass_corner_mid_latch_min: toNumberOr(row.lower_glass_corner_mid_latch_min, undefined),
                lower_glass_corner_mid_latch_max: toNumberOr(row.lower_glass_corner_mid_latch_max, undefined),
                upper_glass_corner_mid_latch_max: toNumberOr(row.upper_glass_corner_mid_latch_max, undefined),
                upper_glass_corner_mid_latch_min: toNumberOr(row.upper_glass_corner_mid_latch_min, undefined),
            }));

        this.state.normList = norms;
        this.state.normById = groupByUniqueId(norms, n => n.code);
    }

    setAvailableProductsFromCsv(csv: Record<string, string>[]) {
        const products: Product[] = csv
            .filter(row => row.sku.trim() !== '')
            .flatMap(row => {
                // Norms
                const norms = (row.id_ref_norm as string)
                    .split(',')
                    .flatMap(n => {
                        const normId = n.trim();
                        const norm = this.state.normById[normId];
                        return !isNil(norm) ? [norm] : [];
                    });

                const sku = row.sku;
                const isSlidingDoor = extractDoorTypeMulti(row.door_type_multi).includes(DoorType.SlidingDoor);

                // Referenziert das Produkt korrekt eine Norm?
                if (norms.length === 0 && !isSlidingDoor) {
                    userMessages.warn(`Keine norms für '${sku}'. Produkt wird ignoriert.`);
                    return [];
                }
                // nur X-Produkte dürfen mehr als eine Norm haben
                else if (norms.length > 1 && sku.indexOf('X') === -1) {
                    userMessages.warn(`'${sku}' referenziert mehr als eine norm, ist aber kein X-Produkt. Produkt wird ignoriert.`);
                    return [];
                }

                // Designs
                const designs: Design[] = (row.ref_design as string)
                    .split(',')
                    .map(id => id.trim())
                    .filter(id => id.length > 0)
                    .flatMap(id => {
                        const design = this.state.designsById[id];
                        if (isNil(design)) {
                            userMessages.warn(`Design ${id} für Produkt ${sku} nicht gefunden`);
                            return [];
                        }
                        return [design];
                    });

                const product: Product = {
                    brand: row.brand,
                    color: row.color,
                    description_40_char_1: row.description_40_char_1,
                    description_40_char_2: row.description_40_char_2,
                    description_40_char_3: row.description_40_char_3,
                    direction: row.direction as L_R_LR,
                    drilling_template: row.drilling_template,
                    family: row.family,
                    glass_opacity: row.glass_opacity,
                    glass_safety: row.glass_safety,
                    height: parseInt(row.height, 10),
                    height_max: toNumberOr(row.height_max, undefined),
                    length: parseInt(row.length, 10),
                    // lock_drilling: row.lock_drilling,
                    // hinge_drilling: row.hinge_drilling,
                    glass_processing_front: row.glass_processing_front,
                    glass_processing_back: row.glass_processing_back,
                    nav_no: row.nav_no,
                    product_name: row.product_name,
                    sku: row.sku,
                    valid_country_select: row.valid_country_select,
                    width: parseInt(row.width, 10),
                    width_max: toNumberOr(row.width_max, undefined),
                    nav_recommended_retail_price_net: row.nav_recommended_retail_price_net,
                    preview_living_situation: row.preview_living_situation,
                    // bbl1: toNumberOr(row.bbl1, undefined),
                    // bbl2: toNumberOr(row.bbl2, undefined),
                    // bbl3: toNumberOr(row.bbl3, undefined),
                    // mitte_falle: toNumberOr(row.mitte_falle, undefined),
                    door_type_multi: row.door_type_multi,

                    norms,
                    designs,
                };
                return [product];
            });

        this.state.availableProducts = products;
        this.state.availableProductsBySku = groupByUniqueId(products, p => p.sku);
    }

}

// const localStorageDatabaseKey = 'database';
let database: string | null = null;

export interface DatabaseFacade extends Facade<DatabaseCommands> {
    loadTables(force?: boolean): Promise<void>;
}

export class GoogleSheetDatabaseFacade extends Facade<DatabaseCommands> implements DatabaseFacade {

    constructor() {
        super('database', new DatabaseCommands(), () => {
            // const databaseItem = localStorage.getItem(localStorageDatabaseKey);
            const databaseItem = database;
            if (databaseItem !== null) {
                return JSON.parse(databaseItem) as DatabaseState;
            }
            return new DatabaseState();
        });
    }

    async loadTables(force = true) {
        if (!force && this.state.availableProducts.length > 0) {
            return;
        }

        await Promise.all([
            this.loadNormTable(),
            this.loadDesignTable(),
        ]);

        await this.loadProductTable();

        // localStorage.setItem(localStorageDatabaseKey, JSON.stringify(this.state));
        database = JSON.stringify(this.state);
    }

    private async loadNormTable() {
        const data =
            // eslint-disable-next-line max-len
            await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vRWaxH6_OaduiDL1v23wyYzo2bwSD3az9bZUYNMbk94l57E0a_nI6IiE_sPx4KYoNdZnoymxm2ez_F3/pub?gid=1787363016&single=true&output=csv');

        const buffer = await data.arrayBuffer();
        const decoder = new TextDecoder();
        const text = decoder.decode(buffer);
        const parsed = convertCsvToObjectArray(text);

        this.commands.setNormsFromCsv(parsed);
    }

    private async loadDesignTable() {
        const data =
            // eslint-disable-next-line max-len
            await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vRWaxH6_OaduiDL1v23wyYzo2bwSD3az9bZUYNMbk94l57E0a_nI6IiE_sPx4KYoNdZnoymxm2ez_F3/pub?gid=2140143383&single=true&output=csv');

        const buffer = await data.arrayBuffer();
        const decoder = new TextDecoder();
        const text = decoder.decode(buffer);
        const parsed = convertCsvToObjectArray(text);

        this.commands.setDesignsFromCsv(parsed);
    }

    private async loadProductTable() {
        const data =
            // eslint-disable-next-line max-len
            await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vRWaxH6_OaduiDL1v23wyYzo2bwSD3az9bZUYNMbk94l57E0a_nI6IiE_sPx4KYoNdZnoymxm2ez_F3/pub?gid=958684333&single=true&output=csv');

        const buffer = await data.arrayBuffer();
        const decoder = new TextDecoder();
        const text = decoder.decode(buffer);
        const parsed = convertCsvToObjectArray(text);

        this.commands.setAvailableProductsFromCsv(parsed);
    }

}
