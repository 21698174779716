import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductCustomize } from './ProductCustomize';
import { useFacadeState } from '../utils';
import { isNil } from '@w11k/rx-ninja';
import { ProductInfoText } from './ProductInfoText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { parseSkuUrlString } from './sku';
import { useIsMobileView } from '../hooks/use-is-mobile-view';
import { AppFacades } from '../facades/app-facades';
import { ProductSketch } from './ProductSketch';
import { NoProductFound } from '../order/NoProductFound';
import { Footer } from '../Footer';


// function useProductSelection(): ProductSelection {
//     const query = new URLSearchParams(useLocation().search);
//     return {
//         land: query.get("land") ?? "de",
//         tuerArt: query.get("tuerArt") ?? "gdt",
//         marke: query.get("marke") ?? "brand_gw",
//         dekor: query.get("dekor") ?? "frame",
//     }
// }

// function useSkuFromUrl() {
//     const query = new URLSearchParams(useLocation().search);
//     return query.get("sku") ?? undefined;
// }

// function setProductSelection(history: H.History, current: ProductSelection, override: Partial<ProductSelection>) {
//     const params = {
//         ...current,
//         ...override
//     };
//
//     const search: string[] = [];
//     for (let paramsKey in params) {
//         if ((params as any)[paramsKey] != null) {
//             search.push(`${paramsKey}=${encodeURIComponent((params as any)[paramsKey])}`);
//         }
//     }
//
//     const currentSearch = window.location.search.substr(1); // führendes '?' entfernen
//     const newSearch = search.join("&");
//     if (currentSearch !== newSearch) {
//         history.push({
//             search: newSearch
//         });
//     }
// }

export function ProductPage(props: { facades: AppFacades/*, sku?: string*/ }) {
    const [customizingScreenActive, setCustomizingScreenActive] = useState(true);
    const params = useParams() as { sku: string };
    const mobileActive = useIsMobileView();
    const configuredProduct = useFacadeState(props.facades.productCustomizeFacade, s => s.configuredProduct);
    const availableProducts = useFacadeState(props.facades.databaseFacade, s => s.availableProducts);
    const productTemplate = useFacadeState(props.facades.productSelectionFacade, s => s.productTemplate);

    const skuInfo = parseSkuUrlString(params.sku);
    // props.facades.productSelectionFacade.setSkuAndUpdateMatchingProducts(skuInfo.sku);

    if (isNil(availableProducts) || availableProducts.length === 0) {
        return <LoadingSpinner/>;
    }

    return (
        <div className="d-flex flex-column h-100">

            <div className="mobile-header only-mobile d-flex align-items-center"
                 style={{backgroundColor: 'rgba(68, 68, 68, 1)'}}>
                <div className="toggle-screen-button ml-auto d-flex align-items-center cursor-pointer"
                     onClick={() => setCustomizingScreenActive(!customizingScreenActive)}>
                    {(customizingScreenActive) &&
                    <>
                        Vorschau anzeigen
                        <span className="material-icons MuiIcon-root">chevron_right</span>
                    </>}
                    {(!customizingScreenActive) &&
                    <>
                        <span className="material-icons MuiIcon-root">chevron_left</span>
                        Weiter bearbeiten
                    </>
                    }
                </div>
            </div>

            <div className="product-page-below-mobile-menu d-flex">
                {(customizingScreenActive || !mobileActive) &&
                <>
                    {/* Produkt konfigurieren */}
                    <div className="product-customize-wrapper">
                        <ProductCustomize productCustomizeFacade={props.facades.productCustomizeFacade}
                                          disabledMode={false}/>
                    </div>
                </>
                }

                {/* Produkt gefunden */}
                {(!isNil(configuredProduct) && !isNil(configuredProduct.final) && (!customizingScreenActive || !mobileActive)) &&
                <>
                    {/* Technische Zeichnung */}
                    <ProductSketch configuredProduct={configuredProduct} productTemplate={productTemplate}/>

                    {/* Produktbeschreibung */}
                    <div className="product-info-text-wrapper">
                        <ProductInfoText configuredProduct={configuredProduct} allowOrderSubmit={true}/>
                        <Footer/>
                    </div>
                </>
                }

                {/* Kein Produkt gefunden */}
                {isNil(configuredProduct) && <NoProductFound/>
                }
            </div>
        </div>
    );
}

export function LoadingSpinner() {
    return (
        <div style={{width: '100%', height: '100%'}} className="d-flex justify-content-center">
            <div className="align-self-center" style={{fontSize: '30px'}}>
                <div className="text-center">
                    <FontAwesomeIcon icon={faHourglassHalf}/>
                    <br/>
                    Loading...
                </div>
            </div>
        </div>
    );
}
