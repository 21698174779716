import React, { useState } from 'react';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { ToggleButton } from 'react-bootstrap';
import { RegisterFormControlFn } from './lock-drilling-utils';

enum LockDrillingDirection {Top, Bottom}

export function LockDrillingDirectionSwitch(props: {
    registerFormControl: RegisterFormControlFn,
    disabledMode: boolean,
}) {
    const [lockDrillingDirection, setLockDrillingDirection] = useState(LockDrillingDirection.Top);
    return (<>
        <div className="lock-drilling-switch">
            <div className="switch-label">
                <span className="label">Abstand Schlossbohrung von</span>
                <ToggleButtonGroup name="lock-drilling"
                                   type="radio"
                                   defaultValue={LockDrillingDirection.Top}>
                    <ToggleButton id="lock-drilling-top"
                                  type="radio"
                                  variant="primary"
                                  checked={lockDrillingDirection === LockDrillingDirection.Top}
                                  value={LockDrillingDirection.Top}
                                  onChange={() => setLockDrillingDirection(LockDrillingDirection.Top)}
                                  disabled={props.disabledMode}>
                        Oben
                    </ToggleButton>
                    <ToggleButton id="lock-drilling-bottom"
                                  type="radio"
                                  variant="primary"
                                  checked={lockDrillingDirection === LockDrillingDirection.Bottom}
                                  value={LockDrillingDirection.Bottom}
                                  onChange={() => setLockDrillingDirection(LockDrillingDirection.Bottom)}
                                  disabled={props.disabledMode}>
                        Unten
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {lockDrillingDirection === LockDrillingDirection.Top &&
            <input type="number"
                   className="form-control"
                   {...props.registerFormControl('mitte_falle_top')}
                   disabled={props.disabledMode}/>
            }
            {lockDrillingDirection === LockDrillingDirection.Bottom &&
            <input type="number"
                   className="form-control"
                   {...props.registerFormControl('mitte_falle')}
                   disabled={props.disabledMode}/>
            }
        </div>
    </>);
}
