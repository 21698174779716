import React, { CSSProperties, useRef } from 'react';
import {
    checkIsSlidingDoor,
    ConfiguredProduct,
    isDirectionL,
    isOfficeDrilling,
    isStudioDrilling,
    L_R_LR,
    Product,
} from '../../../domain';
import { isNil } from '@w11k/rx-ninja';
import { downloadDekor } from '../../../product/dekor-download';
import { Dekor } from '../../../product/Dekor';

import './productSketch.scss';

const OFFSET = 75;
const OFF_TEXT_N = -10;
const OFF_TEXT_E = +25;
const OFF_TEXT_W = -30;
const WM_VERTICAL: CSSProperties = {writingMode: 'vertical-lr'};

export default function ProductSketch(props: {
    configuredProduct: ConfiguredProduct,
    productTemplate: Product | undefined,
    hideSvgDownloadButton?: boolean,
}) {

    const product = props.configuredProduct.final;
    const isSlidingDoor = checkIsSlidingDoor(product.door_type_multi);
    const initialDirection: L_R_LR | undefined = props.productTemplate?.direction;

    const viewBoxHeight = product.height + 330;
    const viewBoxWidth = product.width + 350;

    const dekorRef = useRef<SVGSVGElement>(null);

    return (<>
        {
            !props.hideSvgDownloadButton && !isNil(product.design) &&
            <button onClick={() => downloadDekor(dekorRef.current)}
                    style={{position: 'fixed', visibility: dekorRef === null ? 'hidden' : 'visible'}}>
                Export SVG
            </button>
        }

        <svg ref={dekorRef} xmlns="http://www.w3.org/2000/svg"
             className="svg align-self-center w-100"
             viewBox={`-150 -150 ${viewBoxWidth} ${viewBoxHeight}`}
        >

            {/* Definition for Arrow-Heads*/}
            <defs>
                <marker id="arrowhead_start" markerWidth="7" markerHeight="5" refX="7" refY="2.5"
                        orient="auto-start-reverse">
                    <polygon points="0 0, 7 2.5, 0 5"/>
                </marker>
                <marker id="arrowhead_end" markerWidth="7" markerHeight="5" refX="7" refY="2.5" orient="auto">
                    <polygon points="0 0, 7 2.5, 0 5"/>
                </marker>
            </defs>

            {/* Dekor*/}
            {
                !isNil(product.design) &&
                <Dekor configuredProduct={props.configuredProduct} design={product.design}
                       initialDirection={initialDirection}/>
            }

            {/* Tür */}
            <Tuer width={product.width} height={product.height} configuredProduct={props.configuredProduct}/>

            {!isSlidingDoor && <>

                {/* Schlossbohrung */}
                <Schlossbohrung configuredProduct={props.configuredProduct}/>

                {/* Bänder */}
                {!isNaN(product.norm.bbl1_min) &&
                <Band configuredProduct={props.configuredProduct} bandNo={1}/>
                }
                {!isNaN(product.norm.bbl2_min) &&
                <Band configuredProduct={props.configuredProduct} bandNo={2}/>
                }
                {!isNil(product.norm.bbl3_min) && !isNaN(product.norm.bbl3_min) &&
                <Band configuredProduct={props.configuredProduct} bandNo={3}/>
                }
            </>}

        </svg>

        {/*{(isNil(svg) || svg.loading) &&*/}
        {/*<LoadingSpinner/>*/}
        {/*}*/}
    </>);
}

function Tuer(props: { width: number, height: number, configuredProduct: ConfiguredProduct }) {
    const product = props.configuredProduct.final;
    const mods = props.configuredProduct.modifications;
    const isSlidingDoor = checkIsSlidingDoor(product.door_type_multi);

    const bl3_exists = !isNil(mods.bbl3);
    const offset_multiplier = bl3_exists ? 3 : isSlidingDoor ? 1 : 2;

    return (
        <>
            {/* Tür */}
            {/* oben */}
            <line x1={0} y1={0} x2={props.width} y2={0} className="door-outline"/>
            {/* rechts */}
            <line x1={props.width} y1={0} x2={props.width} y2={props.height} className="door-outline"/>
            {/* unten */}
            <line x1={0} y1={props.height} x2={props.width} y2={props.height} className="door-outline"/>
            {/* links */}
            <line x1={0} y1={0} x2={0} y2={props.height} className="door-outline"/>

            {/* Abmessungen */}
            {/* Breite Linie */}
            <>
                <line x1={0} y1={-OFFSET} x2={props.width} y2={-OFFSET} className="measurement"
                      markerStart="url(#arrowhead_start)"
                      markerEnd="url(#arrowhead_end)"/>
                <line x1={0} y1={0} x2={0} y2={-OFFSET} className="measurement"/>
                <line x1={props.width} y1={0} x2={props.width} y2={-OFFSET} className="measurement"/>
                <text x={props.width / 2} y={-OFFSET + OFF_TEXT_N}>{props.width}</text>

                {/* Gesamte Höhe*/}
                <line x1={getX(props.width + OFFSET * offset_multiplier, product.direction, props.width)}
                      y1={0}
                      x2={getX(props.width + OFFSET * offset_multiplier, product.direction, props.width)}
                      y2={props.height} className="measurement"
                      markerStart="url(#arrowhead_start)" markerEnd="url(#arrowhead_end)"/>
                <line x1={getX(props.width, product.direction, props.width)} y1={0}
                      x2={getX(props.width + OFFSET * offset_multiplier, product.direction, props.width)}
                      y2={0}
                      className="measurement"/>
                <line x1={getX(props.width, product.direction, props.width)} y1={props.height}
                      x2={getX(props.width + OFFSET * offset_multiplier, product.direction, props.width)}
                      y2={props.height} className="measurement"/>
                <text
                    x={getX(props.width + OFFSET * offset_multiplier + OFF_TEXT_E, product.direction, props.width)}
                    y={props.height / 2}
                    style={WM_VERTICAL}>{props.height}</text>
            </>
        </>
    );
}

function Schlossbohrung(props: { configuredProduct: ConfiguredProduct }) {
    const p = props.configuredProduct.final;
    const mods = props.configuredProduct.modifications;
    const mitte_falle = mods.lower_glass_corner_mid_latch;
    const mitte_falle_top = p.height - mitte_falle;

    const knobOffset = 60;
    const knobRadius = 20;
    const width = p.width;

    return (
        <>
            <circle cx={getX(knobOffset, p.direction, width)} cy={p.height - mitte_falle} r={knobRadius}
                    fill="white"
                    stroke="black"
                    strokeWidth="3"/>
            {isStudioDrilling(mods.lock_drilling) &&
            <circle cx={getX((knobOffset * 2), p.direction, width)} cy={p.height - mitte_falle} r={knobRadius}
                    fill="white"
                    stroke="black"
                    strokeWidth="3"/>
            }
            {isOfficeDrilling(mods.lock_drilling) &&
            <circle cx={getX(knobOffset, p.direction, width)} cy={p.height - mitte_falle + knobOffset}
                    r={knobRadius}
                    fill="white"
                    stroke="black"
                    strokeWidth="3"/>
            }

            {/* Oberste Begrenzungslinie */}
            <line x1={getX(-OFFSET, p.direction, width)} y1={0}
                  x2={getX(0, p.direction, width)} y2={0}
                  className="measurement"/>

            {/* Oberer Abstand zur Bohrung */}
            <line x1={getX(-OFFSET, p.direction, width)} y1={0}
                  x2={getX(-OFFSET, p.direction, width)} y2={p.height - mitte_falle}
                  markerStart="url(#arrowhead_start)" markerEnd="url(#arrowhead_end)" className="measurement"/>

            {/* Beschriftung: Oberer Abstand zur Bohrung */}
            <text x={getX(-OFFSET + OFF_TEXT_W, p.direction, width)}
                  y={(p.height - mitte_falle) - (mitte_falle_top / 2)}
                  style={WM_VERTICAL}>{mitte_falle_top}</text>

            {/* Mittlere Begrenzungslinie */}
            <line x1={getX(-OFFSET, p.direction, width)} y1={p.height - mitte_falle}
                  x2={getX(0, p.direction, width)}
                  y2={p.height - mitte_falle} className="measurement"/>

            {/* Mittlere, innere Begrenzungslinie */}
            <line strokeDasharray="4,4,4" x1={getX(0, p.direction, width)} y1={p.height - mitte_falle}
                  x2={getX(knobOffset, p.direction, width)} y2={p.height - mitte_falle} className="measurement"/>

            {/* Unterer Abstand zur Bohrung */}
            <line x1={getX(-OFFSET, p.direction, width)} y1={p.height - mitte_falle}
                  x2={getX(-OFFSET, p.direction, width)}
                  y2={p.height}
                  markerStart="url(#arrowhead_start)" markerEnd="url(#arrowhead_end)" className="measurement"/>

            {/* Unterste Begrenzungslinie */}
            <line x1={getX(-OFFSET, p.direction, width)} y1={p.height} x2={getX(0, p.direction, width)}
                  y2={p.height}
                  className="measurement"/>

            {/* Beschriftung: Unterer Abstand zur Bohrung */}
            <text x={getX(-OFFSET + OFF_TEXT_W, p.direction, width)} y={p.height - (mitte_falle / 2)}
                  style={WM_VERTICAL}>{mitte_falle}</text>
        </>
    );
}

function Band(props: {
    configuredProduct: ConfiguredProduct,
    bandNo: 1 | 2 | 3
}) {
    const p = props.configuredProduct.final;
    const mods = props.configuredProduct.modifications;

    const drillOffset = 33;

    const bbl1 = mods.bbl1 - p.norm.hinge_offset;
    const bbl2 = mods.bbl2;
    const bl3_exists = !isNil(mods.bbl3);
    const bbl3 = mods.bbl3 ?? 0;

    const top = props.bandNo === 1
        ? bbl1
        : props.bandNo === 2
            ? bbl1 + bbl2
            : bbl1 + bbl3;

    const doorWidth = p.width;
    const x1 = doorWidth - drillOffset;
    const y1 = top;

    const officeHinge = isOfficeDrilling(mods.hinge_drilling);
    const x2 = officeHinge ? x1 : x1 - drillOffset;
    const y2 = officeHinge ? y1 + drillOffset : y1;

    const offset_multiplier = bl3_exists ? 2 : 1;

    return (
        <>
            <circle cx={getX(x1, p.direction, p.width)} cy={y1} r={10} fill="lightyellow" stroke="black"
                    strokeWidth="3"/>
            <circle cx={getX(x2, p.direction, p.width)} cy={y2} r={10} fill="lightyellow" stroke="black"
                    strokeWidth="3"/>

            {/* Oberkante - BBL1*/}
            {props.bandNo === 1 && <>
                <line x1={getX(p.width + OFFSET, p.direction, p.width)} y1={0}
                      x2={getX(p.width + OFFSET, p.direction, p.width)} y2={bbl1}
                      markerStart="url(#arrowhead_start)" markerEnd="url(#arrowhead_end)" className="measurement"/>
                <line strokeDasharray="4,4,4"
                      x1={getX(p.width - drillOffset, p.direction, p.width)}
                      y1={bbl1} x2={getX(p.width, p.direction, p.width)} y2={bbl1}
                      className="measurement"/>
                <text x={getX(p.width + OFFSET + OFF_TEXT_E, p.direction, p.width)}
                      y={bbl1 / 2}
                      style={WM_VERTICAL}>{bbl1}</text>
            </>}


            {/* Höhe Linie BBL 1-2*/}
            {props.bandNo === 2 && <>
                <line x1={getX(p.width + OFFSET * offset_multiplier, p.direction, p.width)}
                      y1={bbl1}
                      x2={getX(p.width + OFFSET * offset_multiplier, p.direction, p.width)}
                      y2={bbl2 + bbl1} markerStart="url(#arrowhead_start)"
                      markerEnd="url(#arrowhead_end)" className="measurement"/>
                <line x1={getX(p.width, p.direction, p.width)} y1={bbl1}
                      x2={getX(p.width + OFFSET * offset_multiplier, p.direction, p.width)}
                      y2={bbl1}
                      className="measurement"/>
                <line x1={getX(p.width, p.direction, p.width)}
                      y1={bbl2 + bbl1}
                      x2={getX(p.width + OFFSET * offset_multiplier, p.direction, p.width)}
                      y2={bbl2 + bbl1} className="measurement"/>
                <line strokeDasharray="4,4,4"
                      x1={getX(p.width - drillOffset, p.direction, p.width)}
                      y1={bbl2 + bbl1}
                      x2={getX(p.width, p.direction, p.width)}
                      y2={bbl2 + bbl1} className="measurement"/>
                <text
                    x={getX(p.width + OFFSET * offset_multiplier + OFF_TEXT_E, p.direction, p.width)}
                    y={bbl1 + (bbl2 / 2)}
                    style={WM_VERTICAL}>{bbl2}</text>
            </>}


            {/* Höhe Linie BBL 1-3*/}
            {props.bandNo === 3 && <>
                <line x1={getX(p.width + OFFSET, p.direction, p.width)} y1={bbl1}
                      x2={getX(p.width + OFFSET, p.direction, p.width)}
                      y2={bbl3 + bbl1} markerStart="url(#arrowhead_start)"
                      markerEnd="url(#arrowhead_end)" className="measurement"/>
                <line x1={getX(p.width, p.direction, p.width)} y1={bbl1}
                      x2={getX(p.width + OFFSET, p.direction, p.width)} y2={bbl1}
                      className="measurement"/>
                <line x1={getX(p.width, p.direction, p.width)}
                      y1={bbl3 + bbl1}
                      x2={getX(p.width + OFFSET, p.direction, p.width)}
                      y2={bbl3 + bbl1} className="measurement"/>
                <line strokeDasharray="4,4,4"
                      x1={getX(p.width - drillOffset, p.direction, p.width)}
                      y1={bbl3 + bbl1}
                      x2={getX(p.width, p.direction, p.width)}
                      y2={bbl3 + bbl1} className="measurement"/>
                <text x={getX(p.width + OFFSET + OFF_TEXT_E, p.direction, p.width)}
                      y={bbl1 + (bbl3 / 2)}
                      style={WM_VERTICAL}>{bbl3}</text>
            </>}
        </>
    );
}

/**
 * Mirrors any given x-value depending on the set direction
 *
 * @param xWhenL x-value calculated relative to the left side of the svg (default)
 * @param direction
 * @param width width of the svg
 */
function getX(xWhenL: number, direction: Product['direction'], width: number): number {
    const inverter = isDirectionL(direction) ? -1 : 1;
    return Math.pow(((inverter - 1) / 2), 2) * width + inverter * xWhenL;
}
