import React, { useState } from 'react';
import { Card } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import { AuthenticationFacade } from './facades/AuthenticationFacade';
import { isNil } from '@w11k/rx-ninja';


export interface AuthenticationProps {
    authFacade: AuthenticationFacade;
}

export function LogIn(props: AuthenticationProps) {

    const [pin, setPin] = useState<string>('');
    const history = useHistory();
    const location = useLocation();
    const params = location.search.split('redirecturl=')[1];

    return (
        <form onSubmit={() => validatePinAndRedirect(pin, history, params, props)}>
            <Card className="pin-login">
                <h4>Login</h4>
                <div className="pin-form">
                    <span>Pin:</span>
                    <input className="pin-input form-control" onChange={(p) => setPin(p.target.value)}/>
                </div>
                <button type="submit" className="btn btn-primary" data-dismiss="modal">
                    Login
                </button>
            </Card>
        </form>
    );
}

// default pin if REACT_APP_AUTH_PIN is not set
const DEFAULT_PIN = '1234';

async function validatePinAndRedirect(pin: string, history: History, pathname: string, props: AuthenticationProps) {
  if (pin === process.env.REACT_APP_AUTH_PIN || (isNil(process.env.REACT_APP_AUTH_PIN) && pin === DEFAULT_PIN)) {
    await props.authFacade.setUserLoggedIn(true);
    history.push(pathname ?? '/');
  }
}
