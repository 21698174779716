export interface UserInformationFromBackend {
    type: UserTypes,
    customer: {
        customerNo: string,
        name: string
    }
}

export enum UserTypes {
    INTERNAL='internal',
    PARTNER='partner'
}
