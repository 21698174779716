import React from 'react';
import { HashRouter, Redirect, Route, Switch, useParams } from 'react-router-dom';
import './App.scss';
import { AppFacades } from './facades/app-facades';
import { InternalGuard, PrivateRoute } from './PrivateRoute';
import { LogIn } from './LogIn';
import Products from './products/Products';
import Order from './orderV2/Order';
import Product from './product/Product';
import { useFacadeState } from './utils';
import { UserTypes } from './user/user-types';


function Router(props: { facades: AppFacades, userLoggedIn: boolean }) {
    const userType = useFacadeState(props.facades.authFacade, s => s.userType);

    return (
        <div className="d-block h-100">
            <HashRouter>
                <Switch>
                    {/* PRIVATE ROUTES */}

                    <PrivateRoute
                        path={'/products/cart'}
                        exact={false}
                        isAuthenticated={props.userLoggedIn}>
                        <InternalGuard authFacade={props.facades.authFacade}>
                            <Products userLoggedIn={props.userLoggedIn} facades={props.facades}/>
                        </InternalGuard>
                    </PrivateRoute>

                    <PrivateRoute
                        path={'/products/configure'}
                        exact={false}
                        isAuthenticated={props.userLoggedIn}>
                        <InternalGuard authFacade={props.facades.authFacade}>
                            <Products userLoggedIn={props.userLoggedIn} facades={props.facades}/>
                        </InternalGuard>
                    </PrivateRoute>

                    <PrivateRoute
                        path={'/product'}
                        exact={false}
                        isAuthenticated={props.userLoggedIn}>
                        {userType === UserTypes.INTERNAL ?
                            <Products userLoggedIn={props.userLoggedIn} facades={props.facades}/> :
                            <Product userLoggedIn={props.userLoggedIn} facades={props.facades}/>
                        }
                    </PrivateRoute>

                    <Redirect from={'/products'} to={'/'}/>

                    {/* PUBLIC ROUTES */}

                    <Route path={'/order/:orderId'} exact={false}>
                        <React.StrictMode>
                            <Order facades={props.facades}/>
                        </React.StrictMode>
                    </Route>

                    <Redirect from={'/order'} to={'/'}/>

                    <Route path="/login" exact>
                        <React.StrictMode>
                            <LogIn authFacade={props.facades.authFacade}/>
                        </React.StrictMode>
                    </Route>

                    <Route
                        path="/:orderId" // legacy route
                        exact>
                        <OrderWithIDRedirect defaultRoute={'/'}/>
                    </Route>

                    {/* DEFAULT ROUTE */}

                    <PrivateRoute
                        path={'/'}
                        exact
                        isAuthenticated={props.userLoggedIn}>
                        <InternalGuard authFacade={props.facades.authFacade}>
                            <Redirect to={{pathname: '/products/cart'}}/>
                        </InternalGuard>
                    </PrivateRoute>

                </Switch>
            </HashRouter>
        </div>
    );
}

export default Router;

function OrderWithIDRedirect({defaultRoute}: { defaultRoute: string }): JSX.Element {
    const {orderId} = useParams() as { orderId: string } | Record<string, never>;
    return (<Redirect to={{
        pathname: orderId ? `/order/${orderId}` : defaultRoute,
    }}/>);
}
