import React from 'react';
import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { isNil } from '@w11k/rx-ninja';
import { useFacadeState } from '../utils';
import { PrivateRoute } from '../PrivateRoute';
import { AppFacades } from '../facades/app-facades';
import BaseLayout, { DisabledProductsWrapper, MainLayout, SketchLayoutWrapper } from '../layout/Layout';
import BasketSelect from './basket/BasketSelect';
import BasketEdit from './basket/basketEdit/BasketEdit';
import ProductsCustomize from './configure/productsCustomize/ProductsCustomize';
import { LoadingSpinner } from '../product/ProductPage';
import { UserTypes } from '../user/user-types';

export default function Products({userLoggedIn, facades}: { userLoggedIn: boolean; facades: AppFacades}): JSX.Element {
    const availableProducts = useFacadeState(facades.databaseFacade, s => s.availableProducts);
    const userType = useFacadeState(facades.authFacade, s => s.userType);

    if (isNil(availableProducts) || availableProducts.length === 0) {
        return <LoadingSpinner/>;
    }

    return (
        <Switch>
            <PrivateRoute
                path={'/products/cart'}
                exact
                isAuthenticated={userLoggedIn}>
                <ShoppingCart facades={facades} userType={userType} />
            </PrivateRoute>

            <PrivateRoute
                path={'/product/:sku'}
                exact={true}
                isAuthenticated={userLoggedIn}>
                <ShoppingCart facades={facades} userType={userType} />
            </PrivateRoute>

            <PrivateRoute
                path={'/products/configure/:pos'}
                exact={true}
                isAuthenticated={userLoggedIn}>
                <Configurator userLoggedIn={userLoggedIn} facades={facades} userType={userType}/>
            </PrivateRoute>

            <Redirect from={'/products/configure'} to={'/'}/>
        </Switch>
    );
}

export function Configurator({userLoggedIn, facades, userType}: { userLoggedIn: boolean; facades: AppFacades; userType: UserTypes | undefined }): JSX.Element {
    const {path} = useRouteMatch();
    return (
        <PrivateRoute
            path={path}
            exact
            isAuthenticated={userLoggedIn}>
            <ProductsCustomizeWrapper facades={facades}/>
        </PrivateRoute>
    );
}

function ProductsCustomizeWrapper({facades}: { facades: AppFacades}): JSX.Element {
    const {path} = useRouteMatch();
    const {pos: activePositionParam} = useParams() as { pos: string };
    const {sku: selectedSkuParam} = useParams() as { sku: string };
    const shoppingCartSelection = useFacadeState(facades.shoppingCartFacade, s => s.selection, []);
    const productList = useFacadeState(facades.databaseFacade, s => s.availableProductsBySku);
    const isSkuView = (): boolean => path.includes('sku');

    let activePositionIndex = 0;
    let selectedSku;

    if(selectedSkuParam !== undefined){
        selectedSku = productList[selectedSkuParam].sku;
    } else {
         activePositionIndex = parseInt(activePositionParam, 10);
         selectedSku = shoppingCartSelection[activePositionIndex]?.selectedProduct?.sku;
    }


    facades.shoppingCartFacade.setShoppingCartViewToActive(false);

    if (isNil(activePositionIndex) || isNaN(activePositionIndex) || isNil(selectedSku)) {
        return (<DisabledProductsWrapper facades={facades}/>);
    }

    facades.shoppingCartFacade.setSkuAndUpdateMatchingProducts(selectedSku, activePositionIndex);

    return (
        <BaseLayout
            facades={facades}
            left={
                <ProductsCustomize
                    productCustomizeFacade={facades.productCustomizeFacade}
                    disabledMode={false}
                />
            }
            main={<SketchLayoutWrapper facades={facades}/>}
            leftSideBarVisible={true}
            sideBarNavVisible={!isSkuView()}
        />
    );
}

function ShoppingCart({facades, userType}: { facades: AppFacades, userType: UserTypes | undefined}): JSX.Element {
    facades.shoppingCartFacade.setShoppingCartViewToActive(true);

    return (
        <BaseLayout
            facades={facades}
            left={ <BasketEdit facades={facades}/>}
            main={<TableLayoutWrapper facades={facades}/>}
            leftSideBarVisible={userType === UserTypes.INTERNAL}
            sideBarNavVisible={userType === UserTypes.INTERNAL}
        />
    );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const TableLayoutWrapper = ({facades}: { facades: AppFacades }): JSX.Element => (
    <MainLayout>
        <BasketSelect facades={facades}/>
    </MainLayout>
);
