import { getHeadersByEnv } from '../utils';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { testIdCustomerNumber } from './ProductInfoText';
import { useAsyncAbortable } from 'react-async-hook';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export type Customer = { customerNo: string; name: string }

export async function searchCustomer(customerNumber: string, abortSignal?: AbortSignal): Promise<Customer> {
    const result = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/configurator-api/v2/customer/get/${customerNumber}`,
        {
            credentials: 'include',
            signal: abortSignal,
            headers: getHeadersByEnv() as { 'Content-Type': string, 'Authorization': string },
        });
    const searchResult = await result.json() as { customer: Customer };
    return searchResult?.customer;
}

export function useCustomerSearch() {
    const [searchText, setSearchText] = useState('');

    const debouncedSearch = useConstant(() =>
        AwesomeDebouncePromise(searchCustomer, 300),
    );

    const search = useAsyncAbortable(async (abort, text) => {
        if (text.trim().length < 3) {
            return;
        } else {
            const searchResult = await debouncedSearch(text, abort);
            if (searchResult == null) {
                throw new Error('Keine Übereinstimmung gefunden.');
            }
            return searchResult;
        }
    }, [searchText]);

    return {
        setSearchText,
        search,
    };
}

export function SearchCustomer({setIsValidCustomer}: {
    setIsValidCustomer: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const {search, setSearchText} = useCustomerSearch();
    const {values} = useFormikContext<{ customerNumber: string; }>();

    useEffect(() => {
        setIsValidCustomer(values?.customerNumber !== '' && search?.status === 'success');
    }, [setIsValidCustomer, search?.status, values?.customerNumber]);

    useEffect(() => {
        setSearchText(values.customerNumber);
    }, [setSearchText, values.customerNumber]);

    return (
        <Row>
            <Col>
                <div className="form-group">
                    <label>Kundennummer</label>
                    <Field type="text" name="customerNumber"
                           className="form-control"
                           {...testIdCustomerNumber.attr}/>
                    <ErrorMessage name="customerNumber" component="div"
                                  className="form-text text-danger"/>
                </div>
            </Col>
            <Col>
                <div className="form-group">
                    <label>Name</label>
                    <Field type="text" name="customerName" disabled
                           value={search.result?.name ?? ''}
                           className="form-control"/>
                    {search.error?.message &&
                    <div className="form-text text-danger">
                        {search.error?.message}
                    </div>}
                </div>
            </Col>
        </Row>
    );
}
