import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { AppFacades } from '../../../facades/app-facades';
import { Product } from '../../../domain';
import { useFacadeState } from '../../../utils';
import { ShoppingCartSelection } from '../../../facades/ShoppingCartFacade';
import ButtonShoppingCartRemove from '../../../layout/buttons/ButtonShoppingCartRemove';
import { EmptySidebarLayout } from '../../../layout/Layout';
import { getDescription40char2 } from '../../../facades/OrderFacade';
import './basketEdit.scss';
import { Col, Row } from 'react-bootstrap';
import { UserTypes } from '../../../user/user-types';
import { TriggerOrderModal } from '../orderModal/BasketOrderModal';

export type ReadableBasketContent = { [sku: string]: string[]; }[];

export const getReadableBasketContent = (selection: ShoppingCartSelection[]): ReadableBasketContent =>
    selection.filter(s => !!s?.selectedProduct).map(s => {
        const {sku} = s.selectedProduct as Product;
        const {
            description_40_char_1,
            description_40_char_2,
            description_40_char_3,
        } = s?.configuredProduct?.final ?? s.selectedProduct as Product;
        const description_40_char_2_conf = s?.configuredProduct
            ? getDescription40char2(s?.configuredProduct)
            : undefined;
        return {
            [sku]: [
                description_40_char_1,
                description_40_char_2_conf ?? description_40_char_2,
                description_40_char_3,
            ],
        };
    });

export const convertToGermanCurrency = (priceRaw: string): string =>
    `${priceRaw.replace('.', ',')} €`;

export default function BasketEdit({facades}: { facades: AppFacades }): JSX.Element {
    const history = useHistory();
    const basketSelection = useFacadeState(facades.shoppingCartFacade, s => s.selection, []);
    const priceTotal = useFacadeState(facades.shoppingCartFacade, s => s.priceTotal, '0.00');
    const basket = getReadableBasketContent(basketSelection);
    const activePosition = useFacadeState(facades.shoppingCartFacade, s => s.activePosition);
    const userType = useFacadeState(facades.authFacade, s => s.userType);

    const handleRemoveItem: (s: number) => () => void =
        (selectedPosition: number) => () => {
            facades.shoppingCartFacade.removeProductFromCartByIndex(selectedPosition);
            facades.productSelectionFacade.resetState();
        };
    const handleSelectedItem: (a: number | undefined) => (s: number) => CSSProperties =
        (activeItemPosition: number | undefined) => (selectedItemPosition: number) =>
            activeItemPosition === selectedItemPosition ? {backgroundColor: '#605E5E'} : {};
    const handleReset: () => void = facades.shoppingCartFacade.resetShoppingCart;

    if (basket?.length > 0) {
        return (
            <div className="container-fluid">
                <BasketItems
                    basketSelection={basketSelection}
                    basket={basket}
                    priceTotal={priceTotal}
                    handleRemoveItem={handleRemoveItem}
                    handleSelectedItem={handleSelectedItem(activePosition)}
                    handleReset={handleReset}
                    history={history}
                    userType={userType}
                />
            </div>
        );
    }
    return (
        <EmptySidebarLayout
            style={{
                color: 'white',
                fontWeight: 'bold',
                marginBottom: '4rem',
            }}
            note={'Kein Produkt ausgewählt.'}
        />
    );
}

function BasketItems(
    {
        basketSelection,
        basket,
        priceTotal,
        handleRemoveItem,
        handleSelectedItem,
        handleReset,
        history,
        userType,
    }: {
        basketSelection: ShoppingCartSelection[];
        basket: ReadableBasketContent;
        priceTotal: string;
        handleRemoveItem: (i: number) => () => void;
        handleSelectedItem: (s: number) => CSSProperties;
        handleReset: () => void;
        history: H.History;
        userType: UserTypes | undefined;
    }): JSX.Element {

    const handleNavigation: (s: string, i: number) => () => void =
        (sku: string, i: number) => () => {
            history.push({pathname: `configure/${i}`});
        };

    return (<>
        {basket.map((item, i) => Object.keys(item).map(sku => (
            <div key={`${sku}-${i}`} className="row selectable" style={handleSelectedItem(i)}>
                <div className="col-2 p-0">
                    <ButtonShoppingCartRemove onClick={handleRemoveItem(i)}/>
                </div>
                <div
                    className="col py-2"
                    onClick={handleNavigation(sku, i)}>
                    <ItemInfoDisplay sku={sku} basketItem={item} parentIndex={i}/>
                </div>
            </div>
        )))
        }
        <MandatoryDisplayWrapper
            priceTotal={priceTotal}
            basketSelection={basketSelection}
            handleReset={handleReset}
            userType={userType}
        />
    </>);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ItemInfoDisplay = ({sku, basketItem, parentIndex}: {
    sku: string; basketItem: { [sku: string]: string[]; }; parentIndex: number;
}): JSX.Element => (<>
    {Object.values(basketItem[sku]).map((v, i) => (
        <div key={`${sku}-${parentIndex}-${i}`} className="row">
            {v}
        </div>
    ))}
</>);

// eslint-disable-next-line @typescript-eslint/naming-convention
const MandatoryDisplayWrapper = ({priceTotal, basketSelection, handleReset, userType}: {
    priceTotal: string;
    basketSelection: ShoppingCartSelection[];
    handleReset: () => void;
    userType: UserTypes | undefined;
}): JSX.Element => (<>
    <PriceTotalDisplay priceTotal={priceTotal}/>
    <PlaceAnOrderButton
        priceTotal={priceTotal}
        basketSelection={basketSelection}
        handleReset={handleReset}
        userType={userType}
    />
</>);

function PriceTotalDisplay({priceTotal}: { priceTotal: string }): JSX.Element {
    return (<>
        <div className="row pt-2">
            <div className="col-2 p-0"/>
            <div className="col">
                <div className="row" style={{color: '#c7c7c7'}}>
                    Gesamtbetrag
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-2 p-0"/>
            <div className="col">
                <div className="row" style={{color: 'white'}}>
                    {convertToGermanCurrency(priceTotal)}
                </div>
            </div>
        </div>
    </>);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlaceAnOrderButton = ({priceTotal, basketSelection, handleReset, userType}: {
    priceTotal: string;
    basketSelection: ShoppingCartSelection[];
    handleReset: () => void;
    userType?:  UserTypes;
}): JSX.Element => (
    <Row className="py-4">
        <Col className="d-flex justify-content-center align-items-center">
            <TriggerOrderModal
                priceTotal={priceTotal}
                basketSelection={basketSelection}
                handleReset={handleReset}
                userType = {userType}
            />
        </Col>
    </Row>
);
