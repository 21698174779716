import React, { useEffect, useState } from 'react';
import './App.scss';
import { createFacades } from './facades/app-facades';
import { initializeAnalytics } from './analytics/analytics-service';
import Router from './Router';
import { LoadingSpinner } from './product/ProductPage';

const facades = createFacades();

// App root
function App() {

    useEffect(() => {
        facades.databaseFacade.loadTables();
    }, []);

    const [userLoggedIn, setUserLoggedIn] = useState<boolean | undefined>(undefined);

    facades.authFacade.loadUserInformation().then(user => {
        setUserLoggedIn(user);
    });

    initializeAnalytics();

    return ( userLoggedIn !== undefined  ? <Router facades={facades} userLoggedIn={userLoggedIn}></Router> : <LoadingSpinner/>);
}
export default App;
