import { Facade, groupByUniqueId } from '@w11k/tydux';
import { DatabaseCommands, DatabaseFacade, DatabaseState } from './DatabaseFacade';
import { Design, Norm, Product } from '../domain';
import uniqBy from 'lodash/uniqBy';
import { unravelFacade } from '../tests/test-utils';


export class DatabaseFacadeMock extends Facade<DatabaseCommands> implements DatabaseFacade {

    setFacadeState!: (partialFacadeState: Partial<DatabaseState>) => void;

    constructor() {
        super('database', new DatabaseCommands(), new DatabaseState());
        const [, , setState] = unravelFacade(this as DatabaseFacade);
        this.setFacadeState = setState;
    }

    async loadTables(force?: boolean): Promise<void> {
        // im Mock nicht nötig
    }

    setStateFromProductList(products: Product[]) {
        const normList: Norm[] = uniqBy(products.flatMap(p => p.norms), n => n.code);
        const designs: Design[] = uniqBy(products.flatMap(p => p.designs), d => d.code);

        this.setFacadeState({
            availableProducts: products,
            availableProductsBySku: groupByUniqueId(products, p => p.sku),
            normList,
            normById: groupByUniqueId(normList, n => n.code),
            designsById: groupByUniqueId(designs, d => d.code),
        });
    }

}
