import { Commands, createTyduxStore, Facade, MountPoint, removeGlobalStore, setGlobalStore } from '@w11k/tydux';
import { CommandsState } from '@w11k/tydux/lib/commands';
import { ProductSelectionFacade } from '../facades/ProductSelectionFacade';
import { OrderFacade } from '../facades/OrderFacade';
import { ProductCustomizeFacade } from '../facades/ProductCustomizeFacade';
import { AuthenticationFacade } from '../facades/AuthenticationFacade';
import { DatabaseFacadeMock } from '../facades/DatabaseFacadeMock';
import { OrderGetFromBackend, OrderPostToBackend } from '../domain';
import { ShoppingCartFacade } from '../facades/ShoppingCartFacade';


export function createTestFacades() {
    // Facades
    const databaseFacade = new DatabaseFacadeMock();
    const productSelectionFacade = new ProductSelectionFacade(databaseFacade);
    const productCustomizeFacade = new ProductCustomizeFacade(databaseFacade, productSelectionFacade/*, shoppingCartFacade*/);
    const shoppingCartFacade = new ShoppingCartFacade(databaseFacade, productSelectionFacade, productCustomizeFacade);
    const orderFacade = new OrderFacade(databaseFacade, productSelectionFacade, shoppingCartFacade);
    const authFacade = new AuthenticationFacade();

    return {
        databaseFacade,
        productSelectionFacade,
        productCustomizeFacade,
        orderFacade,
        authFacade,
        shoppingCartFacade,
    };
}


export function testId(id: string) {
    return {
        attr: {
            'data-testid': id,
        },
        val: id,
    };
}

export function resetGlobalTyduxStore() {
    removeGlobalStore();
    const store = createTyduxStore({}, {});
    setGlobalStore(store);
}

export type FacadeCommands<F> = F extends Facade<infer C> ? C : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function unravelFacade<F extends Facade<Commands<S>, S>, S = any>(facade: F)
    : [F, FacadeCommands<F>, (partialFacadeState: Partial<CommandsState<FacadeCommands<F>>>) => void] {

    return [
        facade,
        (facade as unknown as { commands: FacadeCommands<F> }).commands,
        (facadeState: Partial<CommandsState<FacadeCommands<F>>>) => {
            ((facade as unknown as { mountPoint: MountPoint<unknown> }).mountPoint).dispatch({
                type: facade.createActionName('@@SET_STATE'),
                initialState: {...facade.state, ...facadeState},
            });
        },
    ];

}

export function getOrderWithNormCode(order: OrderPostToBackend, norm_code: string): OrderGetFromBackend {
    return {
        ...order,
        modifications: {
            Norm: '',
            Richtung: 'L',
            Schlossbohrung: '',
            Bandbohrung: '',
            Bandversatz: 0,
            Bandbezugslinie_1: 0,
            Bandbezugslinie_2: 0,
            Bandbezugslinie_3: 0,
            Untere_Glaskante_Mitte_Falle: 0,
            Obere_Glaskante_Mitte_Falle: 0,
            ...order.modifications,
            norm_code,
        },
    };
}
