import { isDirectionL } from '../domain';
import { isNotNil } from '@w11k/rx-ninja';

/**
 * Decor is always matched to direction 'R'. Default direction of door is 'L'.
 * Therefore,
 *      door 'L' -> mirrored decor
 *      door 'R' -> default decor
 *
 * Unless it is forbidden to mirror the decor: `mirrorHorizontal === '0'`
 * Then only the initial door direction will be taken into account.
 *
 * @param initialDoorDirection
 * @param designPosX
 * @param doorDirection
 * @param mirrorHorizontal
 * @param vbWidth
 * @return function to be applied
 */
export function mirrorDecorX(initialDoorDirection: string | undefined,
                             designPosX: string,
                             doorDirection: string,
                             mirrorHorizontal: string,
                             vbWidth: number): () => string {
    return () => {
        const translate =
            // mirror svg and set it to default position
            `scale(-1 1) translate(${-1 * vbWidth} 0) `;

        if (mirrorHorizontal === '1') {
            if (designPosX === 'center') {

                // door direction is 'L' -> use mirrored decor
                if (isDirectionL(doorDirection)) {
                    return translate;
                }
                // door direction is 'R' -> use default decor direction

            }
        }

        if (mirrorHorizontal === '0') {
            if (isNotNil(initialDoorDirection) && designPosX === 'center') {

                // initial door directions equal 'L' -> use mirrored decor
                if (isDirectionL(initialDoorDirection)) {
                    return translate;
                }
                // initial door direction is 'R' -> use default decor direction
                // all manual changes of door direction are ignored

            }
        }

        return '';
    };
}

/* eslint-disable */
// export {};

/*
import {
    DekorSvg,
    DekorSvgPositionX,
    DekorSvgPositionY,
    FinalConfiguredProduct,
} from '../facades/ProductCustomizeFacade';
import DOMPurify from 'dompurify';

// TODO: mock window object for tests
// const {JSDOM} = require('jsdom');

export function calculateSvgPosition(svg: DekorSvg, product: FinalConfiguredProduct) {
    let positionX = 0;
    let positionY = 0;

    // POS X
    switch (product?.design?.posX) {
        case DekorSvgPositionX.CENTER:
            positionX = (svg.svgViewBoxX - product.width) / 2;
            break;
        case DekorSvgPositionX.LOCK:
            if (product.direction === 'r') {
                positionX = 0;
            } else {
                positionX = svg.svgViewBoxX - product.width;
            }
            break;
        case DekorSvgPositionX.HINGE:
            if (product.direction === 'r') {
                positionX = svg.svgViewBoxX - product.width;
            } else {
                positionX = 0;
            }
            break;
    }

    // POS Y
    switch (product?.design?.posY) {
        case DekorSvgPositionY.CENTER:
            positionY = (svg.svgViewBoxY - product.height) / 2;
            break;
        case DekorSvgPositionY.TOP:
            positionY = 0;
            break;
        case DekorSvgPositionY.BOTTOM:
            positionY = svg.svgViewBoxY - product.height;
            break;
    }

    return ({positionX, positionY});
}

export async function calculateSvg(product: FinalConfiguredProduct, setSvg: (dekorSvg: DekorSvg) => void) {
    const parser = new DOMParser();
    const domPurify = DOMPurify(window);
    try {
        const data = await fetch(product.design!.svgFile);
        const text = await data.text();
        const parsedSvg = parser.parseFromString(text, 'image/svg+xml');

        const svgViewBoxX = parsedSvg?.documentElement.getAttribute(`viewBox`)?.split(' ')[2] != null ? parseInt(parsedSvg!.documentElement.getAttribute(`viewBox`)!.split(' ')[2], 10) : 0;
        const svgViewBoxY = parsedSvg?.documentElement.getAttribute(`viewBox`)?.split(' ')[3] != null ? parseInt(parsedSvg!.documentElement.getAttribute(`viewBox`)!.split(' ')[3], 10) : 0;
        const dekorSvg: DekorSvg = {
            svg: '',
            svgViewBoxX: product!.design!.widthMaxDecor > product.width ? product!.design!.widthMaxDecor : product.width,
            svgViewBoxY: product!.design!.heightMaxDecor > product.height ? product!.design!.heightMaxDecor : product.height,
            positionX: 0,
            positionY: 0,
            productHeight: product.height,
            productWidth: product.width,
        };
        const positions = calculateSvgPosition(dekorSvg, product);
        parsedSvg.documentElement.setAttribute('viewBox', `0 0 ${svgViewBoxX} ${svgViewBoxY}`);
        parsedSvg.documentElement.setAttribute('width', product!.design!.widthMaxDecor ? product!.design!.widthMaxDecor.toString() : product.width.toString());
        parsedSvg.documentElement.setAttribute('height', product!.design!.heightMaxDecor ? product!.design!.heightMaxDecor.toString() : product.height.toString());
        // for security
        const cleanSvg = domPurify.sanitize(parsedSvg.documentElement.outerHTML, {
            USE_PROFILES: {
                svg: true,
                svgFilters: true,
            },
        });
        setSvg({...dekorSvg, svg: cleanSvg, positionX: positions.positionX, positionY: positions.positionY});
    } catch (e) {
        console.log(e);
    }
}

export function exportDekorSvg(svg: DekorSvg | null, setSvg: Function) {
    let svgData: string;
    setSvg({...svg, exportSvg: true});

    // TODO: fix that async?
    setTimeout(() => {
        svgData = document!.getElementById('doorDekor')!.outerHTML;
        setSvg({...svg, loading: true});
    });

    // show loading spinner at least 0,5 second
    setTimeout(() => {
        const svgBlob = new Blob([svgData], {type: 'image/svg+xml;charset=utf-8'});
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = svgUrl;
        downloadLink.download = 'test-dekor.svg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setSvg({...svg, exportSvg: false});
    }, 500);
}
*/
