export type AnalyticsEvent<T extends AnalyticsEventParams> = {
    name: string,
    params: T
}

export type AnalyticsEventParams = {
    value?: number,
    currency?: string,
    category?: string,
    label: string,
    items?: OrderItem[]
}

export type OrderItem = {
    item_id?: string,
    item_name?: string,
    currency?: string,
    item_brand?: string,
    item_category?: string
    price?: number,
    quantity?: number
}

export enum ANALYTICS_EVENTS {
    // The order was successfully sent to the server (post-order-request)
    ORDER_POST_SUCCESS = 'purchase',
    // The post-order-request to the server returns an error  (post-order-request)
    ORDER_POST_ERROR = 'error_purchase_submit',
    // The order was successfully retrieved from the server (get-order-request)
    ORDER_GET_SUCCESS = 'view_item',
    // The get-order-request to the server returns an error
    ORDER_GET_ERROR = 'error_qr_code_scan'
}
