import React, { CSSProperties, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppFacades } from '../facades/app-facades';
import { useFacadeState } from '../utils';
import { getReadableBasketContent, ReadableBasketContent } from '../products/basket/basketEdit/BasketEdit';
import { LoadingSpinner } from '../product/ProductPage';
import { DisabledOrderWrapper, EmptySidebarLayout, OrderLayout, SketchLayoutWrapper } from '../layout/Layout';
import { isNotNil } from '@w11k/rx-ninja';
import * as H from 'history';
import './order.scss';

export default function Order({facades}: { facades: AppFacades }): JSX.Element {
    const history = useHistory();
    const params = useParams() as { orderId: string };

    useEffect(() => {
        facades.orderFacade.loadOrderById(params.orderId);
    }, [
        facades.orderFacade,
        params.orderId,
    ]);

    return (<OrderLayoutWrapper facades={facades} history={history}/>);
}

function OrderLayoutWrapper({facades, history}: { facades: AppFacades; history: H.History }): JSX.Element {
    const statusIndicator = useFacadeState(facades.orderFacade, s => s.statusIndicator);
    const basketSelection = useFacadeState(facades.shoppingCartFacade, s => s.selection, []);
    const basket = getReadableBasketContent(basketSelection);
    const activePosition = useFacadeState(facades.shoppingCartFacade, s => s.activePosition);

    facades.shoppingCartFacade.setShoppingCartViewToActive(false);

    const handleSelectedItem: (a: number | undefined) => (s: number) => CSSProperties =
        (activeItemPosition: number | undefined) => (selectedItemPosition: number) =>
            activeItemPosition === selectedItemPosition ? {backgroundColor: '#605E5E'} : {};
    const handleOnClick = (clickedItemSku: string | undefined, selectedPosition: number | undefined): void => {
        isNotNil(clickedItemSku) && isNotNil(selectedPosition)
        && facades.shoppingCartFacade.setSkuAndUpdateMatchingProducts(clickedItemSku, selectedPosition);
    };

    if (statusIndicator === 'error') {
        history.push({pathname: '/'});
        return (<></>);
    } else if (statusIndicator === 'notFound') {
        return (<DisabledOrderWrapper/>);
    } else if (statusIndicator === 'loading') {
        return (<LoadingSpinner/>);
    } else if (statusIndicator === undefined) {
        return (<></>);
    }

    if (basket?.length > 0) {
        return (
            <OrderLayout
                left={
                    <OrderItems
                        basket={basket}
                        handleSelectedItem={handleSelectedItem(activePosition)}
                        handleOnClick={handleOnClick}
                    />
                }
                main={<SketchLayoutWrapper facades={facades}/>}
            />
        );
    }
    return (
        <EmptySidebarLayout
            style={{
                color: 'white',
                fontWeight: 'bold',
                marginBottom: '4rem',
            }}
            note={'Kein Produkt ausgewählt.'}
        />
    );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const OrderItems = ({basket, handleSelectedItem, handleOnClick}: {
    basket: ReadableBasketContent;
    handleSelectedItem: (p: number) => CSSProperties;
    handleOnClick: (sku: string, p: number) => void;
}): JSX.Element => (<>
    <div className="container-fluid">
        <div className="row pl-2">
            <div className="col headline py-4">
                Bestellung
            </div>
        </div>
        {basket.map((item, i) => Object.keys(item).map(sku => (
            <div key={`${sku}-${i}`} className="row selectable pl-2" style={handleSelectedItem(i)}>
                <div
                    className="col py-2"
                    onClick={() => handleOnClick(sku, i)}>
                    <ItemInfoDisplay sku={sku} basketItem={item} parentIndex={i}/>
                </div>
            </div>
        )))
        }
    </div>
</>);

// eslint-disable-next-line @typescript-eslint/naming-convention
const ItemInfoDisplay = ({sku, basketItem, parentIndex}: {
    sku: string; basketItem: { [sku: string]: string[]; }; parentIndex: number;
}): JSX.Element => (<>
    {Object.values(basketItem[sku]).map((v, i) => (
        <div key={`${sku}-${parentIndex}-${i}`} className="row">
            <div className="col">{v}</div>
        </div>
    ))}
</>);
