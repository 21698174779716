import { isNil } from '@w11k/rx-ninja';

export function downloadDekor(svgElement: SVGSVGElement | null) {
    if (isNil(svgElement)) {
        console.error('SVG Element nicht auf der Seite');
        return;
    }

    const dekorSvg = svgElement.querySelector('svg');
    if (isNil(dekorSvg)) {
        console.error('Kein Dekor vorhanden');
        return;
    }

    const svgSrc = new XMLSerializer().serializeToString(dekorSvg);
    const svgBlob = new Blob([svgSrc], {type: 'image/svg+xml;charset=utf-8'});
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = svgUrl;
    downloadLink.download = 'dekor.svg';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
