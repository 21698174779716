import React, { useEffect } from 'react';
import { useFacadeState } from '../../utils';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import { AppFacades } from '../../facades/app-facades';
import { useHistory, useParams } from 'react-router-dom';
import { UserTypes } from '../../user/user-types';

export default function BasketSelect(props: { facades: AppFacades }): JSX.Element {
    const history = useHistory();
    const addToCart = props.facades.shoppingCartFacade.addProductToCartBySKU;
    const {sku: selectedSkuParam} = useParams() as { sku: string };
    const availableProducts = useFacadeState(props.facades.databaseFacade, s => s.availableProducts);
    const userType = useFacadeState(props.facades.authFacade, s => s.userType);
    const products = availableProducts.map(p => {
        return {
            ...p,
        };
    });

    useEffect(() => {
        if (selectedSkuParam) {
            addToCart(selectedSkuParam);
        }
    }, [selectedSkuParam]);

    const theme = createMuiTheme({
        overrides: {
            MuiTableSortLabel: {
                root: {
                    color: '#ddd',
                    '&:hover': {
                        color: '#fff',
                    },
                    '&:active': {
                        // currently not overwriting, manually to be set in App.scss
                        color: '#fff',
                    },
                },
            },
        },
    });

    return (
        <div className="product-table-container">
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    columns={[
                        {title: 'SKU', field: 'sku'},
                        {title: 'nav_no', field: 'nav_no'},
                        {title: 'brand', field: 'brand'},
                        {title: 'product_name', field: 'product_name'},
                        {title: 'text 1', field: 'description_40_char_1'},
                        {title: 'text 2', field: 'description_40_char_2', cellStyle: {minWidth: '100px'}},
                        {title: 'text 3', field: 'description_40_char_3'},
                        {title: 'direction', field: 'direction'},
                        {title: 'drilling_template', field: 'drilling_template'},
                        {title: 'family', field: 'family'},
                        {title: 'glass_opacity', field: 'glass_opacity'},
                        {title: 'glass_safety', field: 'glass_safety'},
                        {title: 'height', field: 'height'},
                        {title: 'length', field: 'length'},
                        {title: 'valid_country_select', field: 'valid_country_select'},
                        {title: 'width', field: 'width'},
                        {title: 'width_max', field: 'width_max'},
                        {title: 'price', field: 'nav_recommended_retail_price_net'},
                    ]}
                    data={products}
                    title="Produkte"
                    options={{
                        filtering: true,
                        pageSize: 20,
                        headerStyle: {
                            backgroundColor: '#444444',
                            color: '#FFF',
                        },
                    }}
                    actions={[
                        userType === UserTypes.INTERNAL ?
                        {
                            icon: 'add_shopping_cart',
                            tooltip: 'Produkt hinzufügen',
                            onClick: (event, rowData) => {
                                const sku = Array.isArray(rowData) ? rowData[0].sku : rowData.sku;
                                addToCart(sku);
                            },
                        } :
                        {
                            icon: 'edit',
                            tooltip: 'Produkt auswählen',
                            onClick: (event, rowData) => {
                                const sku = Array.isArray(rowData) ? rowData[0].sku : rowData.sku;
                                history.push({
                                    pathname: `/product/${sku}`,
                                });
                            },
                        }
                    ]}
                />
            </MuiThemeProvider>
        </div>
    );
}

