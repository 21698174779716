import { DatabaseFacade, GoogleSheetDatabaseFacade } from './DatabaseFacade';
import { ProductSelectionFacade } from './ProductSelectionFacade';
import { ProductCustomizeFacade } from './ProductCustomizeFacade';
import { createTyduxStore, enableTyduxDevelopmentMode, removeGlobalStore, setGlobalStore } from '@w11k/tydux';
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { AuthenticationFacade } from './AuthenticationFacade';
import { OrderFacade } from './OrderFacade';
import { ShoppingCartFacade } from './ShoppingCartFacade';

export type AppFacades = ReturnType<typeof createFacades>;

const tyduxStore = process.env.NODE_ENV === 'development'
    ? createTyduxStore(undefined, {
        enhancer: applyMiddleware(createLogger({
            collapsed: true,
            duration: true,
            timestamp: false,
            diff: false,
        })),
    })
    : createTyduxStore();

removeGlobalStore();
setGlobalStore(tyduxStore);
if (process.env.NODE_ENV === 'development') {
    enableTyduxDevelopmentMode(true);
}

export function createFacades() {
    const databaseFacade = new GoogleSheetDatabaseFacade() as DatabaseFacade;
    const productSelectionFacade = new ProductSelectionFacade(databaseFacade);
    const productCustomizeFacade = new ProductCustomizeFacade(databaseFacade, productSelectionFacade/*, shoppingCartFacade*/);
    const shoppingCartFacade = new ShoppingCartFacade(databaseFacade, productSelectionFacade, productCustomizeFacade);
    const orderFacade = new OrderFacade(databaseFacade, productSelectionFacade, shoppingCartFacade);
    const authFacade = new AuthenticationFacade();
    // shoppingCartFacade.lateInit(productSelectionFacade, productCustomizeFacade);

    return {
        databaseFacade,
        productSelectionFacade,
        productCustomizeFacade,
        orderFacade,
        authFacade,
        shoppingCartFacade,
    };
}
