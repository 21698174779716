import { Commands, createAssignCommand, Facade } from '@w11k/tydux';
import { isNil } from '@w11k/rx-ninja';
import { ProductSelectionFacade } from './ProductSelectionFacade';
import { MemberTypeConverter, userMessages } from '../utils';
import {
    ConfiguredProduct,
    Design,
    extractLockAndHingeDrillingFromLabel,
    getDrillingTemplateStringForNorm,
    isCustomizableProduct,
    isDirectionLR,
    L_R,
    L_R_LR,
    Norm,
    Product,
} from '../domain';
import { uniqBy } from 'lodash';
import { DatabaseFacade } from './DatabaseFacade';

export const ABMESSUNGEN_CUSTOM = '__custom';
export const ZFM_WIDTH_DELTA = 7;
export const ZFM_HEIGHT_DELTA = 11;

export enum DoorType {
    RotatingDoor = 'Drehtür',
    SlidingDoor = 'Schiebetür',
}

// export enum DekorSvgPositionY {
//   CENTER = 'center',
//   BOTTOM = 'bottom',
//   TOP = 'top'
// }
//
// export enum DekorSvgPositionX {
//   CENTER = 'center',
//   HINGE = 'hinge',
//   LOCK = 'lock',
// }

// export type DekorSvg = {
//   svg: string;
//   svgViewBoxX: number,
//   svgViewBoxY: number,
//   positionX: number,
//   positionY: number,
//   productHeight: number,
//   productWidth: number,
//   exportSvg?: boolean,
//   loading?: boolean,
// }

export class ProductCustomizeState {

    options = {
        abmessungen: [] as [string, string][],
        bohrung: [] as string[],
        richtung: [] as L_R[],
        glasfarbe: [] as string[],
        blickdichte: [] as string[],
        sicherheit: [] as string[],
        doorType: [] as string[],
    };

    formValues?: MemberTypeConverter<ProductCustomizeState['options'], string> & {
        mitte_falle: string;
        mitte_falle_top: string;
    };
    // formValues?: MemberTypeConverter<Omit<ProductCustomizeState['options'], 'richtung'>, string> & {
    //     richtung: string;
    //     mitte_falle: string;
    // };

    customAbmessungen?: {
        tamWidth: string,
        tamHeight: string,
        zfmWidth: string,
        zfmHeight: string,
    };

    selectedProduct?: Product;

    configuredProduct?: ConfiguredProduct;

}

class ProductCustomizeCommands extends Commands<ProductCustomizeState> {

    setOptions = createAssignCommand(this, 'options');

    setFormValues = createAssignCommand(this, 'formValues');

    setCustomAbmessungen = createAssignCommand(this, 'customAbmessungen');

    setSelectedProduct = createAssignCommand(this, 'selectedProduct');

    setConfiguredProduct = createAssignCommand(this, 'configuredProduct');

    clearConfiguredProduct() {
        this.state.configuredProduct = undefined;
    }

    resetState() {
        this.state.selectedProduct = undefined;
        this.state.configuredProduct = undefined;
    }

    setConfiguredProductWithMatchingProductDataAndOptions(
        matchingProductData: {
            product: Product,
            norm: Norm,
            design?: Design,
        },
        options: {
            richtung: L_R,
            lock_drilling: string,
            hinge_drilling: string;
            width: number,
            height: number,
            mitte_falle: number,
            mitte_falle_top: number,
        }) {

        const {product, norm, design} = matchingProductData;
        const final: ConfiguredProduct['final'] = {
            sku: product.sku,
            // norm_code: norm.code,
            norm,
            width: options.width,
            height: options.height,
            length: product.length,
            color: product.color,
            direction: options.richtung,
            // lock_drilling: norm.norm_lock_drilling,
            // hinge_drilling: norm.norm_hinge_drilling,
            glass_opacity: product.glass_opacity,
            glass_safety: product.glass_safety,
            valid_country_select: product.valid_country_select,
            product_name: product.product_name,
            nav_no: product.nav_no,
            preview_living_situation: product.preview_living_situation,
            nav_recommended_retail_price_net: product.nav_recommended_retail_price_net,
            description_40_char_1: product.description_40_char_1,
            description_40_char_2: product.description_40_char_2,
            description_40_char_3: product.description_40_char_3,
            // bbl1: norm.bbl1_min,
            // bbl2: norm.bbl2_min,
            // bbl3: norm.bbl3_min,
            // mitte_falle: options.mitte_falle,
            door_type_multi: product.door_type_multi,
            glass_processing_front: product.glass_processing_front,
            glass_processing_back: product.glass_processing_back,
            design,
        };
        const modifications: ConfiguredProduct['modifications'] = {
            width: product.width !== options.width ? options.width : undefined,
            height: product.height !== options.height ? options.height : undefined,
            direction: product.direction !== options.richtung ? options.richtung : undefined,

            // mitte_falle: norm.lower_glass_corner_mid_latch_min !== options.mitte_falle ? options.mitte_falle : undefined,
            lower_glass_corner_mid_latch: options.mitte_falle,
            upper_glass_corner_mid_latch: options.height - options.mitte_falle,

            lock_drilling: options.lock_drilling,
            hinge_drilling: options.hinge_drilling,

            bbl1: norm?.bbl1_min,
            bbl2: norm?.bbl2_min,
            bbl3: norm?.bbl3_min,
        };

        this.state.configuredProduct = {
            final,
            modifications,
        };
    }
}


export class ProductCustomizeFacade extends Facade<ProductCustomizeCommands> {

    constructor(
        private readonly databaseFacade: DatabaseFacade,
        private readonly productSelectionFacade: ProductSelectionFacade,
        // private readonly shoppingCartFacade: ShoppingCartFacade,
    ) {
        super('productCustomize', new ProductCustomizeCommands(), new ProductCustomizeState());
    }

    setSelectedProduct(product: Product, configuredProduct: ConfiguredProduct | undefined | null, norm: Norm) {
        this.commands.setSelectedProduct(product);

        const withFormValues = configuredProduct
            ? this.loadFormValuesFromConfiguredProduct(product, configuredProduct)
            : this.createDefaultFormValues();

        if (!isNil(withFormValues)) {
            if (isCustomizableProduct(product) && product.direction as string === '') {
                this.commands.setSelectedProduct({
                    ...product,
                    direction: withFormValues.direction as L_R_LR,
                });
            }

            configuredProduct
                ? this.commands.setConfiguredProduct(configuredProduct)
                : this.commands.setConfiguredProductWithMatchingProductDataAndOptions({
                        product,
                        norm: product.norms[0],
                        design: product.designs[0],
                    },
                    {
                        richtung: withFormValues.direction,
                        lock_drilling: norm?.norm_lock_drilling,
                        hinge_drilling: norm?.norm_hinge_drilling,
                        width: product.width,
                        height: product.height,
                        mitte_falle: withFormValues?.mitte_falle,
                        mitte_falle_top: product.height - withFormValues.mitte_falle,
                    },
                );
        }
    }

    setCustomAbmessungen(abmessungen?: ProductCustomizeState['customAbmessungen']) {
        this.commands.setCustomAbmessungen(abmessungen);
    }

    productCustomizationChanged(form: NonNullable<ProductCustomizeState['formValues']>) {
        const abmessungen: [string, string] = form.abmessungen === ABMESSUNGEN_CUSTOM && !isNil(this.state.customAbmessungen)
            ? [this.state.customAbmessungen.tamWidth, this.state.customAbmessungen.tamHeight]
            : this.state.options.abmessungen[parseInt(form.abmessungen, 10)];
        const richtung = this.state.options.richtung[parseInt(form.richtung, 10)];
        const bohrung = this.state.options.bohrung[parseInt(form.bohrung, 10)];
        const glasfarbe = this.state.options.glasfarbe[parseInt(form.glasfarbe, 10)];
        const blickdichte = this.state.options.blickdichte[parseInt(form.blickdichte, 10)];
        const sicherheit = this.state.options.sicherheit[parseInt(form.sicherheit, 10)];
        const doorType = this.state.options.doorType[parseInt(form.doorType, 10)];

        // TODO: This is a temporary null pointer fix for Products with sku like XJ3YG90
        const requestedWidth = !isNil(abmessungen) ? parseInt(abmessungen[0], 10) : 0;
        const requestedHeight = !isNil(abmessungen) ? parseInt(abmessungen[1], 10) : 0;

        const mitte_falle = parseInt(form.mitte_falle, 10);
        const mitte_falle_top = parseInt(form.mitte_falle_top, 10);

        const foundProducts = this.findProduct(
            requestedWidth, requestedHeight, richtung, bohrung, glasfarbe,
            blickdichte, sicherheit, doorType, mitte_falle, mitte_falle_top,
        );

        const cheapest = foundProducts
            .sort((p1, p2) => {
                return parseInt(p1.product.nav_recommended_retail_price_net, 10)
                    - parseInt(p2.product.nav_recommended_retail_price_net, 10);
            })[0];

        if (!isNil(cheapest)) {
            this.commands.setConfiguredProductWithMatchingProductDataAndOptions(
                cheapest,
                {
                    richtung,
                    lock_drilling: cheapest.norm?.norm_lock_drilling,
                    hinge_drilling: cheapest.norm?.norm_hinge_drilling,
                    width: requestedWidth,
                    height: requestedHeight,
                    mitte_falle,
                    mitte_falle_top,
                });
        } else {
            this.commands.clearConfiguredProduct();
        }

        if (this.state.configuredProduct) {
            // this.commands.setSelectedProduct(this.state.configuredProduct.base);
            // this.productSelectionFacade.setSku(this.state.configuredProduct.base.sku);

            // this.createDefaultFormValues();
            // this.commands.setFormValues(form);

            const newSku = this.state.configuredProduct.final.sku;
            if (isCustomizableProduct(newSku)) {
                return this.productSelectionFacade.state.selectedSku;
            } else {
                return newSku;
            }
        } else {
            this.commands.setSelectedProduct(undefined);
        }

        return undefined;
    }

    resetState() {
        this.commands.resetState();
    }

    createProductCustomizationOptions(matching: Product[]) {
        const optionsAbmessungen = new Set<[string, string]>();
        const optionsRichtung = new Set<L_R>();
        const optionsBohrung = new Set<string>();
        const optionsGlasfarbe = new Set<string>();
        const optionsBlickdichte = new Set<string>();
        const optionsSicherheit = new Set<string>();
        const optionsDoorType = new Set<string>();

        matching.forEach(product => {
            if (!isCustomizableProduct(product)) {
                optionsAbmessungen.add([product.width.toString(), product.height.toString()]);
            }

            const direction = product.direction;
            if (isDirectionLR(direction)) {
                optionsRichtung.add('L');
                optionsRichtung.add('R');
            } else if (direction.trim() === '') {
                optionsRichtung.add('L');
                optionsRichtung.add('R');
            } else {
                optionsRichtung.add(direction);
            }

            optionsGlasfarbe.add(product.color);
            optionsBlickdichte.add(product.glass_opacity);
            optionsSicherheit.add(product.glass_safety);
            optionsDoorType.add(product.door_type_multi);

            product.norms.forEach(norm => {
                optionsBohrung.add(getDrillingTemplateStringForNorm(norm));
            });
        });

        this.commands.setOptions({
            abmessungen: uniqBy(Array.from(optionsAbmessungen), e => e[0] + 'x' + e[1]),
            richtung: Array.from(optionsRichtung),
            bohrung: Array.from(optionsBohrung),
            glasfarbe: Array.from(optionsGlasfarbe),
            blickdichte: Array.from(optionsBlickdichte),
            sicherheit: Array.from(optionsSicherheit),
            doorType: Array.from(optionsDoorType),
        });
    }

    private findProduct(
        requestedWidth: number,
        requestedHeight: number,
        richtung: string,
        bohrung: string,
        glasfarbe: string,
        blickdichte: string,
        sicherheit: string,
        doorType: DoorType | string,
        mitte_falle: number,
        mitte_falle_top: number): { product: Product, norm: Norm, design: Design }[] {
        const [lockDrilling, hingeDrilling] = extractLockAndHingeDrillingFromLabel(bohrung);

        userMessages.log(`%cSuche`, `font-weight:bold;color:#ffffff;background: #bababa;`, JSON.stringify({
            requestedWidth,
            requestedHeight,
            richtung,
            bohrung,
            glasfarbe,
            blickdichte,
            sicherheit,
            doorType,
            mitte_falle,
        }));

        return this.productSelectionFacade.state.matchingProducts
            .flatMap(p => {
                const width =
                    p.width === requestedWidth
                    || requestedWidth <= (p.width_max ?? 0);

                const height =
                    p.height === requestedHeight
                    || requestedHeight <= (p.height_max ?? 0);

                const direction =
                    richtung === p.direction // normaler Abgleich
                    || isDirectionLR(p.direction) // manche normalen Produkte erlauben l und r
                    || p.direction.trim() === ''; // bei "X-Produkten" ist das 'direction' Feld leer
                const colorMatch = glasfarbe === p.color;
                const opacity = blickdichte === p.glass_opacity;
                const safety = sicherheit === p.glass_safety;
                const isSameDoorType = doorType === p.door_type_multi;

                // Norms prüfen
                const logsForNormChecks: ['green' | 'red', string, string, Norm][] = [];
                const matchingNorms = p.norms.filter(norm => {

                    const latchMinLower = norm?.lower_glass_corner_mid_latch_min
                        && norm.lower_glass_corner_mid_latch_min <= mitte_falle;
                    const latchMinUpper = norm?.upper_glass_corner_mid_latch_min
                        && norm.upper_glass_corner_mid_latch_min <= mitte_falle_top;
                    const latchMin = latchMinLower ?? latchMinUpper;
                    const latchMaxLower = norm?.lower_glass_corner_mid_latch_max
                        && norm.lower_glass_corner_mid_latch_max >= mitte_falle;
                    const latchMaxUpper = norm?.upper_glass_corner_mid_latch_max
                        && norm.upper_glass_corner_mid_latch_max >= mitte_falle_top;
                    const latchMax = latchMaxLower ?? latchMaxUpper;

                    const matchingLockDrilling = lockDrilling === norm.norm_lock_drilling || lockDrilling === '';
                    const matchingHingeDrilling = hingeDrilling === norm.norm_hinge_drilling || hingeDrilling === '';
                    const smallerThanMaxHeight = requestedHeight <= norm.norm_height_max;
                    const biggerThanMinHeight = requestedHeight >= norm.norm_height_min;

                    const doesNormMatch = latchMin
                        && latchMax
                        && matchingLockDrilling
                        && matchingHingeDrilling
                        && smallerThanMaxHeight
                        && biggerThanMinHeight;

                    logsForNormChecks.push([doesNormMatch ? 'green' : 'red', norm.code, JSON.stringify({
                        latchMin,
                        latchMax,
                        matchingLockDrilling,
                        matchingHingeDrilling,
                        smallerThanMaxHeight,
                        biggerThanMinHeight,
                    }), norm]);

                    return doesNormMatch;
                });
                const firstMatchingNorm = matchingNorms[0];

                // Designs prüfen
                const logsForDesignChecks: ['green' | 'red', string, string, Design][] = [];
                const matchingDesigns = p.designs.filter(design => {
                    const smallerThanMaxHeight = requestedHeight <= design.heightMaxDecor;
                    const biggerThanMinHeight = requestedHeight >= design.heightMinDecor;
                    const smallerThanMaxWidth = requestedWidth <= design.widthMaxDecor;
                    const biggerThanMinWidth = requestedWidth >= design.widthMinDecor;
                    const doesDesignMatch = smallerThanMaxHeight && biggerThanMinHeight && smallerThanMaxWidth && biggerThanMinWidth;

                    logsForDesignChecks.push([doesDesignMatch ? 'green' : 'red', design.code, JSON.stringify({
                        smallerThanMaxHeight,
                        heigherThanMinHeight: biggerThanMinHeight,
                    }), design]);

                    return doesDesignMatch;
                });
                const doesDesignMatchAndProductHasDesigns = (p.designs.length > 0 && matchingDesigns.length > 0) || p.designs.length === 0;
                const firstMatchingDesign = matchingDesigns[0];

                const matching = doorType === DoorType.RotatingDoor
                    ? width && height && direction && colorMatch && opacity && safety && isSameDoorType
                    && !isNil(firstMatchingNorm) && doesDesignMatchAndProductHasDesigns
                    : width && height && direction && colorMatch && opacity && safety && isSameDoorType
                    && doesDesignMatchAndProductHasDesigns;

                userMessages.groupCollapsed(
                    `%cPrüfe ${p.sku}`,
                    `font-weight:bold;color:#ffffff;background: ${matching ? '#008700' : '#c10000'};`,
                    JSON.stringify({
                        width,
                        height,
                        direction,
                        color: colorMatch,
                        opacity,
                        safety,
                        isSameDoorType,
                        normsLength: p.norms.length,
                        matchingNormsLength: matchingNorms.length,
                        designLength: p.designs.length,
                        matchingDesignLength: matchingDesigns.length,
                    }));
                userMessages.log(`%cProduct`, `font-weight:bold;color:#ffffff;background: #bababa;`, p);
                logsForNormChecks.forEach(([color, title, msg, norm]) => {
                    userMessages.log(
                        `%cNorm ${title}`,
                        `font-weight:bold;color:#ffffff;background: ${color === 'green' ? '#008700' : '#c10000'};`,
                        msg,
                        norm,
                    );
                });
                logsForDesignChecks.forEach(([color, title, msg, design]) => {
                    userMessages.log(
                        `%cDesign ${title}`,
                        `font-weight:bold;color:#ffffff;background: ${color === 'green' ? '#008700' : '#c10000'};`,
                        msg,
                        design,
                    );
                });
                console.groupEnd();

                if (matching) {
                    return [{
                        product: p,
                        norm: firstMatchingNorm,
                        design: firstMatchingDesign,
                    }];
                }
                return [];
            });
    }

    private loadFormValuesFromConfiguredProduct(selectedProduct: Product, configuredProduct: ConfiguredProduct) {
        const {
            direction: configuredDirection,
            width: configuredWidth,
            height: configuredHeight,
            door_type_multi: configuredDoorTypeMulti,
            color: configuredColor,
            glass_opacity: configuredGlassOpacity,
            glass_safety: configuredGlassSafety,
        } = configuredProduct.final;
        const {
            lower_glass_corner_mid_latch: configuredMitteFalle,
            upper_glass_corner_mid_latch: configuredMitteFalleTop,
        } = configuredProduct.modifications;
        const {
            drilling_template: selectedDrillingTemplate,
        } = selectedProduct;

        const direction: L_R =
            (configuredDirection as string === '' || isDirectionLR(configuredDirection))
                ? 'L' : configuredDirection;
        const mitte_falle = configuredMitteFalle;
        const mitte_falle_top = configuredMitteFalleTop;

        let abmessungen = this.state.options.abmessungen.findIndex(
            e => e[0] === configuredWidth.toString() && e[1] === configuredHeight.toString(),
        );
        if (abmessungen < 0) {
            this.commands.setOptions({
                ...this.state.options,
                abmessungen: [[configuredWidth.toString(), configuredHeight.toString()]],
            });
            abmessungen = 0;
        }

        const bohrung = Math.max(this.state.options.bohrung.indexOf(selectedDrillingTemplate), 0);
        const doorType = Math.max(this.state.options.doorType.indexOf(configuredDoorTypeMulti), 0);

        this.commands.setFormValues({
            abmessungen: abmessungen.toString(),
            bohrung: bohrung.toString(),
            doorType: doorType.toString(),
            richtung: this.state.options.richtung.indexOf(direction).toString(),
            glasfarbe: this.state.options.glasfarbe.indexOf(configuredColor).toString(),
            blickdichte: this.state.options.blickdichte.indexOf(configuredGlassOpacity).toString(),
            sicherheit: this.state.options.sicherheit.indexOf(configuredGlassSafety).toString(),

            mitte_falle: mitte_falle.toString(),
            mitte_falle_top: mitte_falle_top.toString(),
        });

        return {
            selectedProduct,
            direction,
            mitte_falle,
            mitte_falle_top,
        };
    }

    private createDefaultFormValues() {
        const sku = this.productSelectionFacade.state.selectedSku;
        if (isNil(sku)) {
            this.commands.setFormValues(undefined);
            return;
        }

        const product = this.databaseFacade.state.availableProductsBySku[sku];
        if (isNil(product)) {
            this.commands.setFormValues(undefined);
            return;
        }

        const direction: L_R = (product.direction as string === '' || isDirectionLR(product.direction))
            ? 'L' : product.direction;
        const mitte_falle = 1068;
        const mitte_falle_top = product.height - mitte_falle;

        let abmessungen = this.state.options.abmessungen.findIndex(
            e => e[0] === product.width.toString() && e[1] === product.height.toString(),
        );
        if (abmessungen < 0) {
            this.commands.setOptions({
                ...this.state.options,
                abmessungen: [[product.width.toString(), product.height.toString()]],
            });
            abmessungen = 0;
        }

        const bohrung = Math.max(this.state.options.bohrung.indexOf(product.drilling_template), 0);
        const doorType = Math.max(this.state.options.doorType.indexOf(product.door_type_multi), 0);

        this.commands.setFormValues({
            abmessungen: abmessungen.toString(),
            bohrung: bohrung.toString(),
            doorType: doorType.toString(),
            richtung: this.state.options.richtung.indexOf(direction).toString(),
            glasfarbe: this.state.options.glasfarbe.indexOf(product.color).toString(),
            blickdichte: this.state.options.blickdichte.indexOf(product.glass_opacity).toString(),
            sicherheit: this.state.options.sicherheit.indexOf(product.glass_safety).toString(),

            mitte_falle: mitte_falle.toString(),
            mitte_falle_top: mitte_falle_top.toString(),
        });

        return {
            product,
            direction,
            mitte_falle,
            mitte_falle_top,
        };
    }

}
