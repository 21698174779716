import { isNil } from '@w11k/rx-ninja';
import { DoorType } from './facades/ProductCustomizeFacade';

export type L_R = 'L' | 'R';
export type L_R_LR = L_R | 'L/R';

export function isDirectionL(direction: string): direction is 'L' {
    return direction.trim().toUpperCase() === 'L';
}

export function isDirectionR(direction: string): direction is 'R' {
    return direction.trim().toUpperCase() === 'R';
}

export function isDirectionLR(direction: string): direction is 'L/R' {
    return direction.trim().toUpperCase() === 'L/R'
        || direction.trim().toUpperCase() === 'R/L';
}

export type Product = {
    brand: string;
    color: string;
    description_40_char_1: string;
    description_40_char_2: string;
    description_40_char_3: string;
    direction: L_R_LR;
    drilling_template: string;
    family: string;
    glass_opacity: string;
    glass_safety: string;
    width: number;
    width_max?: number;
    height: number;
    height_max?: number;
    length: number;
    // lock_drilling: string;
    // hinge_drilling: string;
    glass_processing_front: string;
    glass_processing_back: string;
    nav_no: string;
    product_name: string;
    sku: string;
    valid_country_select: string;
    nav_recommended_retail_price_net: string;
    preview_living_situation: string;
    // bbl1?: number;
    // bbl2?: number;
    // bbl3?: number;
    // mitte_falle?: number;
    door_type_multi: string;

    norms: Norm[];
    designs: Design[];
};

export type Norm = {
    code: string; // ID
    norm: string; // e.g. din_norm, oe_norm
    norm_hinge_drilling: string;
    norm_lock_drilling: string;
    norm_height_min: number;
    norm_height_max: number;
    hinge_offset: number;
    bbl1_min: number;
    bbl1_max: number;
    bbl2_min: number;
    bbl2_max: number;
    bbl3_min: number | undefined;
    bbl3_max: number | undefined;
    lower_glass_corner_mid_latch_min: number | undefined;
    lower_glass_corner_mid_latch_max: number | undefined;
    upper_glass_corner_mid_latch_min: number | undefined;
    upper_glass_corner_mid_latch_max: number | undefined;
};

export type DesignPosX = 'center' | 'lock' | 'hinge';
export type DesignPosY = 'center' | 'bottom';

export type Design = {
    code: string;
    processing: string;
    mirrorHorizontal: string;
    svgFile: string;
    heightMinDecor: number;
    heightMaxDecor: number;
    widthMinDecor: number;
    widthMaxDecor: number;
    posX: DesignPosX;
    posY: DesignPosY;
    scale: boolean;
}

export type ConfiguredProduct = {
    final: {
        sku: Product['sku'];
        norm: Norm,
        color: Product['color'];
        direction: L_R;
        glass_processing_front: Product['glass_processing_front'];
        glass_processing_back: Product['glass_processing_back'];
        glass_opacity: Product['glass_opacity'];
        glass_safety: Product['glass_safety'];
        valid_country_select: Product['valid_country_select'];
        product_name: Product['product_name'];
        nav_no: Product['nav_no'],
        description_40_char_1: Product['description_40_char_1'];
        description_40_char_2: Product['description_40_char_2'];
        description_40_char_3: Product['description_40_char_3'];
        nav_recommended_retail_price_net: Product['nav_recommended_retail_price_net'];
        preview_living_situation: Product['preview_living_situation'];
        width: number;
        height: number;
        length: number;
        design?: Design;
        door_type_multi: string;
    };
    modifications: {
        lower_glass_corner_mid_latch: number;
        upper_glass_corner_mid_latch: number;

        lock_drilling: string;
        hinge_drilling: string;

        bbl1: Norm['bbl1_min'];
        bbl2: Norm['bbl2_min'];
        bbl3?: Norm['bbl3_min'];

        width?: number;
        height?: number;
        direction?: L_R;
    }
};

export type Order = {
    customerNumber: string;
    message: string;
    product: {
        sku: string;
    };
    modifications: {
        norm_code: Norm['code'],
        norm: Norm['norm'],
        color: Product['color'];
        direction: L_R;
        lock_drilling: string;
        hinge_drilling: string;
        glass_processing_front: Product['glass_processing_front'];
        glass_processing_back: Product['glass_processing_back'];
        glass_opacity: Product['glass_opacity'];
        glass_safety: Product['glass_safety'];
        valid_country_select: Product['valid_country_select']; // übergeben?
        hinge_offset: Norm['hinge_offset'];
        bbl1: Norm['bbl1_min'];
        bbl2: Norm['bbl2_min'];
        bbl3: Norm['bbl3_min'];
        product_name: Product['product_name'];
        nav_no: Product['nav_no'],
        description_40_char_1: Product['description_40_char_1'];
        description_40_char_2: Product['description_40_char_2'];
        description_40_char_3: Product['description_40_char_3'];
        nav_recommended_retail_price_net: Product['nav_recommended_retail_price_net'];

        width: number;
        height: number;
        length: number;

        lower_glass_corner_mid_latch: number;
        upper_glass_corner_mid_latch: number;

        design_code?: Design['code'];
        door_type_multi: string;
    };
}

export type Contact = {
    name: string;
    delivery_address_1: string;
    delivery_address_2: string;
    delivery_postcode: string;
    delivery_city: string;
    delivery_country: string;
    e_mail: string;
}

export type ProductFromBackend = {
    sku: string;
    description_40_char_1: Product['description_40_char_1'];
    description_40_char_2: Product['description_40_char_2'];
    description_40_char_3: Product['description_40_char_3'];
}

export type ProductFromBackendV2 = ProductFromBackend & {
    nav_no: string;
    quantity: number;
    configuration_url: string;
}

export type ModificationsFromBackend = {
    norm_code: Norm['code'],
    Norm: Norm['norm'],
    Farbe: Product['color'];
    Richtung: L_R;
    Schlossbohrung: string;
    Bandbohrung: string;
    Glasbearbeitung_Vorderseite: Product['glass_processing_front'];
    Glasbearbeitung_Rückseite: Product['glass_processing_back'];
    Glastransparenz: Product['glass_opacity'];
    Glassicherheit: Product['glass_safety'];
    Land: Product['valid_country_select']; // übergeben?
    Bandversatz: Norm['hinge_offset'];
    Bandbezugslinie_1: Norm['bbl1_min'];
    Bandbezugslinie_2: Norm['bbl2_min'];
    Bandbezugslinie_3: Norm['bbl3_min'];
    Produktname: Product['product_name'];
    Artikelnummer: Product['nav_no'],
    Empfohlener_Verkaufspreis: Product['nav_recommended_retail_price_net'];

    Breite: number;
    Höhe: number;
    Länge: number;

    Untere_Glaskante_Mitte_Falle: number;
    Obere_Glaskante_Mitte_Falle: number;

    Designcode?: Design['code'];
    Türart: string;
}

export type ItemFromBackend = {
    product: ProductFromBackendV2;
    modifications: ModificationsFromBackend;
}

export type OrderItemV2 = {
    product: ProductFromBackendV2;
    modifications: Order['modifications'];
}

export type OrderGetFromBackend = {
    customerNumber: string;
    message: string;
    product: ProductFromBackend;
    modifications: ModificationsFromBackend;
}

export type OrderGenericV2 = {
    type: string;
    customerNumber: string;
    ext_voucher: string;
    message: string;
    contact: Contact | Record<string, never>;
}

export type OrderGetFromBackendV2 = OrderGenericV2 & {
    items: ItemFromBackend[];
}

export type OrderV2 = OrderGenericV2 & {
    items: OrderItemV2[];
}

type Primitive =
    | string
    | number
    | boolean
    | undefined
    | null;

type DeepOmit<T, K> = {
    [P in Exclude<keyof T, K>]: T[P] extends Primitive
        ? T[P]
        : DeepOmit<T[P], K>
};

type OmitFromRotatingDoor =
    'Norm'
    | 'Richtung'
    | 'Schlossbohrung'
    | 'Bandbohrung'
    | 'Bandversatz'
    | 'Bandbezugslinie_1'
    | 'Bandbezugslinie_2'
    | 'Bandbezugslinie_3'
    | 'Untere_Glaskante_Mitte_Falle'
    | 'Obere_Glaskante_Mitte_Falle';
export type OrderPostRotatingDoorToBackend = DeepOmit<OrderGetFromBackend, 'norm_code'>;
export type OrderPostSlidingDoorToBackend = DeepOmit<OrderPostRotatingDoorToBackend, OmitFromRotatingDoor>;
export type OrderPostToBackend = OrderPostRotatingDoorToBackend | OrderPostSlidingDoorToBackend;

export type OrderPostRotatingDoorToBackendV2 = DeepOmit<OrderGetFromBackendV2, 'norm_code'>;
export type OrderPostSlidingDoorToBackendV2 = DeepOmit<OrderPostRotatingDoorToBackendV2, OmitFromRotatingDoor>;
export type OrderPostToBackendV2 = OrderPostRotatingDoorToBackendV2 | OrderPostSlidingDoorToBackendV2;

export function isCustomizableProduct(productOrSku: Product | string): boolean {
    if (typeof productOrSku === 'string') {
        return productOrSku[0] === 'X';
    } else {
        return productOrSku.sku[0] === 'X';
    }
}

export function extractLockAndHingeDrillingFromLabel(label: string | undefined) {
    // TODO: temporary fix for products with no drilling (products with sku like XJ3...)
    if (isNil(label)) {
        return ['', ''];
    }
    const [lock, hinge] = label.split('/');
    return [lock.toLowerCase(), hinge.toLowerCase()];
}

export function isOfficeDrilling(drilling: string) {
    return drilling.toLowerCase().indexOf('office') !== -1;
}

export function isStudioDrilling(drilling: string) {
    return drilling.toLowerCase().indexOf('studio') !== -1;
}

export function getDrillingTemplateStringForNorm(norm: Norm) {
    return (isStudioDrilling(norm.norm_lock_drilling) ? 'Studio' : 'Office')
        + '/'
        + (isStudioDrilling(norm.norm_hinge_drilling) ? 'Studio' : 'Office');
}

export function checkIsSlidingDoor(doorType: string | undefined): boolean {
    if (isNil(doorType)) {
        return false;
    }
    return doorType.trim() === DoorType.SlidingDoor;
}

export function extractDoorTypeMulti(productOrDoorType: Product | string): string[] {
    if (typeof productOrDoorType === 'string') {
        return productOrDoorType.split(',').flatMap(d => d.trim());
    } else {
        return productOrDoorType.door_type_multi.split(',').flatMap(d => d.trim());
    }
}

/**
 * User-defined type guards:
 */

/** Narrows: `Order | OrderGetFromBackend | OrderGetFromBackendV2` to `OrderGetFromBackendV2` or `OrderGetFromBackend | Order` */
export const isOfTypeOrderGetFromBackendV2 =
    (order: Order | OrderGetFromBackend | OrderGetFromBackendV2): order is OrderGetFromBackendV2 =>
    'items' in order;

/** Narrows: `OrderGetFromBackend | Order` to `OrderGetFromBackend` or `Order` */
export const isOfTypeOrderGetFromBackend = (order: Order | OrderGetFromBackend): order is OrderGetFromBackend =>
    'Artikelnummer' in order.modifications;
