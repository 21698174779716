import ReactGA from 'react-ga4';
import { userMessages } from '../utils';
import { ShoppingCartSelection } from '../facades/ShoppingCartFacade';
import { ANALYTICS_EVENTS, AnalyticsEventParams, OrderItem } from './analytic-event-types';
import { ConfiguredProduct, ItemFromBackend } from '../domain';

export function initializeAnalytics() {
    try {
        enableAnalytics();
    } catch (error) {
        console.warn(error);
    }
}

export function sendBasketPurchaseSuccessAnalyticsEvent(priceTotal: string,
                                                        items: ShoppingCartSelection[],
                                                        label: string): AnalyticsEventParams {
    const eventParams: AnalyticsEventParams = {
        value: getB2bPrice(priceTotal, 0.5),
        currency: 'EUR',
        category: 'glass config',
        label: label,
        items: items.map((item): OrderItem => {
            return {
                item_id: item.selectedProduct?.sku,
                item_name: item.selectedProduct?.product_name,
                price: getB2bPrice(item.configuredProduct ?
                    item.configuredProduct.final.nav_recommended_retail_price_net :
                    item.selectedProduct?.nav_recommended_retail_price_net, 0, 0.19),
                quantity: 1,
                currency: 'EUR',
                item_brand: item.selectedProduct?.brand,
                item_category: 'NAV Glas Konfigurationsartikel',
            };
        }),
    };

    sendAnalyticsEvent(ANALYTICS_EVENTS.ORDER_POST_SUCCESS, eventParams);

    return eventParams;
}

export function sendCPPurchaseSuccessAnalyticsEvent(item: ConfiguredProduct,
                                                    label: string): AnalyticsEventParams {
    const eventParams: AnalyticsEventParams = {
        value: getB2bPrice(item.final.nav_recommended_retail_price_net, 0.5),
        currency: 'EUR',
        category: 'glass config',
        label: label,
        items: [{
                item_id: item.final.sku,
                item_name: item.final.product_name,
                price: getB2bPrice(item.final.nav_recommended_retail_price_net, 0, 0.19),
                quantity: 1,
                currency: 'EUR',
                item_category: 'NAV Glas Konfigurationsartikel',
            }],
    };

    sendAnalyticsEvent(ANALYTICS_EVENTS.ORDER_POST_SUCCESS, eventParams);

    return eventParams;
}

export function sendPurchaseErrorAnalyticsEvent(label: string): AnalyticsEventParams {
    const eventParams: AnalyticsEventParams = {
        category: 'glass config',
        label,
    };

    sendAnalyticsEvent(ANALYTICS_EVENTS.ORDER_POST_ERROR, eventParams);

    return eventParams;
}

export function sendOrderViewSuccessAnalyticsEvent(label: string,
                                                   items: ItemFromBackend[]): AnalyticsEventParams {
    const eventParams: AnalyticsEventParams = {
        category: 'glass config',
        label,
        items: items?.map((item): OrderItem => {
            return {
                item_id: item.product.sku,
                item_name: item.modifications.Produktname,
                price: getB2bPrice(item.modifications.Empfohlener_Verkaufspreis, 0, 0.19),
                quantity: 1,
                currency: 'EUR',
                item_category: 'NAV Glas Konfigurationsartikel',
            };
        }),
    };

    sendAnalyticsEvent(ANALYTICS_EVENTS.ORDER_GET_SUCCESS, eventParams);

    return eventParams;
}

export function sendOrderViewErrorAnalyticsEvent(label: string): AnalyticsEventParams {
    const eventParams: AnalyticsEventParams = {
        category: 'glass config',
        label,
    };

    sendAnalyticsEvent(ANALYTICS_EVENTS.ORDER_GET_ERROR, eventParams);

    return eventParams;
}

function getB2bPrice(price: string | undefined, discount: number, tax?: number): number {
    if (price !== undefined && parseFloat(price) > 0) {
        let calculatedPrice = parseFloat(price);
        if (discount > 0) {
            calculatedPrice = calculatedPrice * (1 - discount);
        } else if (tax !== undefined) {
            calculatedPrice = calculatedPrice * (1 + tax);
        }
        return calculatedPrice;
    } else {
        return 0;
    }
}

export function sendAnalyticsEvent(analyticsEvent: ANALYTICS_EVENTS,
                                   params: AnalyticsEventParams) {
    if (process.env.REACT_APP_ANALYTICS_TRACKING_CODE) {
        ReactGA.event(analyticsEvent, params);
    }
}

function enableAnalytics() {
    if (process.env.REACT_APP_ANALYTICS_TRACKING_CODE) {
        console.info('enable analytics with id:', process.env.REACT_APP_ANALYTICS_TRACKING_CODE);
        ReactGA.initialize([{
            trackingId: process.env.REACT_APP_ANALYTICS_TRACKING_CODE,
            gaOptions: {
                debug_mode: process.env.NODE_ENV === 'development',
            },
            gtagOptions: {
                debug_mode: process.env.NODE_ENV === 'development',
            },
        }]);
        ReactGA.send({hitType: 'pageview', page: window.location.pathname + window.location.search});
        ReactGA.ga('set', 'anonymizeIp', true);
        ReactGA.ga('set', 'allow_google_signals', false);
        ReactGA.ga('set', 'allowAdFeatures', false);
    } else {
        userMessages.warn('No analytics tracking code was found');
    }
}
