import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { isNil } from '@w11k/rx-ninja';
import { useFacadeState } from '../utils';
import { PrivateRoute } from '../PrivateRoute';
import { AppFacades } from '../facades/app-facades';
import { LoadingSpinner } from './ProductPage';
import { Configurator } from '../products/Products';

export default function Product({userLoggedIn, facades}: { userLoggedIn: boolean; facades: AppFacades }): JSX.Element {
    const availableProducts = useFacadeState(facades.databaseFacade, s => s.availableProducts);
    const userType = useFacadeState(facades.authFacade, s => s.userType);


    if (isNil(availableProducts) || availableProducts.length === 0) {
        return <LoadingSpinner/>;
    }

    return (
        <Switch>
            <PrivateRoute
                path={'/product/:sku'}
                exact={true}
                isAuthenticated={userLoggedIn}>
                <Configurator userLoggedIn={userLoggedIn} facades={facades} userType={userType}/>
            </PrivateRoute>

            <Redirect from={'/product'} to={'/'}/>
        </Switch>
    );
}
