import { checkIsSlidingDoor, ConfiguredProduct, Order } from '../../domain';
import { isNil } from '@w11k/rx-ninja';
import NumberFormat from 'react-number-format';
import React from 'react';
import { Divider } from '@material-ui/core';
import { ConfiguredProductOrderModal } from '../basket/orderModal/ConfiguredProductOrderModal';
import { AuthenticationState } from '../../facades/AuthenticationFacade';

export default function ProductDescription(props: {
    configuredProduct: ConfiguredProduct;
    order?: Order;
    allowOrderSubmit?: boolean
    user: Readonly<AuthenticationState>;
}) {
    const cp = props.configuredProduct;
    const prod = cp.final;
    const norm = prod.norm;
    const mods = props.configuredProduct.modifications;
    const isSlidingDoor = checkIsSlidingDoor(prod.door_type_multi);

    return (
        <>
            <div className="p-3">
                {prod.preview_living_situation &&
                <div>
                    <img src={prod.preview_living_situation} alt="Vorschau"/>
                    <hr/>
                </div>}
                <div className="pt-3">
                    <div>
                        <div><b>Türart:</b> {prod.door_type_multi}</div>
                        <div><b>Produktname:</b> {prod.product_name}</div>
                        <div><b>Breite:</b> {prod.width} mm</div>
                        <div><b>Höhe:</b> {prod.height} mm</div>
                        <div><b>Länge:</b> {prod.length} mm</div>
                        {!isSlidingDoor && <>
                            <div><b>Richtung:</b> {prod.direction}</div>
                            <div><b>Norm:</b> {norm.norm}</div>
                            <div><b>Schlossbohrung:</b> {mods.lock_drilling}</div>
                            <div><b>Bandbohrung:</b> {mods.hinge_drilling}</div>
                        </>}
                        <div className="mb-3"/>
                        <div><b>Glasbearbeitung Vorderseite:</b> {prod.glass_processing_front}</div>
                        <div><b>Glasbearbeitung Rückseite:</b> {prod.glass_processing_back}</div>
                        {!isSlidingDoor && <>
                            <div><b>Obere Glaskante Mitte Falle:</b> {mods.upper_glass_corner_mid_latch} mm</div>
                            <div><b>Untere Glaskante Mitte Falle:</b> {mods.lower_glass_corner_mid_latch} mm</div>

                            <div><b>Bandversatz:</b> {norm.hinge_offset} mm</div>
                            <div><b>Bandbezugslinie 1:</b> {mods.bbl1} mm</div>
                            <div><b>Bandbezugslinie 2:</b> {mods.bbl2} mm</div>
                            {!isNil(mods.bbl3) && <div><b>Bandbezugslinie 3:</b> {mods.bbl3} mm</div>}
                        </>}

                        <div><b>SKU:</b> {prod.sku}</div>
                        <div><b>Artikelnummer:</b> {prod.nav_no}</div>
                    </div>
                </div>

                {!isNil(props.order) &&
                <div className="pt-3">
                    {props.order.message}
                </div>
                }

                <div className="pt-3 font-weight-bold">
                    <NumberFormat decimalSeparator=","
                                  displayType="text"
                                  value={prod.nav_recommended_retail_price_net}
                                  suffix={'€'}
                                  decimalScale={2}
                                  fixedDecimalScale={true}/>
                </div>
                <div className="text-muted mb-1">
                    UVP des Herstellers <br/>
                    alle Preise exkl. MwSt.
                </div>

                {props.allowOrderSubmit &&
                    <ConfiguredProductOrderModal configuredProduct={cp} user={props.user}/>
                }
            </div>
            <div className="footer">
                <Divider/>
                <a href={process.env.REACT_APP_PRIVACY_URL} rel="noreferrer" target="_blank">DATENSCHUTZ</a>
                <a href={process.env.REACT_APP_IMPRINT_URL} rel="noreferrer" target="_blank">IMPRESSUM</a>
                <a href={process.env.REACT_APP_AGB_URL} rel="noreferrer" target="_blank">AGB</a>
            </div>
        </>
    );
}
