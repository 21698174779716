import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    ABMESSUNGEN_CUSTOM,
    ProductCustomizeFacade,
    ZFM_HEIGHT_DELTA,
    ZFM_WIDTH_DELTA,
} from '../../../facades/ProductCustomizeFacade';
import { isNil } from '@w11k/rx-ninja';
import { useFacadeState, userMessages } from '../../../utils';
import { checkIsSlidingDoor, isCustomizableProduct } from '../../../domain';
import { CustomizedMeasurement, ProductFieldNames, updateMitteFalle } from '../../../product/lock-drilling-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { LockDrillingDirectionSwitch } from '../../../product/LockDrillingDirectionSwitch';

import './productsCustomize.scss';

export default function ProductsCustomize(props: { productCustomizeFacade: ProductCustomizeFacade, disabledMode: boolean }) {
    const customAbmessungenState = props.productCustomizeFacade.state.customAbmessungen;
    const customAbmessungenDefined = !isNil(customAbmessungenState);

    const options = useFacadeState(
        props.productCustomizeFacade,
        s => s.options,
        {
            abmessungen: [],
            richtung: [],
            bohrung: [],
            glasfarbe: [],
            blickdichte: [],
            sicherheit: [],
            doorType: [],
        });

    const initialFormValue = useFacadeState(
        props.productCustomizeFacade,
        s => s.formValues,
        {
            abmessungen: '',
            bohrung: '',
            richtung: '',
            glasfarbe: '',
            blickdichte: '',
            sicherheit: '',
            mitte_falle: '',
            mitte_falle_top: '',
            doorType: '',
        });

    const [formValue, setFormValue] = useState(initialFormValue);

    useEffect(() => {
        setFormValue(initialFormValue);
    }, [initialFormValue]);

    const [showCustomAbmessungenForm, setShowCustomAbmessungenForm] = useState(false);
    // const history = useHistory();

    const isSlidingDoor = checkIsSlidingDoor(
        props.productCustomizeFacade.state.options.doorType[parseInt(formValue?.doorType, 10)],
    );
    const isXProduct = isCustomizableProduct(props.productCustomizeFacade.state.selectedProduct?.sku ?? '');

    const submit = (form: typeof formValue, fieldName: ProductFieldNames, customizedMeasurement?: CustomizedMeasurement) => {
        const newFormValue = !isNil(form);
        form = !isNil(form) ? form : formValue;
        if (newFormValue) {
            const [mitte_falle, mitte_falle_top] = updateMitteFalle(
                form, options, fieldName, customizedMeasurement, customAbmessungenState,
            );
            const updatedForm = {
                ...form,
                mitte_falle,
                mitte_falle_top,
            };
            form = updatedForm;
            setFormValue(updatedForm);
        }

        const newSku = props.productCustomizeFacade.productCustomizationChanged(form);
        if (!isNil(newSku)) {
            /* NOTE: This doesn't work anymore. */
            // history.replace({
            //     pathname: newSku,
            //     search: '',
            // });
        } else {
            userMessages.warn('Kein Produkt gefunden!');
        }
    };

    const registerFormControl = (fieldName: keyof NonNullable<typeof initialFormValue>) => ({
        // ref: register,
        onChange: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            const newForm = {
                ...formValue,
                [fieldName]: event.target.value,
            };

            setFormValue(newForm);
            if (newForm.abmessungen === ABMESSUNGEN_CUSTOM && !customAbmessungenDefined) {
                setShowCustomAbmessungenForm(true);
                return;
            }
            submit(newForm, fieldName as ProductFieldNames);
        },
        value: formValue ? formValue[fieldName] : '',
    });

    return (
        <div className="product-customize-form">
            <div>
                {!showCustomAbmessungenForm &&
                <form>
                    <div className="product-field">
                        {isXProduct ? <>
                            <span>Türart</span>
                            <select className="form-control"{...registerFormControl('doorType')}
                                    disabled={props.disabledMode || !isXProduct}>
                                {options.doorType.map((e, idx) =>
                                    <option key={e} value={idx}>{e}</option>)}
                            </select>
                        </> : <>
                            <span>Türart: <b>{options.doorType[parseInt(formValue?.doorType, 10)]}</b></span>
                        </>}
                    </div>
                    <div className="product-field">
                        <span>Abmessungen (mm)</span>
                        <div className="d-flex">
                            <select className="form-control"{...registerFormControl('abmessungen')}
                                    disabled={props.disabledMode}>
                                <optgroup label="Standardwerte">
                                    {options.abmessungen.map((e, idx) =>
                                        <option key={idx} value={idx}>{e[0]} x {e[1]} mm</option>)}
                                </optgroup>
                                <optgroup label="Individueller Wert">
                                    <option value={ABMESSUNGEN_CUSTOM}>
                                        {customAbmessungenDefined
                                            ? customAbmessungenState?.tamWidth + ' x ' + customAbmessungenState?.tamHeight + ' mm'
                                            : 'Erstellen...'
                                        }
                                    </option>
                                </optgroup>
                            </select>
                            <a onClick={() => !props.disabledMode && setShowCustomAbmessungenForm(true)}>
                                <FontAwesomeIcon icon={faEdit}/>
                            </a>
                        </div>
                    </div>
                    {!isSlidingDoor && <>
                        <div className="product-field">
                            <span>Bohrung</span>
                            <select className="form-control"{...registerFormControl('bohrung')}
                                    disabled={props.disabledMode}>
                                {options.bohrung.map((e, idx) =>
                                    <option key={e} value={idx}>{e}</option>)}
                            </select>
                        </div>
                        <div className="product-field">
                            <LockDrillingDirectionSwitch registerFormControl={registerFormControl}
                                                         disabledMode={props.disabledMode}/>
                        </div>
                        <div className="product-field">
                            <span>Richtung</span>
                            <select className="form-control"{...registerFormControl('richtung')}
                                    disabled={props.disabledMode}>
                                {options.richtung.map((e, idx) =>
                                    <option key={e} value={idx}>{e.toUpperCase()}</option>)}
                            </select>
                        </div>
                    </>}
                    <div className="product-field">
                        <span>Glasfarbe</span>
                        <select className="form-control"{...registerFormControl('glasfarbe')}
                                disabled={props.disabledMode}>
                            {options.glasfarbe.map((e, idx) =>
                                <option key={e} value={idx}>{e}</option>)}
                        </select>
                    </div>
                    <div className="product-field">
                        <span>Blickdichte</span>
                        <select className="form-control"{...registerFormControl('blickdichte')}
                                disabled={props.disabledMode}>
                            {options.blickdichte.map((e, idx) =>
                                <option key={e} value={idx}>{e}</option>)}
                        </select>
                    </div>
                    <div className="product-field">
                        <span>Sicherheit</span>
                        <select className="form-control"{...registerFormControl('sicherheit')}
                                disabled={props.disabledMode}>
                            {options.sicherheit.map((e, idx) =>
                                <option key={e} value={idx}>{e}</option>)}
                        </select>
                    </div>
                </form>
                }
            </div>
            {showCustomAbmessungenForm &&
            <div>
                <CustomAbmessungenForm
                    productCustomizeFacade={props.productCustomizeFacade}
                    close={customizedMeasurement => {
                        setShowCustomAbmessungenForm(false);
                        submit({
                                ...formValue,
                                abmessungen: ABMESSUNGEN_CUSTOM,
                            },
                            'abmessungen',
                            customizedMeasurement,
                        );
                    }}
                    isSlidingDoor={isSlidingDoor}
                />
            </div>
            }
        </div>
    );
}

function CustomAbmessungenForm(props: {
    productCustomizeFacade: ProductCustomizeFacade,
    close: (formValue: CustomizedMeasurement) => void,
    isSlidingDoor: boolean,
}) {
    const configuredProduct = props.productCustomizeFacade.state.configuredProduct;
    const customAbmessungen = props.productCustomizeFacade.state.customAbmessungen;
    const initialTamWidth: string = configuredProduct?.final.width.toString() ?? customAbmessungen?.tamWidth.toString() ?? '1000';
    const initialTamHeight: string = configuredProduct?.final.height.toString() ?? customAbmessungen?.tamHeight.toString() ?? '2000';
    const initialZfmWidth = customAbmessungen?.zfmWidth ?? (1000 + ZFM_WIDTH_DELTA).toString();
    const initialZfmHeight = customAbmessungen?.zfmHeight ?? (2000 + ZFM_HEIGHT_DELTA).toString();
    const isSlidingDoor = props.isSlidingDoor;

    const [formValue, setFormValue] = useState({
        tamWidth: initialTamWidth,
        tamHeight: initialTamHeight,
        zfmWidth: initialZfmWidth,
        zfmHeight: initialZfmHeight,
    } as CustomizedMeasurement);

    const registerTAMField = (fieldName: keyof NonNullable<typeof formValue>) => ({
        onChange: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            let newFormValue = {
                ...formValue,
                [fieldName]: event.target.value,
            };

            if (fieldName.startsWith('tam')) {
                newFormValue = {
                    ...newFormValue,
                    zfmWidth: (parseInt(newFormValue.tamWidth, 10) + 7).toString(),
                    zfmHeight: (parseInt(newFormValue.tamHeight, 10) + 11).toString(),
                };
            } else {
                newFormValue = {
                    ...newFormValue,
                    tamWidth: (parseInt(newFormValue.zfmWidth, 10) - 7).toString(),
                    tamHeight: (parseInt(newFormValue.zfmHeight, 10) - 11).toString(),
                };
            }
            setFormValue(newFormValue);
        },
        value: formValue ? formValue[fieldName] : '',
    });

    const save = () => {
        props.productCustomizeFacade.setCustomAbmessungen(formValue);
        props.close(formValue);
    };

    return (
        <div>
            <div className="text-white pl-3 pb-3 pr-3" style={{fontSize: '1.3rem'}}>
                Individuelle Abmessungen
            </div>

            <div className="text-white pl-3 pb-3 pr-3" style={{fontSize: '1.15rem'}}>
                Türenaußenmaße (TAM)
            </div>
            <div className="product-field">
                <span>Breite</span>
                <input className="form-control" type="number" {...registerTAMField('tamWidth')}/>
            </div>
            <div className="product-field">
                <span>Höhe</span>
                <input className="form-control" type="number" {...registerTAMField('tamHeight')}/>
            </div>

            {!isSlidingDoor && <>
                <div className="text-white pl-3 pb-3 pr-3" style={{fontSize: '1.15rem'}}>
                    Zargenfalzmaß (ZFM)
                </div>
                <div className="product-field">
                    <span>Breite</span>
                    <input className="form-control" type="number" {...registerTAMField('zfmWidth')}/>
                </div>
                <div className="product-field">
                    <span>Höhe</span>
                    <input className="form-control" type="number" {...registerTAMField('zfmHeight')}/>
                </div>
            </>}

            <div className="pl-3 pr-3 pt-3">
                <button type="button" className="form-control" onClick={save}>Übernehmen</button>
            </div>
        </div>
    );
}
