import React, { CSSProperties } from 'react';
import NavTabs from './tabs/NavTabs';
import { AppFacades } from '../facades/app-facades';
import './layout.scss';
import { useFacadeState } from '../utils';
import { isNil } from '@w11k/rx-ninja';
import ProductSketch from '../products/configure/productSketch/ProductSketch';
import ProductDescription from '../products/configure/ProductDescription';
import { Footer } from '../Footer';
import { useRouteMatch } from 'react-router-dom';
import { AuthenticationState } from '../facades/AuthenticationFacade';

// eslint-disable-next-line @typescript-eslint/naming-convention
const BaseLayout = ({facades, left, main, leftSideBarVisible, sideBarNavVisible}: {
    facades: AppFacades; left: JSX.Element; main: JSX.Element; leftSideBarVisible: boolean; sideBarNavVisible: boolean
}): JSX.Element => {
    return (
        <div className="d-flex flex-column h-100">
            <div className="base-layout-wrapper d-flex">
                {leftSideBarVisible &&
                    <div className="left-sidebar-wrapper">
                        {sideBarNavVisible && <NavTabs facades={facades}/>}
                        {left}
                    </div>
                }
                {main}
            </div>
        </div>
    );
};
export default BaseLayout;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OrderLayout = ({left, main}: {
    left: JSX.Element; main: JSX.Element;
}): JSX.Element => (
    <div className="d-flex flex-column h-100">
        <div className="base-layout-wrapper d-flex">
            <div className="left-sidebar-wrapper">
                {left}
            </div>
            {main}
        </div>
    </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MainLayoutRightSidebar = ({main, right}: { main: JSX.Element; right: JSX.Element }): JSX.Element => (
    <>
        <MainLayout>
            <div className="h-100 w-100 d-flex flex-column">
                {main}
            </div>
        </MainLayout>
        <RightSidebarLayout>
            {right}
        </RightSidebarLayout>
    </>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MainLayout = ({children}: { children: JSX.Element }): JSX.Element => (
    <div className="h-100 w-100 flex-grow-1" style={{overflowY: 'auto'}}>
        {children}
    </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RightSidebarLayout = ({children}: { children: JSX.Element }): JSX.Element => (
    <div className="right-sidebar-wrapper">
        {children}
    </div>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const EmptySidebarLayout = ({note, style}: { note?: string; style?: CSSProperties }) => (
    <div className="container-fluid h-75">
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="mt-5">
                <p style={style}>
                    {note ?? {note}}
                </p>
            </div>
        </div>
    </div>
);

export function SketchLayoutWrapper({facades}: { facades: AppFacades }): JSX.Element {
    const {path} = useRouteMatch();
    const configuredProduct = useFacadeState(facades.productCustomizeFacade, s => s.configuredProduct);
    const productTemplate = useFacadeState(facades.productSelectionFacade, s => s.productTemplate);
    const user: Readonly<AuthenticationState> = useFacadeState(facades.authFacade, s => s);

    const isOrderView = (): boolean => path.includes('order');
    const isSkuView = (): boolean => path.includes('sku');

    if (isNil(configuredProduct)) {
        return (
            /* Kein Produkt gefunden */
            <NoProductWrapper/>
        );
    }

    return (
        /* Produkt gefunden */
        <MainLayoutRightSidebar
            main={
                /* Technische Zeichnung */
                <ProductSketch
                    configuredProduct={configuredProduct}
                    productTemplate={productTemplate}
                    hideSvgDownloadButton={isOrderView()}
                />
            }
            right={
                /* Produktbeschreibung */
                <ProductDescription
                    configuredProduct={configuredProduct}
                    allowOrderSubmit={isSkuView()}
                    user={user}
                />
            }
        />
    );
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NoProductWrapper = (): JSX.Element => (
    <MainLayoutRightSidebar
        main={<></>}
        right={<>
            <EmptySidebarLayout
                style={{
                    fontWeight: 'bold',
                    marginTop: '2rem',
                }}
                note={'Kein passendes Produkt gefunden.'}
            />
            <Footer/>
        </>}
    />
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DisabledProductsWrapper = ({facades}: { facades: AppFacades }): JSX.Element => (
    <BaseLayout
        facades={facades}
        left={
            <EmptySidebarLayout
                style={{
                    color: 'white',
                    fontWeight: 'bold',
                    marginBottom: '4rem',
                }}
                note={'Kein Produkt ausgewählt.'}
            />
        }
        main={<NoProductWrapper/>}
        sideBarNavVisible={true}
        leftSideBarVisible={false}
    />
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DisabledOrderWrapper = (): JSX.Element => (
    <OrderLayout
        left={
            <EmptySidebarLayout
                style={{
                    color: 'white',
                    fontWeight: 'bold',
                    marginBottom: '4rem',
                }}
                note={'Kein Produkt ausgewählt.'}
            />
        }
        main={<NoProductWrapper/>}
    />
);
