import React, { useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import Swal from 'sweetalert2';
import { testId } from '../tests/test-utils';
import { isNil } from '@w11k/rx-ninja';
import { SubmitOrderBody } from '../endpoints';
import { getErrorMessageFromException, getHeadersByEnv, wrapHTMLElementWithModalApi } from '../utils';
import NumberFormat from 'react-number-format';
import { checkIsSlidingDoor, ConfiguredProduct, Order } from '../domain';
import { convertToOrderPostToBackendV2, createOrderFromConfiguredProduct } from '../facades/OrderFacade';

export const testIdCustomerNumber = testId('customerNumber');
export const testIdMessage = testId('message');

export function ProductInfoText(props: {
    configuredProduct: ConfiguredProduct;
    order?: Order;
    allowOrderSubmit?: boolean
}) {
    const cp = props.configuredProduct;
    const prod = cp.final;
    const norm = prod.norm;
    const mods = props.configuredProduct.modifications;
    const isSlidingDoor = checkIsSlidingDoor(prod.door_type_multi);

    return (
        <div className="p-3">
            {/*<div>*/}
            {/*    <div className="font-weight-bold">{prod.description_40_char_1}</div>*/}
            {/*    <div className="text-muted">{prod.description_40_char_2}</div>*/}
            {/*    <div className="text-muted">{prod.description_40_char_3}</div>*/}
            {/*</div>*/}
            {/*<hr/>*/}
            {prod.preview_living_situation &&
            <div>
                <img src={prod.preview_living_situation} alt="Vorschau"/>
                <hr/>
            </div>}
            <div className="pt-3">
                <div>
                    <div><b>Türart:</b> {prod.door_type_multi}</div>
                    <div><b>Produktname:</b> {prod.product_name}</div>
                    <div><b>Breite:</b> {prod.width} mm</div>
                    <div><b>Höhe:</b> {prod.height} mm</div>
                    <div><b>Länge:</b> {prod.length} mm</div>
                    {!isSlidingDoor && <>
                        <div><b>Richtung:</b> {prod.direction}</div>
                        <div><b>Norm:</b> {norm.norm}</div>
                        <div><b>Schlossbohrung:</b> {mods.lock_drilling}</div>
                        <div><b>Bandbohrung:</b> {mods.hinge_drilling}</div>
                    </>}
                    <div className="mb-3"/>
                    <div><b>Glasbearbeitung Vorderseite:</b> {prod.glass_processing_front}</div>
                    <div><b>Glasbearbeitung Rückseite:</b> {prod.glass_processing_back}</div>
                    {!isSlidingDoor && <>
                        <div><b>Obere Glaskante Mitte Falle:</b> {mods.upper_glass_corner_mid_latch} mm</div>
                        <div><b>Untere Glaskante Mitte Falle:</b> {mods.lower_glass_corner_mid_latch} mm</div>

                        <div><b>Bandversatz:</b> {norm.hinge_offset} mm</div>
                        <div><b>Bandbezugslinie 1:</b> {mods.bbl1} mm</div>
                        <div><b>Bandbezugslinie 2:</b> {mods.bbl2} mm</div>
                        {!isNil(mods.bbl3) && <div><b>Bandbezugslinie 3:</b> {mods.bbl3} mm</div>}
                    </>}

                    <div><b>SKU:</b> {prod.sku}</div>
                    <div><b>Artikelnummer:</b> {prod.nav_no}</div>
                </div>
            </div>

            {!isNil(props.order) &&
            <div className="pt-3">
                {props.order.message}
            </div>
            }

            <div className="pt-3 font-weight-bold">
                <NumberFormat decimalSeparator=","
                              displayType="text"
                              value={prod.nav_recommended_retail_price_net}
                              suffix={'€'}
                              decimalScale={2}
                              fixedDecimalScale={true}/>
            </div>
            <div className="text-muted mb-1">
                UVP des Herstellers <br/>
                alle Preise exkl. MwSt.
            </div>

            {props.allowOrderSubmit === true &&
            <ModalBestellung configuredProduct={cp}/>
            }
        </div>
    );
}

function ModalBestellung(props: {
    configuredProduct: ConfiguredProduct,
}) {
    const formRef = useRef<HTMLDivElement>(null);
    const product = props.configuredProduct.final;
    const initialFormValue = {
        customerNumber: '',
        message: '',
    };

    async function submit(form: typeof initialFormValue) {
        const body = convertToOrderPostToBackendV2(
            createOrderFromConfiguredProduct(form.customerNumber, form.message, props.configuredProduct),
        );

        console.log('Senden:', JSON.stringify(body, undefined, 4));

        let potentialErrorMessage = '';
        try {
            //for testing
            // const response = {
            //     status: 200,
            //     code: "invalid customerNumber"
            // }

            const headers = getHeadersByEnv();
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/configurator-api/v2/order/submit`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers,
                    body: JSON.stringify(body),
                });

            if (response.status === 200) {
                const result = await response.json() as SubmitOrderBody;
                if (result.status === 'ok') {

                    // noinspection ES6MissingAwait
                    Swal.fire({
                        title: 'Ihre Bestellung wurde erfolgreich abgesendet!',
                        icon: 'success',
                        confirmButtonText: 'Close',
                    });
                    return true;
                } else {
                    potentialErrorMessage = result?.code ?? 'Unbekannter Fehler';
                }
            } else {
                potentialErrorMessage = 'Fehler bei der Übertragung: ' + response.status;
            }
        } catch (e) {
            potentialErrorMessage = getErrorMessageFromException(e);
        }

        await Swal.fire({
            title: 'Das hat leider nicht geklappt...',
            text: potentialErrorMessage,
            icon: 'error',
            confirmButtonText: 'Close',
        });
        return false;
    }

    return (
        <div>
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#bestellung">
                Anfrage abschicken
            </button>
            <Formik
                initialValues={initialFormValue}
                validate={values => {
                    const errors: Record<string, string> = {};
                    if (!values.customerNumber || values.customerNumber.trim().length < 3) {
                        errors.customerNumber = 'Bitte geben Sie Ihre Kundennummer an!';
                    }
                    return errors;
                }}
                onSubmit={async (values, {setSubmitting}) => {
                    const success = await submit(values);
                    setSubmitting(false);
                    if (success) {
                        wrapHTMLElementWithModalApi(formRef.current)?.modal('hide');
                    }
                }}
            >
                {({isSubmitting, isValid, submitForm}) => {
                    return (
                        <Form>
                            <div ref={formRef} className="modal fade" id="bestellung" tabIndex={-1}
                                 aria-labelledby="exampleModalLabel"
                                 aria-hidden="true">
                                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Anfrage senden</h5>
                                        </div>
                                        <div className="modal-body">
                                            <h6 className="mb-3">Zusammenfassung</h6>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div>{product.description_40_char_1}</div>
                                                    <div>{product.description_40_char_2}</div>
                                                    <div>{product.description_40_char_3}</div>
                                                </div>
                                                <div className="col">
                                                    <div>{product.sku}</div>
                                                    <div>{product.product_name}</div>
                                                    <div>Richtung: {product?.direction?.toUpperCase()}</div>
                                                </div>
                                            </div>


                                            <div className="mt-3 mb-3">
                                                <span className="">{product?.nav_recommended_retail_price_net} € </span>
                                                <span className="text-muted mb-3">
                                        UVP des Herstellers,
                                        alle Preise exkl. MwSt.
                                    </span>
                                            </div>
                                            <h6 className="mt-5">Ihre Daten</h6>
                                            {/*<SearchCustomer/>*/}
                                            <div className="form-group">
                                                <label>Bemerkungen</label>
                                                <Field type="text" name="message" className="form-control" as="textarea"
                                                       rows="5"
                                                       maxLength="250" {...testIdMessage.attr}/>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className="btn btn-primary"
                                                    disabled={isSubmitting || !isValid}>
                                                Absenden
                                            </button>
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                                Abbrechen
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
