import { isNotNil } from '@w11k/rx-ninja';
import { ABMESSUNGEN_CUSTOM, ProductCustomizeState } from '../facades/ProductCustomizeFacade';
import { MemberTypeConverter } from '../utils';
import { L_R } from '../domain';
import { ChangeEvent } from 'react';

export type CustomizedMeasurement = {
    tamWidth: string;
    tamHeight: string;
    zfmWidth: string;
    zfmHeight: string;
}

export type ProductFieldNames = keyof NonNullable<MemberTypeConverter<{
    blickdichte: string[], richtung: L_R[], abmessungen: [string, string][],
    bohrung: string[], sicherheit: string[], glasfarbe: string[],
}, string> & { mitte_falle: string, mitte_falle_top: string }>;

export type RegisterFormControlFn = (fieldName: ProductFieldNames) => {
    onChange: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void,
    value: string,
};

/**
 * 'mitte_falle' and 'mitte_falle_top' depend on each other.
 * We check which value was changed and adjust the other one via given height.
 *
 * @param fieldName
 * @param requestedHeight
 * @param mitte_falle
 * @param mitte_falle_top
 * @return [mitte_falle, mitte_falle_top]: [number, number]
 */
const calculateMitteFalle = (
    fieldName: ProductFieldNames,
    requestedHeight: number,
    mitte_falle: number,
    mitte_falle_top: number): [number, number] => {
    if (isNotNil<number>(requestedHeight)) {
        if (fieldName === 'mitte_falle' || fieldName === 'abmessungen') {
            return [mitte_falle, Math.abs(requestedHeight - mitte_falle)];
        }
        if (fieldName === 'mitte_falle_top') {
            return [Math.abs(requestedHeight - mitte_falle_top), mitte_falle_top];
        }
    }
    return [mitte_falle, mitte_falle_top];
};

/**
 * To calculate both values for 'mitte_falle' we need the correct **height**.
 * Height can be provided via several user choices, so we have to get the value accordingly.
 *
 * @param form
 * @param options
 * @param fieldName
 * @param customizedMeasurement
 * @param customizedMeasurementState
 * @return [mitte_falle, mitte_falle_top]: [string, string]
 */
export const updateMitteFalle = (form: NonNullable<ProductCustomizeState['formValues']>,
                                 options: NonNullable<ProductCustomizeState['options']>,
                                 fieldName: ProductFieldNames,
                                 customizedMeasurement?: CustomizedMeasurement,
                                 customizedMeasurementState?: CustomizedMeasurement): [string, string] => {
    let height: string;
    if (['mitte_falle', 'mitte_falle_top', 'abmessungen'].includes(fieldName)) {
        if (form.abmessungen === ABMESSUNGEN_CUSTOM) {
            height = customizedMeasurement?.tamHeight ?? customizedMeasurementState?.tamHeight ?? '0';
        } else {
            height = options.abmessungen[0][1];
        }
        const [mitte_falle, mitte_falle_top] = calculateMitteFalle(fieldName, parseInt(height ?? '0', 10),
            parseInt(form.mitte_falle, 10), parseInt(form.mitte_falle_top, 10),
        );
        return [mitte_falle.toString(), mitte_falle_top.toString()];
    }
    return [form.mitte_falle, form.mitte_falle_top];
};
